import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { useReactToPrint } from "react-to-print";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import logoenvia from "images/envia.png";
import BarCode from "shared/BarCode";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const PrintGuide = (router) => {
    const { navigate, location } = router;
    const { setTitle, media_movil } = useMedia();

    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const funciones = React.useRef(new Funciones(navigate));
    const [toPrintList, setToPrintList] = React.useState([]);
    const template_print = React.useRef();
    const pageStyle = `
        @page {
            size: 21.6cm 27.9cm;
            padding: 0;
            margin 0;
        }

        @media print {
            padding: 0;
            margin 0;

            body {
                size: 21.6cm 27.9cm;
                padding: 0;
                margin 0;
            }
        }
    `;

    const Factura = ({ data }) => {
        return <>
            <div className="factura_header border_top">
                <div className="factura_header_col">
                    <img src={logoenvia} alt="Logo envia" />
                    <p>
                        COLVANES SAS. NIT 800.185.306-4<br />
                        Principal: Carrera 88 # 17B-10 Bogota<br />
                        Atencion al usuario Tel (1)7943670<br />
                        www.envia.co
                    </p>
                    <h4>ESTE ES UN SERVICIO DE MENSAJERIA EXPRESA</h4>
                </div>
                <div className="factura_header_col align_center">
                    <p>
                        Lic.Min.Transporte 0080 de marzo 14/2000 <br />
                        Lic.Mintic 001368 del 4/8/2020
                    </p>
                    <p>Vigilada y Controlada por Mintic<br />
                        CIIU 4923 Transporte de Mercancia<br />
                        CIIU 5320 Mensajeria Expresa
                    </p>
                </div>
                <div className="factura_header_col">
                    <div className="factura_header_row align_around">
                        <div className="factura_header_col">
                            <h2 className="_marco"><span>P.T</span><span>79</span></h2>
                            <h2>CREDITO</h2>
                        </div>
                        <BarCode code="014133391266" />
                    </div>
                    <p className="unmargin">CUFE</p>
                    <p className="unmargin">Somos Autorretenedores Resoluc:4327 Jul/97 - Somos Grandes Contribuyentes Resoluc:9061 Dic/2020</p>
                    <p className="unmargin">Agente Retenedor de IVA</p>
                </div>
            </div>
            <div className="factura_body">
                <div className="factura_body_row align_between border_top">
                    <div className="factura_body_row width_50 align_between" style={{ marginRight: "4rem" }}>
                        <h2>FEC GENERACION/ADMISION 29/11/2023 17:30</h2>
                        <p className="big">ORIGEN: BOGOTA</p>
                        <h2>DESTINO: DISTRACCION-GUAJIRA</h2>
                    </div>
                    <div className="factura_body_row width_50 align_between" style={{ paddingLeft: "1rem" }}>
                        <h2>REG.DESTINO: VALLEDUPAR</h2>
                        <h2>CITA ENTREGA:</h2>
                        <h2>&nbsp;</h2>
                    </div>
                </div>
                <div className="factura_body_row border_top">
                    <div className="content_primary border_right">
                        <div className="factura_body_col">
                            <div className="factura_body_row align_between border_bottom">
                                <div className="factura_body_col width_total">
                                    <div className="factura_body_row border_bottom">
                                        <h2 className="_field width_70 border_right">REMITE: HIT COLOMBIATECH SAS DESPACHOS </h2>
                                        <h2 className="_field width_25">CENTRO DE COSTO</h2>
                                    </div>
                                    <div className="factura_body_row border_bottom">
                                        <h2 className="_field width_total">DIRECCION: CARRERA 23#12-70 OFC 401 BARRIO LA PEPITA</h2>
                                    </div>
                                    <div className="factura_body_row align_middle border_bottom">
                                        <h2 className="_field _height width_25">TEL: 3136795927 </h2>
                                        <h2 className="_field _height width_25 border_right border_left">CEDULA / TI / NIT <br />901485389-9</h2>
                                        <h2 className="_field _height width_25 border_right">COD. POSTAL ORIGEN <br />111411228</h2>
                                        <h2 className="_field _height width_25">CUENTA: 01-001-0016214</h2>
                                    </div>
                                    <div className="factura_body_row border_bottom">
                                        <h2 className="_field">PARA </h2>
                                        <h2 className="_field">ZAPATA YAININ <br /> CALLE 13#11-09</h2>
                                    </div>
                                    <div className="factura_body_row align_middle border_bottom">
                                        <h2 className="_field _height width_25">TEL: 3118669369 </h2>
                                        <h2 className="_field _height width_25 border_right border_left">CEDULA / TI / NIT <br />901485389-9</h2>
                                        <h2 className="_field _height width_25 border_right">COD. POSTAL <br />444001</h2>
                                        <h2 className="_field _height width_25">RECIBE LOS SABADOS: SI</h2>
                                    </div>
                                    <div className="factura_body_row border_bottom">
                                        <h2 className="_field width_total">NOTAS <br /><b>SIN TEXTO GUIA</b></h2>
                                    </div>
                                    <div className="factura_body_row">
                                        <h2 className="_field height_nota width_50 border_right">Nombre CC.Remitente</h2>
                                        <h2 className="_field height_nota width_50">El remitente declara que esta mercancía no es contrabando, joyas, títulos valores, dinero, ni de prohibido transporte y su contenido sin verificar es: <br />#48995 - PAQUETE HIT COLOMBIA</h2>
                                    </div>
                                </div>
                                <div className="factura_body_col">
                                    <h2 className="_marco">
                                        <span>UNIDADES</span>
                                        <span>1</span>
                                    </h2>
                                    <h2 className="_marco">
                                        <span>PESO (Kgr)</span>
                                        <span>1</span>
                                    </h2>
                                    <h2 className="_marco">
                                        <span>PESO VOL</span>
                                        <span>1</span>
                                    </h2>
                                    <h2 className="_marco">
                                        <span>PESO A COBRAR(Kg)</span>
                                        <span>1</span>
                                    </h2>
                                    <h2 className="_marco">
                                        <span>VALOR DECLARADO</span>
                                        <span>40000</span>
                                    </h2>
                                    <h2 className="_marco">
                                        <span>VAL SERV ME</span>
                                        <span>0</span>
                                    </h2>
                                    <h2 className="_marco">
                                        <span>FLETE VARIABLE</span>
                                        <span>0</span>
                                    </h2>
                                    <h2 className="_marco">
                                        <span>OTROS</span>
                                        <span>0</span>
                                    </h2>
                                    <h2 className="_marco">
                                        <span>TOTAL FLETE</span>
                                        <span>0</span>
                                    </h2>
                                    <h2 className="_marco">
                                        <span>CARTAPORTE:NO</span>
                                    </h2>
                                </div>
                            </div>
                            <p className="small">El usuario deja expresa constancia que tuvo conocimiento del contrato que se encuentra publicado en la pagina web www.envia.co de Colvanes SAS y en las carteleras ubicadas en los puntos de servicio, que regula el servicio acordado entre las partes, cuyo contenido clausular acepta expresamente con la suscripcion de este documento. Para la prestacion del PQR remitirse a nuestra pagina web o al PBX (1)7943670</p>
                        </div>
                    </div>
                    <div className="content_primary">
                        <div className="factura_body_col width_total">
                            <div className="factura_body_row border_bottom">
                                <div className="factura_body_col width_40 border_right">
                                    <h2 className="title_causal">CAUSAL DE DEVOLUCION</h2>
                                    <p className="row_causal"><span className="nombre">Desconocido</span>  <span className="numero">No.31</span>  <samp>1</samp><samp>2</samp></p>
                                    <p className="row_causal"><span className="nombre">Rehusado</span>     <span className="numero">No.44</span>  <samp>1</samp><samp>2</samp></p>
                                    <p className="row_causal"><span className="nombre">No Reside</span>    <span className="numero">No.35</span>  <samp>1</samp><samp>2</samp></p>
                                    <p className="row_causal"><span className="nombre">No Reclamado</span> <span className="numero">No.40</span>  <samp>1</samp><samp>2</samp></p>
                                    <p className="row_causal"><span className="nombre">Dir. errada</span>  <span className="numero">No.34</span>  <samp>1</samp><samp>2</samp></p>
                                    <p className="row_causal"><span className="nombre">Otros (Nov Operativa/cerrado)</span> <span className="numero"></span>  <samp>1</samp><samp>2</samp></p>
                                </div>
                                <div className="factura_body_col">
                                    <h2 className="small border_bottom">Para ME y RF: Tiempo de entrega 48 horas habiles despues de arribo en destino</h2>
                                    <h2 className="title_causal">INTENTO DE ENTREGA</h2>
                                    <p className="long_frames"><samp className="numero"><u>1</u></samp>  <samp><u>D:</u></samp><samp><u>M:</u></samp><samp><u>A:</u></samp>  <samp className="long_separate"><u>H:</u></samp><samp><u>M:</u></samp></p>
                                    <p className="long_frames"><samp className="numero"><u>2</u></samp>  <samp><u>D:</u></samp><samp><u>M:</u></samp><samp><u>A:</u></samp>  <samp className="long_separate"><u>H:</u></samp><samp><u>M:</u></samp></p>
                                </div>
                            </div>
                            <div className="factura_body_row border_bottom">
                                <div className="factura_body_col width_40 height_second border_right">
                                    <h2 className="title_devolucion">Fecha de devolucion al remitente</h2>
                                    <p className="small_frames border_bottom"><samp><u>D:</u></samp><samp><u>M:</u></samp><samp><u>A:</u></samp>  <samp className="small_separate"><u>H:</u></samp><samp><u>M:</u></samp></p>
                                    <p>Observaciones en la entrega:</p>
                                    <h2 className="date_bottom">Fecha estimada de entrega: 04/12/2023</h2>
                                </div>
                                <div className="factura_body_col height_second">
                                    <p className="nota">Guia complementaria de devolucion</p>
                                    <p className="nota">Recibi a satisfaccion / Nombre, CC y Sello Destinatario</p>
                                    <p className="bottom_frames"><samp><u>D:</u></samp><samp><u>M:</u></samp><samp><u>A:</u></samp>  <samp className="separate"><u>H:</u></samp><samp><u>M:</u></samp></p>
                                </div>
                            </div>
                        </div>
                        <div className="factura_body_row">
                            <div className="border_t_r"></div>
                            <div className="border_t_r"></div>
                            <div className="border_t_r"></div>
                            <div className="border_t_r"></div>
                            <div className="border_t_r"></div>
                            <div className="border_t_r"></div>
                            <div className="border_t_r"></div>
                            <div className="border_t_r"></div>
                            <div className="border_t_r"></div>
                            <div className="border_t_r"></div>
                        </div>
                        <p className="supersmall">ENVIA COLVANES S. A. S., informa al Remitente que en cumplimiento a la Ley 1581 de 2012, normas complementarias, Aviso de Privacidad y Políticas de Tratamiento de Datos Personales, su información personal y la del destinatario, suministrada en esta Guía, solo recibirán el tratamiento necesario a la prestación del servicio contratado, atención de novedades y/o reclamaciones, y será suministrada únicamente a los intervinientes del servicio o trámite que usted requiera, y por su solicitud u orden de autoridad competente. Para la presentación de PQR remitirse al portal web www.enviacolvanes.com o a la línea telefónica: 7943</p>
                    </div>
                </div>
            </div>
            <div className="factura_footer border_top">
                <h4>- {data.tipo} -</h4>
                <h4 className="align_center">FOPER01</h4>
            </div>
        </>;
    }

    const getList = async (_info) => {
        let _data = await api.fetchJson({
            url: "envia-get-guia",
            data: {
                guia: _info.tracking_number
            }
        });

        console.log(_data);
        if (_data.response == 1) {

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        return _data;
    }

    const armaLista = async (_data) => {
        let _newData = [];
        setShowLoading(true);

        for (let i = 0; i < _data.length; i++) {
            let _item = _data[i];
            console.log("_item", _item);
            let _resp = await getList(_item);

            if (_resp.response == -1) {
                break;
            }
        }

        if (_newData.length > 0) {
            setToPrintList(_data);
        }
        setShowLoading(false);
    }

    const printGuides = useReactToPrint({
        content: () => template_print.current,
        pageStyle: pageStyle,
    });

    React.useEffect(() => {
        document.body.style.overflow = "auto";
        setTitle("Imprimir Guías");

        if (location.state && location.state.data) {
            let _data = location.state.data;

            funciones.current.validaSesion("guias_crear").then(response => {
                if (response) {
                    setPermission(true);
                    console.log("lista", _data);
                    armaLista(_data);
                } else {
                    setPermission(false);
                    setShowLoading(false);
                }
            });

        } else {
            setShowLoading(false);
            Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                "warning",
                () => navigate(-1));
        }
    }, [location.state]);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                {media_movil && <h4>En la versión movil no esta disponible la impresión de guías</h4>}
            </div>

            <div className="content_to_print" ref={template_print} >
                {toPrintList.map((item, i) => {
                    return <div key={i} className="factura">
                        <Factura data={{ ...item, tipo: "RECOLECCION" }} />
                        <Factura data={{ ...item, tipo: "ORIGEN ENVIA" }} />
                        <Factura data={{ ...item, tipo: "DESTINATARIO" }} />
                    </div>
                })}
            </div>

            <div className="content_btns _floating">
                {!media_movil && <button type="button" className="btn btn_primary" onClick={printGuides}>Imprimir {toPrintList.length} guía{toPrintList.length == 1 ? "" : "s"}</button>}
            </div>

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(PrintGuide);