import * as React from "react";
import { Alert, alertArea, alerta, alertaRemove } from "shared/Alert";
import { Input, TextArea } from "shared/Components";
import { useNavigate } from "react-router-dom";
import MovePopUp from "shared/MovePopUp";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const CrearCodigo = ({ parent, reload, close }) => {
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = React.useState(false);
    const [nombre, setNombre] = React.useState("");
    const [categoria, setCategoria] = React.useState("");

    const categoriaRef = React.useRef(null);
    const nombreRef = React.useRef(null);


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const saveCode = async () => {
        setShowLoading(true);
        let _radios = document.getElementsByName("tipo");
        let _tipo;

        _radios.forEach(element => {
            if (element.checked) {
                _tipo = element.value;
            }
        });

        let _data = await api.fetchJson({
            url: "set-concept",
            data: {
                task: _tipo == 1 ? "entrada" : "salida",
                info: JSON.stringify({
                    tipo: _tipo, // 0 - salida, 1 - entrada
                    caegoria: categoria.trim().toLowerCase(),
                    nombre: nombre.trim(),
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                reload();
                close(false);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                close(false);
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validate = (e) => {
        e.preventDefault();
        alertaRemove(categoriaRef.current);
        alertaRemove(nombreRef.current);

        if (categoria.length < 1) {
            alerta(categoriaRef.current, "¡Debes ingresar la categoria!");
            categoriaRef.current.focus();
            return false;
        }

        if (nombre.length < 1) {
            alertArea(nombreRef.current, "¡Debes ingresar el nombre del código!");
            nombreRef.current.focus();
            return false;
        }

        saveCode();
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title={"Crear código"}>

            <div className="content_primary _on_popup">

                <h5>Recuerda que el número se asigna de forma automática al momento de guardar.</h5>

                <Input
                    ref={categoriaRef}
                    title="Categoria"
                    value={categoria}
                    onChange={v => { alertaRemove(categoriaRef.current); setCategoria(v) }} />

                <TextArea
                    ref={nombreRef}
                    title="Nombre"
                    value={nombre}
                    onChange={v => { alertaRemove(nombreRef.current); setNombre(v) }} />

            </div>

            <div className="radio_goup">
                <label><input type="radio" name="tipo" value="1" defaultChecked={true} /><Iconos.CashIn />Entrada</label>
                <label><input type="radio" name="tipo" value="0" /><Iconos.CashOut />Salida</label>
            </div>
            <br />

            <div className="content_btns _without_margin">
                {showLoading ?
                    <button type="button" className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <button type="button" className="btn btn_primary" onClick={validate}>Guardar</button>
                }
            </div>
        </MovePopUp>)
}

export default CrearCodigo;