import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import ciudades from "shared/ciudades";
import Loading from "shared/Loading";
import Menu from "./components/Menu";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react/jsx-no-target-blank: 0 */
/* eslint no-extend-native: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

String.prototype.acentos = function () {
    var translate_re = /[áéíóúÁÉÍÓÚ]/g;
    var translate = {
        "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
        "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
    };
    return this.replace(translate_re, function (match) { return translate[match]; });
};

const Guias = (router) => {
    const { navigate } = router;
    const { setTitle, media_movil } = useMedia();
    const [showLoading, setShowLoading] = React.useState(true);
    const [selected, setSelected] = React.useState({ index: 0 });
    const [permission, setPermission] = React.useState(false);
    const funciones = React.useRef(new Funciones(navigate));
    const [dataList, setDataList] = React.useState([]);
    const [selecionados, setSelecionados] = React.useState([]);
    const [toPrintList, setToPrintList] = React.useState([]);
    const [rows, setRows] = React.useState(<h4 className="msg_lazy_load _alone _left">Verificando pedidos...</h4>);
    const [navBtns, setNavBtns] = React.useState({ prev: "", next: "" });
    const [prevLastId, setPrevLastId] = React.useState({ direction: "next", since: 0 });


    const Row = ({ data, interline }) => {
        // const [flete, setFlete] = React.useState(<button type="button"><Iconos.Loading /> </button>);
        const [flete, setFlete] = React.useState(<strong>debuging</strong>);
        const [isChecked, setIsChecked] = React.useState(false);
        const [toPrint, setToPrint] = React.useState(false);

        let _default_address = data.customer ? data.customer.default_address : "-1";
        let _status = data.financial_status === "pending" ? "Pendiente" : data.financial_status === "paid" ? "Pagado" : data.financial_status;
        let _city = _default_address.city ? _default_address.city.toString().toLowerCase().acentos() : "-1";
        let _province = _default_address.province ? _default_address.province.toString().toLowerCase().acentos() : "-1";
        let _obj_provinces = ciudades.filter(item => item.departamento.toString().toLowerCase().acentos().includes(_province));
        let _obj_city = _obj_provinces.filter(item => item.ciudad.toString().toLowerCase().acentos().includes(_city));

        data.fulfill_id = data.fulfillments[0]?.id;
        data.tracking_number = data.fulfillments[0]?.tracking_number;
        data.tracking_url = data.fulfillments[0]?.tracking_url;
        data.ciudad = _obj_city[0];


        const cargaFlete = async () => {
            let _resp = await getFlete({
                order: data.name,
                fulfill_id: data.fulfill_id,
                codigo: data.ciudad?.codigo,
                destinatario: {
                    nombre: data.customer.default_address.name,
                    direccion: data.customer.default_address.address1,
                    telefono: data.customer.default_address.phone ? data.customer.default_address.phone.replaceAll(' ', '') : "",
                    cedula: data.customer.default_address.company,
                },
                producto: {
                    nombre: data.name + " - Paquete Hit Colombia",
                    titulo: data.line_items[0].title ?? "",
                    precio: data.line_items[0].price ?? ""
                }
            });

            if (_resp.response === -1) {
                setFlete(<strong className="_error">{_resp.msg}</strong>);
            } else {
                setFlete(<strong>{_resp.msg}</strong>);
            }
        }

        React.useEffect(() => {
            if (data.ciudad?.codigo && !data.tracking_number) {
                if (!data.fulfill_id) {
                    setFlete(<strong className="_error">¡No hay orden de cumplimiento!</strong>);
                } else {
                    // cargaFlete();
                }
            } else {
                setFlete(<strong className="_error">Ciudad o departamento no encontrado</strong>);
            }
        }, []);

        return <Menu
            data={data}
            interline={interline}
            current={selected.id}
            onSel={_obj => {
                setSelected(_obj);

                if (data.fulfill_id && data.tracking_number === null && data.ciudad?.codigo) {
                    setIsChecked(b => !b);

                    setSelecionados(s => {
                        if (s.some(item => item.id === _obj.id)) {
                            return s.filter(f => f.id !== _obj.id);
                        } else {
                            return [...s, _obj];
                        }
                    });
                } else if (data.fulfill_id && data.tracking_number.length > 3) {
                    setToPrint(b => !b);

                    setToPrintList(s => {
                        if (s.some(item => item.id === _obj.id)) {
                            return s.filter(f => f.id !== _obj.id);
                        } else {
                            return [...s, _obj];
                        }
                    });
                }
            }}>

            {media_movil ?
                <td>
                    <pre className="cont_checkbox">
                        {(data.fulfill_id && data.tracking_number === null && data.ciudad?.codigo) ?
                            <>
                                <input type="checkbox" name={data.id} checked={isChecked} readOnly={true} />
                                <span>pedido:&nbsp;</span>
                            </>
                            :
                            <>
                                {data.tracking_number ? <Iconos.CheckBold className="_correcto" /> : <Iconos.Close className="_error" />}
                                <span>pedido:&nbsp;</span>
                            </>
                        } {data.name}
                        <span>Estado:&nbsp;</span> {_status}
                    </pre>
                    <pre style={{ paddingLeft: "2rem" }}>{data.tracking_number ?
                        <><span>Guía:&nbsp;</span> <a href={data.tracking_url} target="_blank" onClick={e => e.stopPropagation()}>{data.tracking_number}</a></>
                        :
                        <><span>Costo envio:&nbsp;</span> {data.ciudad?.ciudad}{data.ciudad?.departamento ? ", " + data.ciudad?.departamento : ""} {flete}</>
                    }
                    </pre>
                </td>
                :
                <>
                    <td className="cont_checkbox">
                        {(data.fulfill_id && data.tracking_number === null && data.ciudad?.codigo) ?
                            <input type="checkbox" name={data.id} checked={isChecked} readOnly={true} />
                            :
                            <>{data.tracking_number ? <Iconos.CheckBold className={toPrint ? "_correcto _to_print" : "_correcto"} /> : <Iconos.Close className="_error" />}</>
                        } {data.name}
                    </td>
                    <td>{_status}</td>
                    <td style={{ textTransform: "capitalize" }}>{data.customer ? data.customer.default_address.city : ""}</td>
                    <td className="cont_city">
                        {data.tracking_number ?
                            <span>Guía: <a href={data.tracking_url} target="_blank" onClick={e => e.stopPropagation()}>{data.tracking_number}</a></span>
                            :
                            <>
                                {data.fulfill_id ?
                                    <span>{data.ciudad?.ciudad}{data.ciudad?.departamento ? ", " + data.ciudad?.departamento : ""} {flete}</span>
                                    :
                                    <span>{flete}</span>
                                }
                            </>
                        }
                    </td>
                </>
            }
        </Menu>;
    }

    const armaFilas = () => {
        let _data = dataList.map((item, i) => {
            // console.log(i, item.name, item.id, item.fulfillments);
            return <Row
                key={item.id}
                interline={i % 2}
                data={item} />;
        });

        if (_data.length < 1) {
            setRows(<h4 className="msg_lazy_load _alone _left">No hay pedidos</h4>);
        } else {
            setRows(<>
                <table className="table">
                    <tbody>
                        {media_movil ?
                            <tr className="table_title">
                                <th>&nbsp;</th>
                            </tr>
                            :
                            <tr className="table_title">
                                <th>Pedido</th>
                                <th>Estado del pago</th>
                                <th>Ciudad</th>
                                <th>Costo envio</th>
                            </tr>
                        }
                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <div className="content_btns _center">
                        {navBtns.prev !== "" && <button className="btn btn_lazy_load" onClick={() => getListLazy("prev")}>Anterior</button>}
                        {navBtns.next !== "" && <button className="btn btn_lazy_load" onClick={() => getListLazy("next")}>Siguiente</button>}
                    </div>
                }
            </>);
        }

        window.scrollTo(0, 120);
    }

    const reloadList = async () => {
        setShowLoading(true);
        let _data = await api.fetchCurl({
            url: "shopify-get-orders",
            data: {
                task: "lazy",
                direction: prevLastId.direction,
                limit: 20,
                since_id: prevLastId.since
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }

            setNavBtns({ prev: _prev, next: _next });
            setDataList(_data.data);
            setSelecionados([]);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getListLazy = async (direction) => {
        setShowLoading(true);
        let position = direction === "next" ? dataList.length - 1 : 0;
        let prev_last_id = dataList[position].id;
        let _data = await api.fetchCurl({
            url: "shopify-get-orders",
            data: {
                task: "lazy",
                direction,
                limit: 20,
                since_id: dataList[position].id
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }
            console.log(_data.data);
            setPrevLastId({ direction, since: prev_last_id });
            setNavBtns({ prev: _prev, next: _next });
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);
        setDataList([]);
        let _data = await api.fetchCurl({
            url: "shopify-get-orders",
            data: {
                task: "first",
                limit: 20,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                // _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }

            console.log(_data.data);
            setNavBtns({ prev: _prev, next: _next });
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getFlete = async (params) => {
        let _data = await api.fetchJson({
            url: "envia-get-flete",
            data: params
        });

        if (_data.response == 1) {
            let _res = JSON.parse(_data.data);
            return { response: 1, msg: `$${(_res.valor_flete + _res.valor_costom)}` };
        } else {
            return { response: -1, msg: _data.msg };
        }
    }

    const crearGuias = async () => {
        setShowLoading(true);
        let _guias = selecionados.map(pedido => {
            return {
                order: pedido.name,
                fulfill_id: pedido.fulfill_id,
                codigo: pedido.ciudad.codigo,
                destinatario: {
                    nombre: pedido.customer.default_address.name,
                    direccion: pedido.customer.default_address.address1,
                    telefono: pedido.customer.default_address.phone ? pedido.customer.default_address.phone.replaceAll(' ', '') : "",
                    cedula: pedido.customer.default_address.company,
                },
                producto: {
                    nombre: pedido.name + " - Paquete Hit Colombia",
                    titulo: pedido.line_items[0].title ?? "",
                    precio: pedido.line_items[0].price ?? ""
                }
            }
        });

        let _data = await api.fetchJson({
            url: "set-guias-envia",
            data: _guias
        });

        console.log(_data);

        if (_data.response == 1) {
            reloadList();
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const goPrint = (e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate("/guias-imprimir", { state: { data: toPrintList } });
    }

    React.useEffect(() => {
        armaFilas();
    }, [dataList]);

    React.useEffect(() => {
        document.body.style.overflow = "auto";

        setTitle("Guías");

        funciones.current.validaSesion("guias_crear").then(response => {
            if (response) {
                setPermission(true);
                getList();
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, []);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar_sticky">
                {selecionados.length < 1 ?
                    <h3>No hay pedidos seleccionados</h3>
                    :
                    <h3>{selecionados.length} pedido{selecionados.length == 1 ? "" : "s"} seleccionado{selecionados.length == 1 ? "" : "s"}</h3>
                }

                {selecionados.length < 1 ?
                    <button type="button" className="btn btn_with_icon _left unable"><Iconos.CheckBook />Crear guía</button>
                    :
                    <button type="button" className="btn btn_with_icon _left" onClick={crearGuias}><Iconos.CheckBook />Crear guía{selecionados.length == 1 ? "" : "s"}</button>
                }

                {media_movil ? null :
                    <>
                        {toPrintList.length < 1 ?
                            <button type="button" className="btn btn_with_icon _left unable"><Iconos.PrinterOutline />Imprimir guía</button>
                            :
                            <button type="button" className="btn btn_with_icon _left" onClick={goPrint}><Iconos.PrinterOutline />Imprimir {toPrintList.length} guía{toPrintList.length == 1 ? "" : "s"}</button>
                        }
                    </>
                }
            </div>

            {rows}

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(Guias);