import * as React from "react";
import { InputWithIcon, SelectWithIcon } from "shared/Components";
import { RequirePermissions } from "shared/RequirePermissions";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { uploadFile } from "services/Uploader";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { DropFile } from "shared/FileUpload";
import { cuentas } from "shared/cuentas";
import Loading from "shared/Loading";
import resize from "shared/Resize";
import { useMedia } from "hooks";
import * as Iconos from "images";
import log from "services/logs";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const VoucherEdit = (router) => {
    const { navigate, location } = router;
    const { setTitle } = useMedia();
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const funciones = React.useRef(new Funciones(navigate));

    const [banco, setBanco] = React.useState("");
    const [voucher, setVoucher] = React.useState("");
    const [pedido, setPedido] = React.useState("");
    const [files, setFiles] = React.useState([]);

    const voucherRef = React.useRef(null);
    const pedidoRef = React.useRef(null);
    const filesRef = React.useRef(null);
    const bancoRef = React.useRef(null);


    const createFile = React.useCallback(async (_attach) => {
        let _files = [];

        _attach.forEach(async (_file) => {
            let response = await fetch(_file.url, {
                mode: 'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            let data = await response.blob();

            let file = new File([data],
                _file.name,
                {
                    type: _file.name.split(".").pop() === "pdf" ? "application/pdf" : "image/" + _file.name.split(".").pop(),
                    lastModified: Date.now()
                });
            file = Object.assign(file, {
                preview: _file.url,
                url: _file.url,
                path: _file.name,
                nameinserver: _file.name,
                isUpdate: true
            });

            _files.push(file);

            if (_files.length == _attach.length) {
                setFiles(_files);
            }
        });
    }, []);

    const saveVoucher = async (_files) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "update-voucher",
            data: {
                task: "??",
                info: JSON.stringify({
                    id: location.state.data.id,
                    bank: banco,
                    bank_name: cuentas.find(count => count[banco])[banco],
                    voucher,
                    pedido,
                    files: _files
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                navigate(-1);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validate = () => {
        let allFiles = [];
        let _next = true;

        alertaRemove(bancoRef.current);
        alertaRemove(voucherRef.current);
        alertaRemove(filesRef.current);

        if (voucher == null || voucher.length < 1) {
            alerta(voucherRef.current, "¡Debes ingresar el número del comprobante!");
            voucherRef.current.focus();
            return false;
        }

        if (banco == null || banco.length < 1) {
            alerta(bancoRef.current, "¡Debes seleccionar una cuenta bancaria!");
            bancoRef.current.focus();
            return false;
        }

        if (files == null || files.length < 1) {
            alerta(filesRef.current, "¡Debes agregar una foto del comprobante!");
            filesRef.current.focus();
            return false;
        }

        files.some(file => {
            if (file.type.toString().split('/')[0] === "image" || file.type === "application/pdf") {
                log.i(file.type.toString().split('/')[1]);
            } else {
                alerta(filesRef.current, "¡Solo se admiten imagenes o archivos PDF!");
                filesRef.current.focus();
                _next = false;
                return false;
            }
        });

        if (files.length > 0 && _next) {
            setShowLoading(true);

            files.forEach(async file => {
                const data = new FormData();
                const imgName = "voucher_" + voucher + file.nameinserver.toString().split('voucher')[1];

                if (file.isUpdate) {
                    allFiles.push({ url: file.url, name: file.nameinserver });

                    log.i("archivo actualizado", file.name);

                    if (allFiles.length == files.length) {
                        saveVoucher(allFiles);
                    }
                } else {
                    if (file.type.toString().split('/')[0] === "image") {

                        resize(file, async image => {
                            if (image !== null) {
                                data.append('file', image);
                                data.append('nombre', imgName.trim());
                                data.append('destino', "vouchers");
                            }

                            await uploadFile(data).then(response => {

                                allFiles.push({ url: response.url, name: response.nameinserver });

                                log.i("archivo subido", file.name);

                                if (allFiles.length == files.length) {
                                    saveVoucher(allFiles);
                                }

                            }).catch(error => {
                                alerta(filesRef.current, error);
                                setShowLoading(false);
                            });
                        });
                    } else {
                        data.append('file', file);
                        data.append('nombre', imgName.trim());
                        data.append('destino', "vouchers");

                        await uploadFile(data).then(response => {

                            allFiles.push({ url: response.url, name: response.nameinserver });

                            log.i("archivo subido", file.name);

                            if (allFiles.length == files.length) {
                                saveVoucher(allFiles);
                            }

                        }).catch(error => {
                            alerta(filesRef.current, error);
                            setShowLoading(false);
                        });
                    }
                }
            });
        }
    }

    React.useEffect(() => {
        document.body.style.overflow = "auto";

        if (location.state && location.state.data) {
            let _data = location.state.data;

            setTitle("Editar comprobante " + _data.voucher, location.state);

            funciones.current.validaSesion("comprobantes_edita").then(response => {
                if (response) {
                    setPermission(true);
                    setVoucher(_data.voucher);
                    setPedido(_data.pedido);
                    setBanco(_data.bank);
                    createFile(_data.files);
                } else {
                    setPermission(false);
                }
                setShowLoading(false);
            });
        } else {
            setShowLoading(false);
            setTitle("Editar comprobante", location.state);
            Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                "warning",
                () => navigate(-1));
        }
    }, [location.state]);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2 className="head_bar-title">Editar comprobante "{voucher}"</h2>
            </div>

            <div className="content_primary">

                <InputWithIcon
                    ref={voucherRef}
                    type="text"
                    title="Número de comprobante"
                    value={voucher}
                    onChange={v => { alertaRemove(voucherRef.current); setVoucher(v) }} />

                <InputWithIcon
                    ref={pedidoRef}
                    type="text"
                    title="Número de pedido"
                    value={pedido}
                    onChange={v => { alertaRemove(pedidoRef.current); setPedido(v) }} />

                <SelectWithIcon
                    ref={bancoRef}
                    title="Cuenta Bancaria"
                    Icono={Iconos.Bank}
                    value={banco}
                    onChange={v => { alertaRemove(bancoRef.current); setBanco(v) }} >
                    <option value="">Selecciona Cuenta</option>
                    {cuentas.map((item, i) => {
                        let [key, value] = Object.entries(item)[0];
                        return <option key={i} value={key}>{value}</option>;
                    })}

                </SelectWithIcon>

                <div className="form_group">
                    <span className="form_group-label">Foto del comprobante</span>
                    <DropFile
                        ref={filesRef}
                        voucher={voucher}
                        attach={files}
                        onChange={v => setFiles(v)} />
                </div>

            </div>

            <div className="content_btns _with_margin">
                <button type="button" className="btn btn_primary" onClick={validate}>Guardar</button>
            </div>

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(VoucherEdit);