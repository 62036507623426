import * as React from "react";
import { Alert, alertArea, alertaRemove } from "shared/Alert";
import { useNavigate } from "react-router-dom";
import { DropFile } from "shared/FileUpload";
import { TextArea } from "shared/Components";
import MovePopUp from "shared/MovePopUp";
import Loading from "shared/Loading";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses_hsort = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Options = ({ data, parent, reload, close }) => {
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = React.useState(false);
    const [files, setFiles] = React.useState([]);
    const [fecha, setFecha] = React.useState("");
    const [nota, setNota] = React.useState("");

    const notaRef = React.useRef(null);


    const createFile = React.useCallback(async (_attach) => {
        let _files = [];

        _attach.forEach(async (_file) => {
            let response = await fetch(_file.url, {
                mode: 'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            let data = await response.blob();

            let file = new File([data],
                _file.name,
                {
                    type: _file.name.split(".").pop() === "pdf" ? "application/pdf" : "image/" + _file.name.split(".").pop(),
                    lastModified: Date.now()
                });
            file = Object.assign(file, {
                preview: _file.url,
                url: _file.url,
                path: _file.name,
                nameinserver: _file.name,
                isUpdate: true
            });

            _files.push(file);

            if (_files.length == _attach.length) {
                setFiles(_files);
            }
        });
    }, []);

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const saveValidate = async (_estado) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "update-voucher",
            data: {
                task: "validate",
                info: JSON.stringify({
                    id: data.id,
                    estado: _estado,
                    nota,
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                reload();
                close(false);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                close(false);
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validate = (e, _estado) => {
        e.preventDefault();
        alertaRemove(notaRef.current);

        if (_estado === 2 && nota.length < 1) {
            alertArea(notaRef.current, "¡Debes indicar el motivo del rechazo!");
            notaRef.current.focus();
            return false;
        }

        saveValidate(_estado);
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        createFile(data.files);
        let fecha_creado = new Date(data.fecha_creado);
        setFecha(format(fecha_creado.getDate()) + "/" + meses_hsort[fecha_creado.getMonth()] + "/" + fecha_creado.getFullYear() + " " + format(fecha_creado.getHours()) + ":" + format(fecha_creado.getMinutes()));
    }, [data]);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title="Validar comprobante">

            <div className="content_primary _on_popup">

                <h4 className="field_text">{data.asesor.nombre} <i>{fecha}</i></h4>

                <div className="form_group">
                    <DropFile
                        readOnly={true}
                        voucher={data.voucher}
                        attach={files} />
                </div>

                <h4 className="field_text">Comprobante: <span>{data.voucher}</span></h4>
                <h4 className="field_text">Pedido: <span>{data.pedido}</span></h4>
                <h4 className="field_text">Cuenta: <span>{data.bank} - {data.bank_name}</span></h4>

                <TextArea
                    ref={notaRef}
                    title="Observsción"
                    value={nota}
                    onChange={v => { alertaRemove(notaRef.current); setNota(v) }} />
            </div>


            <div className="content_btns _without_margin">
                <button type="button" className="btn btn_primary" onClick={e => validate(e, 1)}>Aceptar</button>
                <button type="button" className="btn btn_primary" onClick={e => validate(e, 2)}>Rechazar</button>
            </div>

            <Loading state={showLoading} />
        </MovePopUp>)
}

export default Options;