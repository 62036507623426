import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { useNavigate } from "react-router";
import { useMedia } from "hooks";
import * as Iconos from "images";

/* eslint react-hooks/exhaustive-deps: 0 */

const Home = () => {
    const { setTitle } = useMedia();
    const navigate = useNavigate();


    React.useEffect(() => {
        setTitle("Sistema de gestión de recursos - Hit Colombia");
        document.body.style.overflow = "auto";
    }, []);

    return (
        <RequirePermissions>

            <div className="head_bar">
                <h2 className="head_bar-title _left">Hit Colombia</h2>
                <p>Sistema de gestión de recursos</p>
            </div>


            <div className="home_cont_btns">

                <div className="btn_home" onClick={() => navigate("/pendiente")}>
                    <Iconos.Alert />
                    <h4>Pendiente</h4>
                </div>

            </div>

            <hr />

            <div className="home_cont_btns">

                <div className="btn_home" onClick={() => navigate("/pendiente")}>
                    <Iconos.Alert />
                    <h4>Pendiente</h4>
                </div>

                <div className="btn_home" onClick={() => navigate("/pendiente")}>
                    <Iconos.Alert />
                    <h4>Pendiente</h4>
                </div>

            </div>

        </RequirePermissions>
    )
}

export default Home;