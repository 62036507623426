import CryptoJS from "crypto-js";

const WEB_API = process.env.REACT_APP_WEB_API;

/* eslint import/no-anonymous-default-export: 0 */

class API {

    /**
     * Genera la firma de autenticación
     * necesaria para autorizar la consulta en el servidor
     * @return {string}: Un string con el digest 
     */
    getAuth(_tipo = "") {
        let _data = JSON.parse(localStorage.getItem('usuario'));
        let auth = _data == null ? window.btoa(0) : _data.auth;
        let respuesta_valida;
        let live_time;

        if (_tipo === "invitado") {
            live_time = new Date().getTime() / 1000;
            respuesta_valida = CryptoJS.MD5("invitado:" + process.env.REACT_APP_DOMINIO + ":" + live_time).toString();

            return `Digest auth="invitado", time="${live_time}", response="${respuesta_valida}"`;
        } else if (typeof android !== "undefined") {
            live_time = new Date().getTime() / 1000;
            respuesta_valida = CryptoJS.MD5(auth + ":" + process.env.REACT_APP_DOMINIO + ":" + live_time).toString();

            return `Digest auth="${auth}", time="${live_time}", response="${respuesta_valida}"`;
        } else {
            let current_time = new Date().getTime() / 1000;

            live_time = _data == null ? 0 : _data.time;

            if (current_time - live_time > 10800) {

                localStorage.removeItem('usuario');

                respuesta_valida = CryptoJS.MD5(auth + ":" + process.env.REACT_APP_DOMINIO + ":" + current_time).toString();

                return `Digest auth="${auth}", time="${live_time}", response="${respuesta_valida}"`;
            } else {

                respuesta_valida = _data == null ? 0 : CryptoJS.MD5(auth + ":" + process.env.REACT_APP_DOMINIO + ":" + current_time).toString();

                localStorage.setItem('usuario', JSON.stringify({
                    ..._data,
                    time: parseInt(current_time),
                    token: respuesta_valida
                }));

                return `Digest auth="${auth}", time="${current_time}", response="${respuesta_valida}"`;
            }
        }

    }

    /**
     * Verifica el estado de la respuesta en la consulta 
     * @param response: La respuesta generada en la consulta 
     */
    verificaEstado(response) {
        switch (response.status) {
            case 200:
            case 210:
                //console.log('Conexión corecta', response.status);
                break;
            case 401:
            case 403:
            case 422:
                console.log('Error', response.status);
                break;
            case 404:
                throw new Error('404 - No se encuentra el recurso!');
            case 500:
            case 501:
            case 502:
                console.log('Error', response.status);
                // throw new Error('500 - Ocurrio un error en la sintaxis!');
                break;
            case 503:
                throw new Error('503 - Servicio no disponible!');
            default:
                throw new Error(`(${response.status}) es un código inesperado, puedes reportarlo al correo "desarrollo@sarguero.com" para revisarlo mas a fondo`);
        }
    }


    /**
     * Hace una consulta al servidor 
     * @param {JSON} params: json con { `url`: string, `data`: json }
     * @return {Promise<JSON>}: `json` con los datos solicitados 
     */
    async fetchJson(params) {
        let datos;

        await fetch(WEB_API + params.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth(params.tipo || '')
            },
            body: JSON.stringify(params.data)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async fetchCurl(params) {
        let datos;

        await fetch(WEB_API + params.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth(params.tipo || '')
            },
            body: JSON.stringify(params.data)
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                let _headers = {};
                let _data;
                let _resp = -1;
                datos = data.split('\r\n');
                datos.forEach(linea => {
                    const partes = linea.split(': ');

                    if (partes.length === 2) {
                        _headers[partes[0]] = partes[1];
                    }

                    if (linea.includes('"orders":')) {
                        _data = JSON.parse(linea);
                        _resp = 1;
                    }

                    if (linea.includes('"errors":')) {
                        _data = JSON.parse(linea);
                        _resp = -1;
                    }
                });
                if (_resp === -1) {
                    datos = { response: _resp, msg: _data.errors, headers: _headers };
                } else {
                    datos = { response: _resp, data: _data.orders, headers: _headers };
                }
            })
            .catch((error) => {
                datos = { response: -1, msg: error.toString() };
            });

        return datos;
    }

    /**
     * Envia datos al servidor 
     * @param {FormData} data: form-data 
     * @param {string} url: string
     * @return {Promise<JSON>}: json con la respusta
     */
    async fetchFormData(data, url) {
        let datos;

        await fetch(WEB_API + url, {
            method: 'POST',
            body: data,
            progress: (e) => { console.log(`Progress: ${e.loaded / e.total}%`) }
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {

                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    /**
     * Verifica el correo y la clave en la base de datos 
     * @param {String} email: el correo del usuario en texto plano
     * @param {String} pass: La clave del usuario en texto plano
     * @return {Promise<JSON>}: Json con los datos del usuario 
     */
    async signIn(email, pass) {
        let clave = CryptoJS.SHA256(pass);
        let datos;

        await fetch(`${WEB_API}sign-in`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth("invitado")
            },
            body: JSON.stringify({
                email: email.trim(),
                pass: clave.toString()
            })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }
}

export default new API();