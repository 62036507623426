import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import CodigosCaja from "./components/CodigosCaja";
import ControlCaja from "./components/ControlCaja";
import FlujoCaja from "./components/FlujoCaja";
import { Funciones } from "services/funciones";
import { useNavigate } from "react-router";
import Reporte from "./components/Reporte";
import Entrada from "./components/Entrada";
import Salida from "./components/Salida";
import { cuentas } from "shared/cuentas";
import { Alert } from "shared/Alert";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Till = () => {
    const { setTitle, media_movil } = useMedia();
    const navigate = useNavigate();
    const funciones = React.useRef(new Funciones(navigate));
    const [permission, setPermission] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(true);
    const [currentTab, setCurrentTab] = React.useState(1);

    const [modalView, setModalView] = React.useState(null);
    const [bancos, setBancos] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    const handleRefresh = () => {
        getInfo(bancos);
    }

    const abrirEntrada = (e) => {
        e.preventDefault();

        setModalView(<Entrada
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            reload={() => handleRefresh()}
            close={() => setModalView(null)} />);
    }

    const abrirSalida = (e) => {
        e.preventDefault();

        setModalView(<Salida
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            reload={() => handleRefresh()}
            close={() => setModalView(null)} />);
    }

    const abrirReporte = (e) => {
        e.preventDefault();

        setModalView(<Reporte
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            close={() => setModalView(null)} />);
    }

    const getInfo = async (_bancos) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-cash-movement",
            data: {
                task: "cuentas",
            }
        });

        if (_data.response == 1) {
            let _total = 0;
            let _new = _bancos.map(b => {
                // eslint-disable-next-line
                _data.data.map(a => {
                    if (b.codigo === a.bank) {
                        b.monto = Number(a.monto).toFixed(2).replace('.', ',');
                        _total += Number(a.monto);
                    }
                });
                return b;
            });

            setBancos(_new);

            setTotal(_total.toFixed(2).replace('.', ','));

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setShowLoading(true);
    }, [currentTab]);


    React.useEffect(() => {
        document.body.style.overflow = "auto";

        setTitle("Caja principal");

        let _bancos = cuentas.map((item, i) => {
            let [key, value] = Object.entries(item)[0];
            return { codigo: key, nombre: value, monto: 0 };
        });

        setBancos(_bancos);

        funciones.current.validaSesion("contable_caja_ver").then(response => {
            if (response) {
                setPermission(true);
                getInfo(_bancos);
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, []);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2 className="head_bar-title _left">Caja principal</h2>
            </div>

            <div className="caja">
                <div className="caja_to_rigth">
                    <Iconos.Renew className={showLoading ? "caja_icon_renew _rotate" : "caja_icon_renew"} onClick={handleRefresh} />

                    {bancos.map((item, i) => {
                        return <h3 className="caja_totales" key={i}><span>{item.nombre}</span> {item.codigo} <samp>{funciones.current.formatPrice(item.monto).format}</samp></h3>;
                    })}

                    <h3 className="caja_totales">Total <samp>{funciones.current.formatPrice(total).format}</samp></h3>
                </div>
            </div>

            <div className="content_btns _multiple">
                {funciones.current.validaPermisos("contable_caja_entreda") && <button type="button" className="btn btn_with_icon _left" onClick={abrirEntrada}><Iconos.CashIn /> Crear entrada</button>}
                {funciones.current.validaPermisos("contable_caja_salida") && <button type="button" className="btn btn_with_icon _left" onClick={abrirSalida}><Iconos.CashOut /> Crear salida</button>}
                {funciones.current.validaPermisos("contable_caja_informes") && <button type="button" className="btn btn_with_icon _left" onClick={abrirReporte}><Iconos.ExcelDownload /> Descargar excel</button>}
            </div>

            <div className="content_tabs">
                <div className={currentTab === 1 ? "_tab _active" : "_tab"} onClick={() => setCurrentTab(1)}><h4>Control de caja</h4></div>
                <div className={currentTab === 2 ? "_tab _active" : "_tab"} onClick={() => setCurrentTab(2)}><h4>Flujo de caja</h4></div>
                {funciones.current.validaPermisos("contable_caja_code_ver") && <div className={currentTab === 3 ? "_tab _active" : "_tab"} onClick={() => setCurrentTab(3)}><h4>Códigos de caja</h4></div>}
            </div>

            {currentTab === 1 && <ControlCaja
                navigate={navigate}
                media_movil={media_movil}
                funciones={funciones}
                showLoading={showLoading}
                setShowLoading={setShowLoading}
                handleRefresh={() => handleRefresh()} />}

            {currentTab === 2 && <FlujoCaja
                navigate={navigate}
                media_movil={media_movil}
                funciones={funciones}
                showLoading={showLoading}
                setShowLoading={setShowLoading}
                handleRefresh={() => handleRefresh()} />}

            {currentTab === 3 && <CodigosCaja
                navigate={navigate}
                media_movil={media_movil}
                funciones={funciones}
                showLoading={showLoading}
                setShowLoading={setShowLoading}
                handleRefresh={() => handleRefresh()} />}

            {modalView}
        </RequirePermissions>
    )
}

export default Till;