import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { InputWithIcon } from "shared/Components";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { NavLink } from "react-router-dom";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const EditDispatch = (router) => {
    const { navigate, location } = router;
    const { setTitle } = useMedia();
    const [listaPedidos, setListaPedidos] = React.useState([]);
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const funciones = React.useRef(new Funciones(navigate));
    const [guia, setGuia] = React.useState("");
    const [olds, setOlds] = React.useState({});

    const itemsRef = React.useRef([]);
    const guiaRef = React.useRef(null);
    const btnAddRef = React.useRef(null);


    const handleInputGuia = (_value) => {
        alertaRemove(guiaRef.current);
        setGuia(_value);
    }

    const handleInputCode = (_index, _id, _value) => {
        alertaRemove(itemsRef.current[_index]);
        setListaPedidos(p => {
            let _new = p.map(item => {
                if (item.id == _id) {
                    _value = _value.replaceAll("'", "-");
                    let _info = _value.split(")");

                    return {
                        ...item,
                        value: _value,
                        numero: _info[0] ? _info[0].trim() : "No aplica",
                        fecha: _info[1] ? _info[1].trim() : "No aplica",
                        cantidad: _info[2] ? _info[2].trim() : "No aplica",
                        cliente: _info[3] ? _info[3].trim() : "No aplica",
                        valor: _info[4] ? _info[4].trim() : "No aplica",
                        ciudad: _info[5] ? _info[5].trim() : "No aplica",
                        contacto: _info[6] ? _info[6].trim() : "No aplica",
                        cedula: _info[7] ? _info[7].trim() : "No aplica",
                        etiquetas: _info[8] ? _info[8].trim() : "No aplica",
                        productos: _info[11] ? _info[11].trim() : "No aplica",
                    };
                } else {
                    return item;
                }
            });
            return _new;
        });
    }

    const handleRemove = (_id, _value) => {
        setListaPedidos(p => {
            return p.filter(item => item.id !== _id);
        });
    }

    const changeStatus = (e) => {
        e.preventDefault();

        navigate("/entregar-despachos", { state: { data: location.state.data } });
    }

    const saveDispatch = async (_lista_pedidos) => {
        setShowLoading(true);
        let _lista = [];

        _lista_pedidos.map(item => {
            _lista.push(item.numero);
        });

        let _data = await api.fetchJson({
            url: "update-dispatch",
            data: {
                task: "??",
                info: JSON.stringify({
                    guia,
                    signature: location.state.data.signature,
                    pedidos: _lista_pedidos
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg + "\n\n Pedidos: " + _lista, "ok", () => {
                navigate(-1);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validaCambios = (_lista_pedidos) => {
        let _data = location.state.data;

        for (let i = 0; i < _lista_pedidos.length; i++) {
            if (_lista_pedidos[i].value !== olds[_lista_pedidos[i].id]) {
                return true;
            }
        }

        if (_data.guia === guia) {
            return false;
        }

        return true;
    }

    const validate = (_lista_pedidos) => {
        alertaRemove(guiaRef.current);
        alertaRemove(btnAddRef.current);


        if (guia == null || guia.length < 1) {
            alerta(guiaRef.current, "¡Debes ingresar el número de la guía!");
            guiaRef.current.focus();
            return false;
        }

        if (_lista_pedidos.length < 1) {
            alerta(btnAddRef.current, "¡Debes agregar almenos un pedido!");
            btnAddRef.current.focus();
            return false;
        }

        for (let i = 0; i < _lista_pedidos.length; i++) {
            alertaRemove(itemsRef.current[i]);

            if (_lista_pedidos[i].value == null || _lista_pedidos[i].value.length < 1) {
                alerta(itemsRef.current[i], "¡Debes ingresar información del pedido!");
                itemsRef.current[i].focus();
                return false;
            }

            let codigo = _lista_pedidos.filter(item => (_lista_pedidos[i].value.length > 1 && item.numero === _lista_pedidos[i].numero));

            if (codigo.length > 1) {
                alerta(itemsRef.current[i], "¡El número de pedido se encuentra repetido!");
                itemsRef.current[i].focus();
                return false;
            }
        }

        if (validaCambios(_lista_pedidos)) {
            saveDispatch(_lista_pedidos);
        } else {
            Alert("No hay cambios en la información.", "warning");
        }
    }

    const getOrders = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-dispatch",
            data: {
                task: "por_guia",
                guia: location.state.data.guia
            }
        });

        if (_data.response == 1) {
            let _olds = {};

            _data.data.map(item => {
                let _value = JSON.parse(item.info).value;
                let _info = _value.split(")");

                _olds = { ..._olds, [item.id]: _value }

                setListaPedidos(p => [...p, {
                    id: item.id,
                    value: _value,
                    numero: _info[0] ? _info[0].trim() : "No aplica",
                    fecha: _info[1] ? _info[1].trim() : "No aplica",
                    cantidad: _info[2] ? _info[2].trim() : "No aplica",
                    cliente: _info[3] ? _info[3].trim() : "No aplica",
                    valor: _info[4] ? _info[4].trim() : "No aplica",
                    ciudad: _info[5] ? _info[5].trim() : "No aplica",
                    contacto: _info[6] ? _info[6].trim() : "No aplica",
                    cedula: _info[7] ? _info[7].trim() : "No aplica",
                    etiquetas: _info[8] ? _info[8].trim() : "No aplica",
                    productos: _info[11] ? _info[11].trim() : "No aplica",
                }]);
            });

            setOlds(_olds);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        document.body.style.overflow = "auto";

        if (location.state && location.state.data) {
            let _data = location.state.data;

            setTitle("Editar despacho " + _data.guia, location.state);

            funciones.current.validaSesion("despachos_edita").then(response => {
                if (response) {
                    setPermission(true);
                    getOrders();
                    setGuia(_data.guia);
                } else {
                    setPermission(false);
                    setShowLoading(false);
                }
            });
        } else {
            setShowLoading(false);
            setTitle("Editar despacho", location.state);
            Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                "warning",
                () => navigate(-1));
        }
    }, [location.state]);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2 className="head_bar-title">Editar despacho "{location.state && location.state.data.guia}"</h2>
            </div>

            <div className="content_primary">
                {(location.state && location.state.data.estado == 1) ?
                    <h4 className="title_with_icon _left"><Iconos.TruckCheck /> Entregado a {location.state.data.carrier && location.state.data.carrier.toLowerCase()}</h4>
                    :
                    <button type="button" className="btn btn_with_icon _left _slim" onClick={changeStatus}>
                        <Iconos.TruckCheck />
                        Entregar a transportadora
                    </button>
                }
            </div>

            <div className="internal_frame">
                <div className="content_primary">

                    <InputWithIcon
                        ref={guiaRef}
                        type="text"
                        title="Número de guía"
                        value={guia}
                        onChange={handleInputGuia} />

                    {listaPedidos.map((item, i) => {
                        return <InputWithIcon
                            key={i}
                            ref={r => itemsRef.current[i] = r}
                            title={"Información del pedido " + (item.numero || item.id)}
                            Icono={Iconos.QrcodeScan}
                            value={item.value}
                            onChange={v => handleInputCode(i, item.id, v)} >
                            <Iconos.CloseCircle className="close" onClick={() => handleRemove(item.id)} />
                            {(item.numero && item.numero !== "No aplica") && <p className="detalles">
                                <span><b>Numero:</b> {item.numero} </span><br />
                                {(item.fecha && item.fecha !== "No aplica") && <><span><b>Fecha:</b> {item.fecha} </span><br /></>}
                                {(item.cantidad && item.cantidad !== "No aplica") && <><span><b>Cantidad:</b> {item.cantidad} </span><br /></>}
                                {(item.cliente && item.cliente !== "No aplica") && <><span><b>Cliente:</b> {item.cliente} </span><br /></>}
                                {(item.valor && item.valor !== "No aplica") && <><span><b>Valor:</b> {item.valor} </span><br /></>}
                                {(item.ciudad && item.ciudad !== "No aplica") && <><span><b>Ciudad:</b> {item.ciudad} </span><br /></>}
                                {(item.contacto && item.contacto !== "No aplica") && <><span><b>Contacto:</b> {item.contacto} </span><br /></>}
                                {(item.cedula && item.cedula !== "No aplica") && <><span><b>Cédula:</b> {item.cedula} </span><br /></>}
                                {(item.etiquetas && item.etiquetas !== "No aplica") && <><span><b>Etiquetas:</b> {item.etiquetas} </span><br /></>}
                                {(item.productos && item.productos !== "No aplica") && <><span><b>Productos:</b> {item.productos}  </span><br /></>}
                            </p>}
                        </InputWithIcon>
                    })}
                </div>

                <button type="button" ref={btnAddRef}
                    className="btn btn_with_icon _left"
                    onClick={() => {
                        alertaRemove(btnAddRef.current);
                        setListaPedidos(p => [...p, { id: (p.length + 1), value: "" }]);
                    }}>
                    <Iconos.QrcodeAdd />
                    Agregar pedido
                </button>
            </div>

            <div className="content_btns _with_margin">
                {(location.state && location.state.data.estado == 0) && <button type="button" className="btn btn_primary" onClick={() => validate(listaPedidos)}>Guardar</button>}
            </div>

            <div className="content_btns">

                <NavLink
                    to="/despachos"
                    className="btn link_to_btn _right">
                    Ver todos los despachos
                </NavLink>
            </div>

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(EditDispatch);