import * as React from 'react';
import { useNavigate } from "react-router";
import { Funciones } from "services/funciones";
import * as Iconos from "images";
import Loading from './Loading';

/**
 * Oculta el contenido si el valor `permission` es falso
 * @param {Boolean} permission Si no se especifica este valor se asume que solo requiere estar logeado
 * @returns 
 */
export const RequirePermissions = ({ permission, children, menu = false, loading = false }) => {
    const [showContent, setShowContent] = React.useState(false);
    const navigate = useNavigate();
    const funciones = React.useRef(new Funciones(navigate));
    const [inLoad, setInLoad] = React.useState(true);


    const Mensaje = () => {
        return (
            <div className="container">
                {inLoad ?
                    <div className="container_messages" style={{ height: "20vh" }}>
                        <h2><Iconos.PermissionsSync />Verificando permisos...</h2>
                        <Loading state={inLoad} />
                    </div>
                    :
                    <div className="container_messages">
                        <h2>No cuentas con los permisos necesarios para ver este contenido</h2>
                        <button className="btn btn_primary" onClick={() => navigate("/")}>Ir al inicio</button>
                        <button className="btn btn_primary" onClick={() => navigate("/sing-in")}>Ir a iniciar sesión</button>
                    </div>
                }
            </div>
        )
    }

    React.useEffect(() => {
        if (typeof permission === 'boolean') {
            setShowContent(permission);
            setInLoad(loading);
        } else if (permission === undefined) {
            funciones.current.validaSesion().then(response => {
                setShowContent(response);
                setInLoad(false);
            });
        } else {
            setShowContent(false);
            setInLoad(loading);
        }
    }, [permission, loading]);

    return (
        <>
            {menu ?
                <>
                    {showContent ?
                        children
                        :
                        <Mensaje />
                    }
                </>
                :
                <div className="container">
                    {showContent ?
                        children
                        :
                        <Mensaje />
                    }
                </div>
            }
        </>
    )
}
