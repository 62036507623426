const ciudades = [
    { codigo: "05001", ciudad: "Medellín", departamento: "Antioquia" },
    { codigo: "05002", ciudad: "Abejorral", departamento: "Antioquia" },
    { codigo: "05004", ciudad: "Abriaquí", departamento: "Antioquia" },
    { codigo: "05021", ciudad: "Alejandría", departamento: "Antioquia" },
    { codigo: "05030", ciudad: "Amagá", departamento: "Antioquia" },
    { codigo: "05031", ciudad: "Amalfi", departamento: "Antioquia" },
    { codigo: "05034", ciudad: "Andes", departamento: "Antioquia" },
    { codigo: "05036", ciudad: "Angelópolis", departamento: "Antioquia" },
    { codigo: "05038", ciudad: "Angostura", departamento: "Antioquia" },
    { codigo: "05040", ciudad: "Anorí", departamento: "Antioquia" },
    { codigo: "05042", ciudad: "Santa fé de antioquia", departamento: "Antioquia" },
    { codigo: "05044", ciudad: "Anzá", departamento: "Antioquia" },
    { codigo: "05045", ciudad: "Apartadó", departamento: "Antioquia" },
    { codigo: "05051", ciudad: "Arboletes", departamento: "Antioquia" },
    { codigo: "05055", ciudad: "Argelia", departamento: "Antioquia" },
    { codigo: "05059", ciudad: "Armenia", departamento: "Antioquia" },
    { codigo: "05079", ciudad: "Barbosa", departamento: "Antioquia" },
    { codigo: "05086", ciudad: "Belmira", departamento: "Antioquia" },
    { codigo: "05088", ciudad: "Bello", departamento: "Antioquia" },
    { codigo: "05091", ciudad: "Betania", departamento: "Antioquia" },
    { codigo: "05093", ciudad: "Betulia", departamento: "Antioquia" },
    { codigo: "05101", ciudad: "Ciudad bolívar", departamento: "Antioquia" },
    { codigo: "05107", ciudad: "Briceño", departamento: "Antioquia" },
    { codigo: "05113", ciudad: "Buriticá", departamento: "Antioquia" },
    { codigo: "05120", ciudad: "Cáceres", departamento: "Antioquia" },
    { codigo: "05125", ciudad: "Caicedo", departamento: "Antioquia" },
    { codigo: "05129", ciudad: "Caldas", departamento: "Antioquia" },
    { codigo: "05134", ciudad: "Campamento", departamento: "Antioquia" },
    { codigo: "05138", ciudad: "Cañasgordas", departamento: "Antioquia" },
    { codigo: "05142", ciudad: "Caracolí", departamento: "Antioquia" },
    { codigo: "05145", ciudad: "Caramanta", departamento: "Antioquia" },
    { codigo: "05147", ciudad: "Carepa", departamento: "Antioquia" },
    { codigo: "05148", ciudad: "El carmen de viboral", departamento: "Antioquia" },
    { codigo: "05150", ciudad: "Carolina", departamento: "Antioquia" },
    { codigo: "05154", ciudad: "Caucasia", departamento: "Antioquia" },
    { codigo: "05172", ciudad: "Chigorodó", departamento: "Antioquia" },
    { codigo: "05190", ciudad: "Cisneros", departamento: "Antioquia" },
    { codigo: "05197", ciudad: "Cocorná", departamento: "Antioquia" },
    { codigo: "05206", ciudad: "Concepción", departamento: "Antioquia" },
    { codigo: "05209", ciudad: "Concordia", departamento: "Antioquia" },
    { codigo: "05212", ciudad: "Copacabana", departamento: "Antioquia" },
    { codigo: "05234", ciudad: "Dabeiba", departamento: "Antioquia" },
    { codigo: "05237", ciudad: "Donmatías", departamento: "Antioquia" },
    { codigo: "05240", ciudad: "Ebéjico", departamento: "Antioquia" },
    { codigo: "05250", ciudad: "El bagre", departamento: "Antioquia" },
    { codigo: "05264", ciudad: "Entrerríos", departamento: "Antioquia" },
    { codigo: "05266", ciudad: "Envigado", departamento: "Antioquia" },
    { codigo: "05282", ciudad: "Fredonia", departamento: "Antioquia" },
    { codigo: "05284", ciudad: "Frontino", departamento: "Antioquia" },
    { codigo: "05306", ciudad: "Giraldo", departamento: "Antioquia" },
    { codigo: "05308", ciudad: "Girardota", departamento: "Antioquia" },
    { codigo: "05310", ciudad: "Gómez plata", departamento: "Antioquia" },
    { codigo: "05313", ciudad: "Granada", departamento: "Antioquia" },
    { codigo: "05315", ciudad: "Guadalupe", departamento: "Antioquia" },
    { codigo: "05318", ciudad: "Guarne", departamento: "Antioquia" },
    { codigo: "05321", ciudad: "Guatapé", departamento: "Antioquia" },
    { codigo: "05347", ciudad: "Heliconia", departamento: "Antioquia" },
    { codigo: "05353", ciudad: "Hispania", departamento: "Antioquia" },
    { codigo: "05360", ciudad: "Itagüí", departamento: "Antioquia" },
    { codigo: "05361", ciudad: "Ituango", departamento: "Antioquia" },
    { codigo: "05364", ciudad: "Jardín", departamento: "Antioquia" },
    { codigo: "05368", ciudad: "Jericó", departamento: "Antioquia" },
    { codigo: "05376", ciudad: "La ceja", departamento: "Antioquia" },
    { codigo: "05380", ciudad: "La estrella", departamento: "Antioquia" },
    { codigo: "05390", ciudad: "La pintada", departamento: "Antioquia" },
    { codigo: "05400", ciudad: "La unión", departamento: "Antioquia" },
    { codigo: "05411", ciudad: "Liborina", departamento: "Antioquia" },
    { codigo: "05425", ciudad: "Maceo", departamento: "Antioquia" },
    { codigo: "05440", ciudad: "Marinilla", departamento: "Antioquia" },
    { codigo: "05467", ciudad: "Montebello", departamento: "Antioquia" },
    { codigo: "05475", ciudad: "Murindó", departamento: "Antioquia" },
    { codigo: "05480", ciudad: "Mutatá", departamento: "Antioquia" },
    { codigo: "05483", ciudad: "Nariño", departamento: "Antioquia" },
    { codigo: "05490", ciudad: "Necoclí", departamento: "Antioquia" },
    { codigo: "05495", ciudad: "Nechí", departamento: "Antioquia" },
    { codigo: "05501", ciudad: "Olaya", departamento: "Antioquia" },
    { codigo: "05541", ciudad: "Peñol", departamento: "Antioquia" },
    { codigo: "05543", ciudad: "Peque", departamento: "Antioquia" },
    { codigo: "05576", ciudad: "Pueblorrico", departamento: "Antioquia" },
    { codigo: "05579", ciudad: "Puerto berrío", departamento: "Antioquia" },
    { codigo: "05585", ciudad: "Puerto nare", departamento: "Antioquia" },
    { codigo: "05591", ciudad: "Puerto triunfo", departamento: "Antioquia" },
    { codigo: "05604", ciudad: "Remedios", departamento: "Antioquia" },
    { codigo: "05607", ciudad: "Retiro", departamento: "Antioquia" },
    { codigo: "05615", ciudad: "Rionegro", departamento: "Antioquia" },
    { codigo: "05628", ciudad: "Sabanalarga", departamento: "Antioquia" },
    { codigo: "05631", ciudad: "Sabaneta", departamento: "Antioquia" },
    { codigo: "05642", ciudad: "Salgar", departamento: "Antioquia" },
    { codigo: "05647", ciudad: "San andrés de cuerquía", departamento: "Antioquia" },
    { codigo: "05649", ciudad: "San carlos", departamento: "Antioquia" },
    { codigo: "05652", ciudad: "San francisco", departamento: "Antioquia" },
    { codigo: "05656", ciudad: "San jerónimo", departamento: "Antioquia" },
    { codigo: "05658", ciudad: "San josé de la montaña", departamento: "Antioquia" },
    { codigo: "05659", ciudad: "San juan de urabá", departamento: "Antioquia" },
    { codigo: "05660", ciudad: "San luis", departamento: "Antioquia" },
    { codigo: "05664", ciudad: "San pedro de los milagros", departamento: "Antioquia" },
    { codigo: "05665", ciudad: "San pedro de urabá", departamento: "Antioquia" },
    { codigo: "05667", ciudad: "San rafael", departamento: "Antioquia" },
    { codigo: "05670", ciudad: "San roque", departamento: "Antioquia" },
    { codigo: "05674", ciudad: "San vicente ferrer", departamento: "Antioquia" },
    { codigo: "05679", ciudad: "Santa bárbara", departamento: "Antioquia" },
    { codigo: "05686", ciudad: "Santa rosa de osos", departamento: "Antioquia" },
    { codigo: "05690", ciudad: "Santo domingo", departamento: "Antioquia" },
    { codigo: "05697", ciudad: "El santuario", departamento: "Antioquia" },
    { codigo: "05736", ciudad: "Segovia", departamento: "Antioquia" },
    { codigo: "05756", ciudad: "Sonsón", departamento: "Antioquia" },
    { codigo: "05761", ciudad: "Sopetrán", departamento: "Antioquia" },
    { codigo: "05789", ciudad: "Támesis", departamento: "Antioquia" },
    { codigo: "05790", ciudad: "Tarazá", departamento: "Antioquia" },
    { codigo: "05792", ciudad: "Tarso", departamento: "Antioquia" },
    { codigo: "05809", ciudad: "Titiribí", departamento: "Antioquia" },
    { codigo: "05819", ciudad: "Toledo", departamento: "Antioquia" },
    { codigo: "05837", ciudad: "Turbo", departamento: "Antioquia" },
    { codigo: "05842", ciudad: "Uramita", departamento: "Antioquia" },
    { codigo: "05847", ciudad: "Urrao", departamento: "Antioquia" },
    { codigo: "05854", ciudad: "Valdivia", departamento: "Antioquia" },
    { codigo: "05856", ciudad: "Valparaíso", departamento: "Antioquia" },
    { codigo: "05858", ciudad: "Vegachí", departamento: "Antioquia" },
    { codigo: "05861", ciudad: "Venecia", departamento: "Antioquia" },
    { codigo: "05873", ciudad: "Vigía del fuerte", departamento: "Antioquia" },
    { codigo: "05885", ciudad: "Yalí", departamento: "Antioquia" },
    { codigo: "05887", ciudad: "Yarumal", departamento: "Antioquia" },
    { codigo: "05890", ciudad: "Yolombó", departamento: "Antioquia" },
    { codigo: "05893", ciudad: "Yondó", departamento: "Antioquia" },
    { codigo: "05895", ciudad: "Zaragoza", departamento: "Antioquia" },
    { codigo: "08001", ciudad: "Barranquilla", departamento: "Atlántico" },
    { codigo: "08078", ciudad: "Baranoa", departamento: "Atlántico" },
    { codigo: "08137", ciudad: "Campo de la cruz", departamento: "Atlántico" },
    { codigo: "08141", ciudad: "Candelaria", departamento: "Atlántico" },
    { codigo: "08296", ciudad: "Galapa", departamento: "Atlántico" },
    { codigo: "08372", ciudad: "Juan de acosta", departamento: "Atlántico" },
    { codigo: "08421", ciudad: "Luruaco", departamento: "Atlántico" },
    { codigo: "08433", ciudad: "Malambo", departamento: "Atlántico" },
    { codigo: "08436", ciudad: "Manatí", departamento: "Atlántico" },
    { codigo: "08520", ciudad: "Palmar de varela", departamento: "Atlántico" },
    { codigo: "08549", ciudad: "Piojó", departamento: "Atlántico" },
    { codigo: "08558", ciudad: "Polonuevo", departamento: "Atlántico" },
    { codigo: "08560", ciudad: "Ponedera", departamento: "Atlántico" },
    { codigo: "08573", ciudad: "Puerto colombia", departamento: "Atlántico" },
    { codigo: "08606", ciudad: "Repelón", departamento: "Atlántico" },
    { codigo: "08634", ciudad: "Sabanagrande", departamento: "Atlántico" },
    { codigo: "08638", ciudad: "Sabanalarga", departamento: "Atlántico" },
    { codigo: "08675", ciudad: "Santa lucía", departamento: "Atlántico" },
    { codigo: "08685", ciudad: "Santo tomás", departamento: "Atlántico" },
    { codigo: "08758", ciudad: "Soledad", departamento: "Atlántico" },
    { codigo: "08770", ciudad: "Suan", departamento: "Atlántico" },
    { codigo: "08832", ciudad: "Tubará", departamento: "Atlántico" },
    { codigo: "08849", ciudad: "Usiacurí", departamento: "Atlántico" },
    { codigo: "11001", ciudad: "Bogotá, D.C.", departamento: "Cundinamarca" },
    { codigo: "11001", ciudad: "Bogotá, D.C.", departamento: "Bogotá, D.C." },
    { codigo: "13001", ciudad: "Cartagena de indias", departamento: "Bolívar" },
    { codigo: "13006", ciudad: "Achí", departamento: "Bolívar" },
    { codigo: "13030", ciudad: "Altos del rosario", departamento: "Bolívar" },
    { codigo: "13042", ciudad: "Arenal", departamento: "Bolívar" },
    { codigo: "13052", ciudad: "Arjona", departamento: "Bolívar" },
    { codigo: "13062", ciudad: "Arroyohondo", departamento: "Bolívar" },
    { codigo: "13074", ciudad: "Barranco de loba", departamento: "Bolívar" },
    { codigo: "13140", ciudad: "Calamar", departamento: "Bolívar" },
    { codigo: "13160", ciudad: "Cantagallo", departamento: "Bolívar" },
    { codigo: "13188", ciudad: "Cicuco", departamento: "Bolívar" },
    { codigo: "13212", ciudad: "Córdoba", departamento: "Bolívar" },
    { codigo: "13222", ciudad: "Clemencia", departamento: "Bolívar" },
    { codigo: "13244", ciudad: "El carmen de bolívar", departamento: "Bolívar" },
    { codigo: "13248", ciudad: "El guamo", departamento: "Bolívar" },
    { codigo: "13268", ciudad: "El peñón", departamento: "Bolívar" },
    { codigo: "13300", ciudad: "Hatillo de loba", departamento: "Bolívar" },
    { codigo: "13430", ciudad: "Magangué", departamento: "Bolívar" },
    { codigo: "13433", ciudad: "Mahates", departamento: "Bolívar" },
    { codigo: "13440", ciudad: "Margarita", departamento: "Bolívar" },
    { codigo: "13442", ciudad: "María la baja", departamento: "Bolívar" },
    { codigo: "13458", ciudad: "Montecristo", departamento: "Bolívar" },
    { codigo: "13468", ciudad: "Santa cruz de mompox", departamento: "Bolívar" },
    { codigo: "13473", ciudad: "Morales", departamento: "Bolívar" },
    { codigo: "13490", ciudad: "Norosí", departamento: "Bolívar" },
    { codigo: "13549", ciudad: "Pinillos", departamento: "Bolívar" },
    { codigo: "13580", ciudad: "Regidor", departamento: "Bolívar" },
    { codigo: "13600", ciudad: "Río viejo", departamento: "Bolívar" },
    { codigo: "13620", ciudad: "San cristóbal", departamento: "Bolívar" },
    { codigo: "13647", ciudad: "San estanislao", departamento: "Bolívar" },
    { codigo: "13650", ciudad: "San fernando", departamento: "Bolívar" },
    { codigo: "13654", ciudad: "San jacinto", departamento: "Bolívar" },
    { codigo: "13655", ciudad: "San jacinto del cauca", departamento: "Bolívar" },
    { codigo: "13657", ciudad: "San juan nepomuceno", departamento: "Bolívar" },
    { codigo: "13667", ciudad: "San martín de loba", departamento: "Bolívar" },
    { codigo: "13670", ciudad: "San pablo", departamento: "Bolívar" },
    { codigo: "13673", ciudad: "Santa catalina", departamento: "Bolívar" },
    { codigo: "13683", ciudad: "Santa rosa", departamento: "Bolívar" },
    { codigo: "13688", ciudad: "Santa rosa del sur", departamento: "Bolívar" },
    { codigo: "13744", ciudad: "Simití", departamento: "Bolívar" },
    { codigo: "13760", ciudad: "Soplaviento", departamento: "Bolívar" },
    { codigo: "13780", ciudad: "Talaigua nuevo", departamento: "Bolívar" },
    { codigo: "13810", ciudad: "Tiquisio", departamento: "Bolívar" },
    { codigo: "13836", ciudad: "Turbaco", departamento: "Bolívar" },
    { codigo: "13838", ciudad: "Turbana", departamento: "Bolívar" },
    { codigo: "13873", ciudad: "Villanueva", departamento: "Bolívar" },
    { codigo: "13894", ciudad: "Zambrano", departamento: "Bolívar" },
    { codigo: "15001", ciudad: "Tunja", departamento: "Boyacá" },
    { codigo: "15022", ciudad: "Almeida", departamento: "Boyacá" },
    { codigo: "15047", ciudad: "Aquitania", departamento: "Boyacá" },
    { codigo: "15051", ciudad: "Arcabuco", departamento: "Boyacá" },
    { codigo: "15087", ciudad: "Belén", departamento: "Boyacá" },
    { codigo: "15090", ciudad: "Berbeo", departamento: "Boyacá" },
    { codigo: "15092", ciudad: "Betéitiva", departamento: "Boyacá" },
    { codigo: "15097", ciudad: "Boavita", departamento: "Boyacá" },
    { codigo: "15104", ciudad: "Boyacá", departamento: "Boyacá" },
    { codigo: "15106", ciudad: "Briceño", departamento: "Boyacá" },
    { codigo: "15109", ciudad: "Buenavista", departamento: "Boyacá" },
    { codigo: "15114", ciudad: "Busbanzá", departamento: "Boyacá" },
    { codigo: "15131", ciudad: "Caldas", departamento: "Boyacá" },
    { codigo: "15135", ciudad: "Campohermoso", departamento: "Boyacá" },
    { codigo: "15162", ciudad: "Cerinza", departamento: "Boyacá" },
    { codigo: "15172", ciudad: "Chinavita", departamento: "Boyacá" },
    { codigo: "15176", ciudad: "Chiquinquirá", departamento: "Boyacá" },
    { codigo: "15180", ciudad: "Chiscas", departamento: "Boyacá" },
    { codigo: "15183", ciudad: "Chita", departamento: "Boyacá" },
    { codigo: "15185", ciudad: "Chitaraque", departamento: "Boyacá" },
    { codigo: "15187", ciudad: "Chivatá", departamento: "Boyacá" },
    { codigo: "15189", ciudad: "Ciénega", departamento: "Boyacá" },
    { codigo: "15204", ciudad: "Cómbita", departamento: "Boyacá" },
    { codigo: "15212", ciudad: "Coper", departamento: "Boyacá" },
    { codigo: "15215", ciudad: "Corrales", departamento: "Boyacá" },
    { codigo: "15218", ciudad: "Covarachía", departamento: "Boyacá" },
    { codigo: "15223", ciudad: "Cubará", departamento: "Boyacá" },
    { codigo: "15224", ciudad: "Cucaita", departamento: "Boyacá" },
    { codigo: "15226", ciudad: "Cuítiva", departamento: "Boyacá" },
    { codigo: "15232", ciudad: "Chíquiza", departamento: "Boyacá" },
    { codigo: "15236", ciudad: "Chivor", departamento: "Boyacá" },
    { codigo: "15238", ciudad: "Duitama", departamento: "Boyacá" },
    { codigo: "15244", ciudad: "El cocuy", departamento: "Boyacá" },
    { codigo: "15248", ciudad: "El espino", departamento: "Boyacá" },
    { codigo: "15272", ciudad: "Firavitoba", departamento: "Boyacá" },
    { codigo: "15276", ciudad: "Floresta", departamento: "Boyacá" },
    { codigo: "15293", ciudad: "Gachantivá", departamento: "Boyacá" },
    { codigo: "15296", ciudad: "Gámeza", departamento: "Boyacá" },
    { codigo: "15299", ciudad: "Garagoa", departamento: "Boyacá" },
    { codigo: "15317", ciudad: "Guacamayas", departamento: "Boyacá" },
    { codigo: "15322", ciudad: "Guateque", departamento: "Boyacá" },
    { codigo: "15325", ciudad: "Guayatá", departamento: "Boyacá" },
    { codigo: "15332", ciudad: "Güicán de la sierra", departamento: "Boyacá" },
    { codigo: "15362", ciudad: "Iza", departamento: "Boyacá" },
    { codigo: "15367", ciudad: "Jenesano", departamento: "Boyacá" },
    { codigo: "15368", ciudad: "Jericó", departamento: "Boyacá" },
    { codigo: "15377", ciudad: "Labranzagrande", departamento: "Boyacá" },
    { codigo: "15380", ciudad: "La capilla", departamento: "Boyacá" },
    { codigo: "15401", ciudad: "La victoria", departamento: "Boyacá" },
    { codigo: "15403", ciudad: "La uvita", departamento: "Boyacá" },
    { codigo: "15407", ciudad: "Villa de leyva", departamento: "Boyacá" },
    { codigo: "15425", ciudad: "Macanal", departamento: "Boyacá" },
    { codigo: "15442", ciudad: "Maripí", departamento: "Boyacá" },
    { codigo: "15455", ciudad: "Miraflores", departamento: "Boyacá" },
    { codigo: "15464", ciudad: "Mongua", departamento: "Boyacá" },
    { codigo: "15466", ciudad: "Monguí", departamento: "Boyacá" },
    { codigo: "15469", ciudad: "Moniquirá", departamento: "Boyacá" },
    { codigo: "15476", ciudad: "Motavita", departamento: "Boyacá" },
    { codigo: "15480", ciudad: "Muzo", departamento: "Boyacá" },
    { codigo: "15491", ciudad: "Nobsa", departamento: "Boyacá" },
    { codigo: "15494", ciudad: "Nuevo colón", departamento: "Boyacá" },
    { codigo: "15500", ciudad: "Oicatá", departamento: "Boyacá" },
    { codigo: "15507", ciudad: "Otanche", departamento: "Boyacá" },
    { codigo: "15511", ciudad: "Pachavita", departamento: "Boyacá" },
    { codigo: "15514", ciudad: "Páez", departamento: "Boyacá" },
    { codigo: "15516", ciudad: "Paipa", departamento: "Boyacá" },
    { codigo: "15518", ciudad: "Pajarito", departamento: "Boyacá" },
    { codigo: "15522", ciudad: "Panqueba", departamento: "Boyacá" },
    { codigo: "15531", ciudad: "Pauna", departamento: "Boyacá" },
    { codigo: "15533", ciudad: "Paya", departamento: "Boyacá" },
    { codigo: "15537", ciudad: "Paz de río", departamento: "Boyacá" },
    { codigo: "15542", ciudad: "Pesca", departamento: "Boyacá" },
    { codigo: "15550", ciudad: "Pisba", departamento: "Boyacá" },
    { codigo: "15572", ciudad: "Puerto boyacá", departamento: "Boyacá" },
    { codigo: "15580", ciudad: "Quípama", departamento: "Boyacá" },
    { codigo: "15599", ciudad: "Ramiriquí", departamento: "Boyacá" },
    { codigo: "15600", ciudad: "Ráquira", departamento: "Boyacá" },
    { codigo: "15621", ciudad: "Rondón", departamento: "Boyacá" },
    { codigo: "15632", ciudad: "Saboyá", departamento: "Boyacá" },
    { codigo: "15638", ciudad: "Sáchica", departamento: "Boyacá" },
    { codigo: "15646", ciudad: "Samacá", departamento: "Boyacá" },
    { codigo: "15660", ciudad: "San eduardo", departamento: "Boyacá" },
    { codigo: "15664", ciudad: "San josé de pare", departamento: "Boyacá" },
    { codigo: "15667", ciudad: "San luis de gaceno", departamento: "Boyacá" },
    { codigo: "15673", ciudad: "San mateo", departamento: "Boyacá" },
    { codigo: "15676", ciudad: "San miguel de sema", departamento: "Boyacá" },
    { codigo: "15681", ciudad: "San pablo de borbur", departamento: "Boyacá" },
    { codigo: "15686", ciudad: "Santana", departamento: "Boyacá" },
    { codigo: "15690", ciudad: "Santa maría", departamento: "Boyacá" },
    { codigo: "15693", ciudad: "Santa rosa de viterbo", departamento: "Boyacá" },
    { codigo: "15696", ciudad: "Santa sofía", departamento: "Boyacá" },
    { codigo: "15720", ciudad: "Sativanorte", departamento: "Boyacá" },
    { codigo: "15723", ciudad: "Sativasur", departamento: "Boyacá" },
    { codigo: "15740", ciudad: "Siachoque", departamento: "Boyacá" },
    { codigo: "15753", ciudad: "Soatá", departamento: "Boyacá" },
    { codigo: "15755", ciudad: "Socotá", departamento: "Boyacá" },
    { codigo: "15757", ciudad: "Socha", departamento: "Boyacá" },
    { codigo: "15759", ciudad: "Sogamoso", departamento: "Boyacá" },
    { codigo: "15761", ciudad: "Somondoco", departamento: "Boyacá" },
    { codigo: "15762", ciudad: "Sora", departamento: "Boyacá" },
    { codigo: "15763", ciudad: "Sotaquirá", departamento: "Boyacá" },
    { codigo: "15764", ciudad: "Soracá", departamento: "Boyacá" },
    { codigo: "15774", ciudad: "Susacón", departamento: "Boyacá" },
    { codigo: "15776", ciudad: "Sutamarchán", departamento: "Boyacá" },
    { codigo: "15778", ciudad: "Sutatenza", departamento: "Boyacá" },
    { codigo: "15790", ciudad: "Tasco", departamento: "Boyacá" },
    { codigo: "15798", ciudad: "Tenza", departamento: "Boyacá" },
    { codigo: "15804", ciudad: "Tibaná", departamento: "Boyacá" },
    { codigo: "15806", ciudad: "Tibasosa", departamento: "Boyacá" },
    { codigo: "15808", ciudad: "Tinjacá", departamento: "Boyacá" },
    { codigo: "15810", ciudad: "Tipacoque", departamento: "Boyacá" },
    { codigo: "15814", ciudad: "Toca", departamento: "Boyacá" },
    { codigo: "15816", ciudad: "Togüí", departamento: "Boyacá" },
    { codigo: "15820", ciudad: "Tópaga", departamento: "Boyacá" },
    { codigo: "15822", ciudad: "Tota", departamento: "Boyacá" },
    { codigo: "15832", ciudad: "Tununguá", departamento: "Boyacá" },
    { codigo: "15835", ciudad: "Turmequé", departamento: "Boyacá" },
    { codigo: "15837", ciudad: "Tuta", departamento: "Boyacá" },
    { codigo: "15839", ciudad: "Tutazá", departamento: "Boyacá" },
    { codigo: "15842", ciudad: "Úmbita", departamento: "Boyacá" },
    { codigo: "15861", ciudad: "Ventaquemada", departamento: "Boyacá" },
    { codigo: "15879", ciudad: "Viracachá", departamento: "Boyacá" },
    { codigo: "15897", ciudad: "Zetaquira", departamento: "Boyacá" },
    { codigo: "17001", ciudad: "Manizales", departamento: "Caldas" },
    { codigo: "17013", ciudad: "Aguadas", departamento: "Caldas" },
    { codigo: "17042", ciudad: "Anserma", departamento: "Caldas" },
    { codigo: "17050", ciudad: "Aranzazu", departamento: "Caldas" },
    { codigo: "17088", ciudad: "Belalcázar", departamento: "Caldas" },
    { codigo: "17174", ciudad: "Chinchiná", departamento: "Caldas" },
    { codigo: "17272", ciudad: "Filadelfia", departamento: "Caldas" },
    { codigo: "17380", ciudad: "La dorada", departamento: "Caldas" },
    { codigo: "17388", ciudad: "La merced", departamento: "Caldas" },
    { codigo: "17433", ciudad: "Manzanares", departamento: "Caldas" },
    { codigo: "17442", ciudad: "Marmato", departamento: "Caldas" },
    { codigo: "17444", ciudad: "Marquetalia", departamento: "Caldas" },
    { codigo: "17446", ciudad: "Marulanda", departamento: "Caldas" },
    { codigo: "17486", ciudad: "Neira", departamento: "Caldas" },
    { codigo: "17495", ciudad: "Norcasia", departamento: "Caldas" },
    { codigo: "17513", ciudad: "Pácora", departamento: "Caldas" },
    { codigo: "17524", ciudad: "Palestina", departamento: "Caldas" },
    { codigo: "17541", ciudad: "Pensilvania", departamento: "Caldas" },
    { codigo: "17614", ciudad: "Riosucio", departamento: "Caldas" },
    { codigo: "17616", ciudad: "Risaralda", departamento: "Caldas" },
    { codigo: "17653", ciudad: "Salamina", departamento: "Caldas" },
    { codigo: "17662", ciudad: "Samaná", departamento: "Caldas" },
    { codigo: "17665", ciudad: "San josé", departamento: "Caldas" },
    { codigo: "17777", ciudad: "Supía", departamento: "Caldas" },
    { codigo: "17867", ciudad: "Victoria", departamento: "Caldas" },
    { codigo: "17873", ciudad: "Villamaría", departamento: "Caldas" },
    { codigo: "17877", ciudad: "Viterbo", departamento: "Caldas" },
    { codigo: "18001", ciudad: "Florencia", departamento: "Caquetá" },
    { codigo: "18029", ciudad: "Albania", departamento: "Caquetá" },
    { codigo: "18094", ciudad: "Belén de los andaquíes", departamento: "Caquetá" },
    { codigo: "18150", ciudad: "Cartagena del chairá", departamento: "Caquetá" },
    { codigo: "18205", ciudad: "Curillo", departamento: "Caquetá" },
    { codigo: "18247", ciudad: "El doncello", departamento: "Caquetá" },
    { codigo: "18256", ciudad: "El paujíl", departamento: "Caquetá" },
    { codigo: "18410", ciudad: "La montañita", departamento: "Caquetá" },
    { codigo: "18460", ciudad: "Milán", departamento: "Caquetá" },
    { codigo: "18479", ciudad: "Morelia", departamento: "Caquetá" },
    { codigo: "18592", ciudad: "Puerto rico", departamento: "Caquetá" },
    { codigo: "18610", ciudad: "San josé del fragua", departamento: "Caquetá" },
    { codigo: "18753", ciudad: "San vicente del caguán", departamento: "Caquetá" },
    { codigo: "18756", ciudad: "Solano", departamento: "Caquetá" },
    { codigo: "18785", ciudad: "Solita", departamento: "Caquetá" },
    { codigo: "18860", ciudad: "Valparaíso", departamento: "Caquetá" },
    { codigo: "19001", ciudad: "Popayán", departamento: "Cauca" },
    { codigo: "19022", ciudad: "Almaguer", departamento: "Cauca" },
    { codigo: "19050", ciudad: "Argelia", departamento: "Cauca" },
    { codigo: "19075", ciudad: "Balboa", departamento: "Cauca" },
    { codigo: "19100", ciudad: "Bolívar", departamento: "Cauca" },
    { codigo: "19110", ciudad: "Buenos aires", departamento: "Cauca" },
    { codigo: "19130", ciudad: "Cajibío", departamento: "Cauca" },
    { codigo: "19137", ciudad: "Caldono", departamento: "Cauca" },
    { codigo: "19142", ciudad: "Caloto", departamento: "Cauca" },
    { codigo: "19212", ciudad: "Corinto", departamento: "Cauca" },
    { codigo: "19256", ciudad: "El tambo", departamento: "Cauca" },
    { codigo: "19290", ciudad: "Florencia", departamento: "Cauca" },
    { codigo: "19300", ciudad: "Guachené", departamento: "Cauca" },
    { codigo: "19318", ciudad: "Guapi", departamento: "Cauca" },
    { codigo: "19355", ciudad: "Inzá", departamento: "Cauca" },
    { codigo: "19364", ciudad: "Jambaló", departamento: "Cauca" },
    { codigo: "19392", ciudad: "La sierra", departamento: "Cauca" },
    { codigo: "19397", ciudad: "La vega", departamento: "Cauca" },
    { codigo: "19418", ciudad: "López de micay", departamento: "Cauca" },
    { codigo: "19450", ciudad: "Mercaderes", departamento: "Cauca" },
    { codigo: "19455", ciudad: "Miranda", departamento: "Cauca" },
    { codigo: "19473", ciudad: "Morales", departamento: "Cauca" },
    { codigo: "19513", ciudad: "Padilla", departamento: "Cauca" },
    { codigo: "19517", ciudad: "Páez", departamento: "Cauca" },
    { codigo: "19532", ciudad: "Patía", departamento: "Cauca" },
    { codigo: "19533", ciudad: "Piamonte", departamento: "Cauca" },
    { codigo: "19548", ciudad: "Piendamó - tunía", departamento: "Cauca" },
    { codigo: "19573", ciudad: "Puerto tejada", departamento: "Cauca" },
    { codigo: "19585", ciudad: "Puracé", departamento: "Cauca" },
    { codigo: "19622", ciudad: "Rosas", departamento: "Cauca" },
    { codigo: "19693", ciudad: "San sebastián", departamento: "Cauca" },
    { codigo: "19698", ciudad: "Santander de quilichao", departamento: "Cauca" },
    { codigo: "19701", ciudad: "Santa rosa", departamento: "Cauca" },
    { codigo: "19743", ciudad: "Silvia", departamento: "Cauca" },
    { codigo: "19760", ciudad: "Sotará paispamba", departamento: "Cauca" },
    { codigo: "19780", ciudad: "Suárez", departamento: "Cauca" },
    { codigo: "19785", ciudad: "Sucre", departamento: "Cauca" },
    { codigo: "19807", ciudad: "Timbío", departamento: "Cauca" },
    { codigo: "19809", ciudad: "Timbiquí", departamento: "Cauca" },
    { codigo: "19821", ciudad: "Toribío", departamento: "Cauca" },
    { codigo: "19824", ciudad: "Totoró", departamento: "Cauca" },
    { codigo: "19845", ciudad: "Villa rica", departamento: "Cauca" },
    { codigo: "20001", ciudad: "Valledupar", departamento: "Cesar" },
    { codigo: "20011", ciudad: "Aguachica", departamento: "Cesar" },
    { codigo: "20013", ciudad: "Agustín codazzi", departamento: "Cesar" },
    { codigo: "20032", ciudad: "Astrea", departamento: "Cesar" },
    { codigo: "20045", ciudad: "Becerril", departamento: "Cesar" },
    { codigo: "20060", ciudad: "Bosconia", departamento: "Cesar" },
    { codigo: "20175", ciudad: "Chimichagua", departamento: "Cesar" },
    { codigo: "20178", ciudad: "Chiriguaná", departamento: "Cesar" },
    { codigo: "20228", ciudad: "Curumaní", departamento: "Cesar" },
    { codigo: "20238", ciudad: "El copey", departamento: "Cesar" },
    { codigo: "20250", ciudad: "El paso", departamento: "Cesar" },
    { codigo: "20295", ciudad: "Gamarra", departamento: "Cesar" },
    { codigo: "20310", ciudad: "González", departamento: "Cesar" },
    { codigo: "20383", ciudad: "La gloria", departamento: "Cesar" },
    { codigo: "20400", ciudad: "La jagua de ibirico", departamento: "Cesar" },
    { codigo: "20443", ciudad: "Manaure balcón del cesar", departamento: "Cesar" },
    { codigo: "20517", ciudad: "Pailitas", departamento: "Cesar" },
    { codigo: "20550", ciudad: "Pelaya", departamento: "Cesar" },
    { codigo: "20570", ciudad: "Pueblo bello", departamento: "Cesar" },
    { codigo: "20614", ciudad: "Río de oro", departamento: "Cesar" },
    { codigo: "20621", ciudad: "La paz", departamento: "Cesar" },
    { codigo: "20710", ciudad: "San alberto", departamento: "Cesar" },
    { codigo: "20750", ciudad: "San diego", departamento: "Cesar" },
    { codigo: "20770", ciudad: "San martín", departamento: "Cesar" },
    { codigo: "20787", ciudad: "Tamalameque", departamento: "Cesar" },
    { codigo: "23001", ciudad: "Montería", departamento: "Córdoba" },
    { codigo: "23068", ciudad: "Ayapel", departamento: "Córdoba" },
    { codigo: "23079", ciudad: "Buenavista", departamento: "Córdoba" },
    { codigo: "23090", ciudad: "Canalete", departamento: "Córdoba" },
    { codigo: "23162", ciudad: "Cereté", departamento: "Córdoba" },
    { codigo: "23168", ciudad: "Chimá", departamento: "Córdoba" },
    { codigo: "23182", ciudad: "Chinú", departamento: "Córdoba" },
    { codigo: "23189", ciudad: "Ciénaga de oro", departamento: "Córdoba" },
    { codigo: "23300", ciudad: "Cotorra", departamento: "Córdoba" },
    { codigo: "23350", ciudad: "La apartada", departamento: "Córdoba" },
    { codigo: "23417", ciudad: "Lorica", departamento: "Córdoba" },
    { codigo: "23419", ciudad: "Los córdobas", departamento: "Córdoba" },
    { codigo: "23464", ciudad: "Momil", departamento: "Córdoba" },
    { codigo: "23466", ciudad: "Montelíbano", departamento: "Córdoba" },
    { codigo: "23500", ciudad: "Moñitos", departamento: "Córdoba" },
    { codigo: "23555", ciudad: "Planeta rica", departamento: "Córdoba" },
    { codigo: "23570", ciudad: "Pueblo nuevo", departamento: "Córdoba" },
    { codigo: "23574", ciudad: "Puerto escondido", departamento: "Córdoba" },
    { codigo: "23580", ciudad: "Puerto libertador", departamento: "Córdoba" },
    { codigo: "23586", ciudad: "Purísima de la concepción", departamento: "Córdoba" },
    { codigo: "23660", ciudad: "Sahagún", departamento: "Córdoba" },
    { codigo: "23670", ciudad: "San andrés de sotavento", departamento: "Córdoba" },
    { codigo: "23672", ciudad: "San antero", departamento: "Córdoba" },
    { codigo: "23675", ciudad: "San bernardo del viento", departamento: "Córdoba" },
    { codigo: "23678", ciudad: "San carlos", departamento: "Córdoba" },
    { codigo: "23682", ciudad: "San josé de uré", departamento: "Córdoba" },
    { codigo: "23686", ciudad: "San pelayo", departamento: "Córdoba" },
    { codigo: "23807", ciudad: "Tierralta", departamento: "Córdoba" },
    { codigo: "23815", ciudad: "Tuchín", departamento: "Córdoba" },
    { codigo: "23855", ciudad: "Valencia", departamento: "Córdoba" },
    { codigo: "25001", ciudad: "Agua de dios", departamento: "Cundinamarca" },
    { codigo: "25019", ciudad: "Albán", departamento: "Cundinamarca" },
    { codigo: "25035", ciudad: "Anapoima", departamento: "Cundinamarca" },
    { codigo: "25040", ciudad: "Anolaima", departamento: "Cundinamarca" },
    { codigo: "25053", ciudad: "Arbeláez", departamento: "Cundinamarca" },
    { codigo: "25086", ciudad: "Beltrán", departamento: "Cundinamarca" },
    { codigo: "25095", ciudad: "Bituima", departamento: "Cundinamarca" },
    { codigo: "25099", ciudad: "Bojacá", departamento: "Cundinamarca" },
    { codigo: "25120", ciudad: "Cabrera", departamento: "Cundinamarca" },
    { codigo: "25123", ciudad: "Cachipay", departamento: "Cundinamarca" },
    { codigo: "25126", ciudad: "Cajicá", departamento: "Cundinamarca" },
    { codigo: "25148", ciudad: "Caparrapí", departamento: "Cundinamarca" },
    { codigo: "25151", ciudad: "Cáqueza", departamento: "Cundinamarca" },
    { codigo: "25154", ciudad: "Carmen de carupa", departamento: "Cundinamarca" },
    { codigo: "25168", ciudad: "Chaguaní", departamento: "Cundinamarca" },
    { codigo: "25175", ciudad: "Chía", departamento: "Cundinamarca" },
    { codigo: "25178", ciudad: "Chipaque", departamento: "Cundinamarca" },
    { codigo: "25181", ciudad: "Choachí", departamento: "Cundinamarca" },
    { codigo: "25183", ciudad: "Chocontá", departamento: "Cundinamarca" },
    { codigo: "25200", ciudad: "Cogua", departamento: "Cundinamarca" },
    { codigo: "25214", ciudad: "Cota", departamento: "Cundinamarca" },
    { codigo: "25224", ciudad: "Cucunubá", departamento: "Cundinamarca" },
    { codigo: "25245", ciudad: "El colegio", departamento: "Cundinamarca" },
    { codigo: "25258", ciudad: "El peñón", departamento: "Cundinamarca" },
    { codigo: "25260", ciudad: "El rosal", departamento: "Cundinamarca" },
    { codigo: "25269", ciudad: "Facatativá", departamento: "Cundinamarca" },
    { codigo: "25279", ciudad: "Fómeque", departamento: "Cundinamarca" },
    { codigo: "25281", ciudad: "Fosca", departamento: "Cundinamarca" },
    { codigo: "25286", ciudad: "Funza", departamento: "Cundinamarca" },
    { codigo: "25288", ciudad: "Fúquene", departamento: "Cundinamarca" },
    { codigo: "25290", ciudad: "Fusagasugá", departamento: "Cundinamarca" },
    { codigo: "25293", ciudad: "Gachalá", departamento: "Cundinamarca" },
    { codigo: "25295", ciudad: "Gachancipá", departamento: "Cundinamarca" },
    { codigo: "25297", ciudad: "Gachetá", departamento: "Cundinamarca" },
    { codigo: "25299", ciudad: "Gama", departamento: "Cundinamarca" },
    { codigo: "25307", ciudad: "Girardot", departamento: "Cundinamarca" },
    { codigo: "25312", ciudad: "Granada", departamento: "Cundinamarca" },
    { codigo: "25317", ciudad: "Guachetá", departamento: "Cundinamarca" },
    { codigo: "25320", ciudad: "Guaduas", departamento: "Cundinamarca" },
    { codigo: "25322", ciudad: "Guasca", departamento: "Cundinamarca" },
    { codigo: "25324", ciudad: "Guataquí", departamento: "Cundinamarca" },
    { codigo: "25326", ciudad: "Guatavita", departamento: "Cundinamarca" },
    { codigo: "25328", ciudad: "Guayabal de síquima", departamento: "Cundinamarca" },
    { codigo: "25335", ciudad: "Guayabetal", departamento: "Cundinamarca" },
    { codigo: "25339", ciudad: "Gutiérrez", departamento: "Cundinamarca" },
    { codigo: "25368", ciudad: "Jerusalén", departamento: "Cundinamarca" },
    { codigo: "25372", ciudad: "Junín", departamento: "Cundinamarca" },
    { codigo: "25377", ciudad: "La calera", departamento: "Cundinamarca" },
    { codigo: "25386", ciudad: "La mesa", departamento: "Cundinamarca" },
    { codigo: "25394", ciudad: "La palma", departamento: "Cundinamarca" },
    { codigo: "25398", ciudad: "La peña", departamento: "Cundinamarca" },
    { codigo: "25402", ciudad: "La vega", departamento: "Cundinamarca" },
    { codigo: "25407", ciudad: "Lenguazaque", departamento: "Cundinamarca" },
    { codigo: "25426", ciudad: "Machetá", departamento: "Cundinamarca" },
    { codigo: "25430", ciudad: "Madrid", departamento: "Cundinamarca" },
    { codigo: "25436", ciudad: "Manta", departamento: "Cundinamarca" },
    { codigo: "25438", ciudad: "Medina", departamento: "Cundinamarca" },
    { codigo: "25473", ciudad: "Mosquera", departamento: "Cundinamarca" },
    { codigo: "25483", ciudad: "Nariño", departamento: "Cundinamarca" },
    { codigo: "25486", ciudad: "Nemocón", departamento: "Cundinamarca" },
    { codigo: "25488", ciudad: "Nilo", departamento: "Cundinamarca" },
    { codigo: "25489", ciudad: "Nimaima", departamento: "Cundinamarca" },
    { codigo: "25491", ciudad: "Nocaima", departamento: "Cundinamarca" },
    { codigo: "25506", ciudad: "Venecia", departamento: "Cundinamarca" },
    { codigo: "25513", ciudad: "Pacho", departamento: "Cundinamarca" },
    { codigo: "25518", ciudad: "Paime", departamento: "Cundinamarca" },
    { codigo: "25524", ciudad: "Pandi", departamento: "Cundinamarca" },
    { codigo: "25530", ciudad: "Paratebueno", departamento: "Cundinamarca" },
    { codigo: "25535", ciudad: "Pasca", departamento: "Cundinamarca" },
    { codigo: "25572", ciudad: "Puerto salgar", departamento: "Cundinamarca" },
    { codigo: "25580", ciudad: "Pulí", departamento: "Cundinamarca" },
    { codigo: "25592", ciudad: "Quebradanegra", departamento: "Cundinamarca" },
    { codigo: "25594", ciudad: "Quetame", departamento: "Cundinamarca" },
    { codigo: "25596", ciudad: "Quipile", departamento: "Cundinamarca" },
    { codigo: "25599", ciudad: "Apulo", departamento: "Cundinamarca" },
    { codigo: "25612", ciudad: "Ricaurte", departamento: "Cundinamarca" },
    { codigo: "25645", ciudad: "San antonio del tequendama", departamento: "Cundinamarca" },
    { codigo: "25649", ciudad: "San bernardo", departamento: "Cundinamarca" },
    { codigo: "25653", ciudad: "San cayetano", departamento: "Cundinamarca" },
    { codigo: "25658", ciudad: "San francisco", departamento: "Cundinamarca" },
    { codigo: "25662", ciudad: "San juan de rioseco", departamento: "Cundinamarca" },
    { codigo: "25718", ciudad: "Sasaima", departamento: "Cundinamarca" },
    { codigo: "25736", ciudad: "Sesquilé", departamento: "Cundinamarca" },
    { codigo: "25740", ciudad: "Sibaté", departamento: "Cundinamarca" },
    { codigo: "25743", ciudad: "Silvania", departamento: "Cundinamarca" },
    { codigo: "25745", ciudad: "Simijaca", departamento: "Cundinamarca" },
    { codigo: "25754", ciudad: "Soacha", departamento: "Cundinamarca" },
    { codigo: "25758", ciudad: "Sopó", departamento: "Cundinamarca" },
    { codigo: "25769", ciudad: "Subachoque", departamento: "Cundinamarca" },
    { codigo: "25772", ciudad: "Suesca", departamento: "Cundinamarca" },
    { codigo: "25777", ciudad: "Supatá", departamento: "Cundinamarca" },
    { codigo: "25779", ciudad: "Susa", departamento: "Cundinamarca" },
    { codigo: "25781", ciudad: "Sutatausa", departamento: "Cundinamarca" },
    { codigo: "25785", ciudad: "Tabio", departamento: "Cundinamarca" },
    { codigo: "25793", ciudad: "Tausa", departamento: "Cundinamarca" },
    { codigo: "25797", ciudad: "Tena", departamento: "Cundinamarca" },
    { codigo: "25799", ciudad: "Tenjo", departamento: "Cundinamarca" },
    { codigo: "25805", ciudad: "Tibacuy", departamento: "Cundinamarca" },
    { codigo: "25807", ciudad: "Tibirita", departamento: "Cundinamarca" },
    { codigo: "25815", ciudad: "Tocaima", departamento: "Cundinamarca" },
    { codigo: "25817", ciudad: "Tocancipá", departamento: "Cundinamarca" },
    { codigo: "25823", ciudad: "Topaipí", departamento: "Cundinamarca" },
    { codigo: "25839", ciudad: "Ubalá", departamento: "Cundinamarca" },
    { codigo: "25841", ciudad: "Ubaque", departamento: "Cundinamarca" },
    { codigo: "25843", ciudad: "Villa de san diego de ubaté", departamento: "Cundinamarca" },
    { codigo: "25845", ciudad: "Une", departamento: "Cundinamarca" },
    { codigo: "25851", ciudad: "Útica", departamento: "Cundinamarca" },
    { codigo: "25862", ciudad: "Vergara", departamento: "Cundinamarca" },
    { codigo: "25867", ciudad: "Vianí", departamento: "Cundinamarca" },
    { codigo: "25871", ciudad: "Villagómez", departamento: "Cundinamarca" },
    { codigo: "25873", ciudad: "Villapinzón", departamento: "Cundinamarca" },
    { codigo: "25875", ciudad: "Villeta", departamento: "Cundinamarca" },
    { codigo: "25878", ciudad: "Viotá", departamento: "Cundinamarca" },
    { codigo: "25885", ciudad: "Yacopí", departamento: "Cundinamarca" },
    { codigo: "25898", ciudad: "Zipacón", departamento: "Cundinamarca" },
    { codigo: "25899", ciudad: "Zipaquirá", departamento: "Cundinamarca" },
    { codigo: "27001", ciudad: "Quibdó", departamento: "Chocó" },
    { codigo: "27006", ciudad: "Acandí", departamento: "Chocó" },
    { codigo: "27025", ciudad: "Alto baudó", departamento: "Chocó" },
    { codigo: "27050", ciudad: "Atrato", departamento: "Chocó" },
    { codigo: "27073", ciudad: "Bagadó", departamento: "Chocó" },
    { codigo: "27075", ciudad: "Bahía solano", departamento: "Chocó" },
    { codigo: "27077", ciudad: "Bajo baudó", departamento: "Chocó" },
    { codigo: "27099", ciudad: "Bojayá", departamento: "Chocó" },
    { codigo: "27135", ciudad: "El cantón del san pablo", departamento: "Chocó" },
    { codigo: "27150", ciudad: "Carmen del darién", departamento: "Chocó" },
    { codigo: "27160", ciudad: "Cértegui", departamento: "Chocó" },
    { codigo: "27205", ciudad: "Condoto", departamento: "Chocó" },
    { codigo: "27245", ciudad: "El carmen de atrato", departamento: "Chocó" },
    { codigo: "27250", ciudad: "El litoral del san juan", departamento: "Chocó" },
    { codigo: "27361", ciudad: "Istmina", departamento: "Chocó" },
    { codigo: "27372", ciudad: "Juradó", departamento: "Chocó" },
    { codigo: "27413", ciudad: "Lloró", departamento: "Chocó" },
    { codigo: "27425", ciudad: "Medio atrato", departamento: "Chocó" },
    { codigo: "27430", ciudad: "Medio baudó", departamento: "Chocó" },
    { codigo: "27450", ciudad: "Medio san juan", departamento: "Chocó" },
    { codigo: "27491", ciudad: "Nóvita", departamento: "Chocó" },
    { codigo: "27495", ciudad: "Nuquí", departamento: "Chocó" },
    { codigo: "27580", ciudad: "Río iró", departamento: "Chocó" },
    { codigo: "27600", ciudad: "Río quito", departamento: "Chocó" },
    { codigo: "27615", ciudad: "Riosucio", departamento: "Chocó" },
    { codigo: "27660", ciudad: "San josé del palmar", departamento: "Chocó" },
    { codigo: "27745", ciudad: "Sipí", departamento: "Chocó" },
    { codigo: "27787", ciudad: "Tadó", departamento: "Chocó" },
    { codigo: "27800", ciudad: "Unguía", departamento: "Chocó" },
    { codigo: "27810", ciudad: "Unión panamericana", departamento: "Chocó" },
    { codigo: "41001", ciudad: "Neiva", departamento: "Huila" },
    { codigo: "41006", ciudad: "Acevedo", departamento: "Huila" },
    { codigo: "41013", ciudad: "Agrado", departamento: "Huila" },
    { codigo: "41016", ciudad: "Aipe", departamento: "Huila" },
    { codigo: "41020", ciudad: "Algeciras", departamento: "Huila" },
    { codigo: "41026", ciudad: "Altamira", departamento: "Huila" },
    { codigo: "41078", ciudad: "Baraya", departamento: "Huila" },
    { codigo: "41132", ciudad: "Campoalegre", departamento: "Huila" },
    { codigo: "41206", ciudad: "Colombia", departamento: "Huila" },
    { codigo: "41244", ciudad: "Elías", departamento: "Huila" },
    { codigo: "41298", ciudad: "Garzón", departamento: "Huila" },
    { codigo: "41306", ciudad: "Gigante", departamento: "Huila" },
    { codigo: "41319", ciudad: "Guadalupe", departamento: "Huila" },
    { codigo: "41349", ciudad: "Hobo", departamento: "Huila" },
    { codigo: "41357", ciudad: "Íquira", departamento: "Huila" },
    { codigo: "41359", ciudad: "Isnos", departamento: "Huila" },
    { codigo: "41378", ciudad: "La argentina", departamento: "Huila" },
    { codigo: "41396", ciudad: "La plata", departamento: "Huila" },
    { codigo: "41483", ciudad: "Nátaga", departamento: "Huila" },
    { codigo: "41503", ciudad: "Oporapa", departamento: "Huila" },
    { codigo: "41518", ciudad: "Paicol", departamento: "Huila" },
    { codigo: "41524", ciudad: "Palermo", departamento: "Huila" },
    { codigo: "41530", ciudad: "Palestina", departamento: "Huila" },
    { codigo: "41548", ciudad: "Pital", departamento: "Huila" },
    { codigo: "41551", ciudad: "Pitalito", departamento: "Huila" },
    { codigo: "41615", ciudad: "Rivera", departamento: "Huila" },
    { codigo: "41660", ciudad: "Saladoblanco", departamento: "Huila" },
    { codigo: "41668", ciudad: "San agustín", departamento: "Huila" },
    { codigo: "41676", ciudad: "Santa maría", departamento: "Huila" },
    { codigo: "41770", ciudad: "Suaza", departamento: "Huila" },
    { codigo: "41791", ciudad: "Tarqui", departamento: "Huila" },
    { codigo: "41797", ciudad: "Tesalia", departamento: "Huila" },
    { codigo: "41799", ciudad: "Tello", departamento: "Huila" },
    { codigo: "41801", ciudad: "Teruel", departamento: "Huila" },
    { codigo: "41807", ciudad: "Timaná", departamento: "Huila" },
    { codigo: "41872", ciudad: "Villavieja", departamento: "Huila" },
    { codigo: "41885", ciudad: "Yaguará", departamento: "Huila" },
    { codigo: "44001", ciudad: "Riohacha", departamento: "La guajira" },
    { codigo: "44035", ciudad: "Albania", departamento: "La guajira" },
    { codigo: "44078", ciudad: "Barrancas", departamento: "La guajira" },
    { codigo: "44090", ciudad: "Dibulla", departamento: "La guajira" },
    { codigo: "44098", ciudad: "Distracción", departamento: "La guajira" },
    { codigo: "44110", ciudad: "El molino", departamento: "La guajira" },
    { codigo: "44279", ciudad: "Fonseca", departamento: "La guajira" },
    { codigo: "44378", ciudad: "Hatonuevo", departamento: "La guajira" },
    { codigo: "44420", ciudad: "La jagua del pilar", departamento: "La guajira" },
    { codigo: "44430", ciudad: "Maicao", departamento: "La guajira" },
    { codigo: "44560", ciudad: "Manaure", departamento: "La guajira" },
    { codigo: "44650", ciudad: "San juan del cesar", departamento: "La guajira" },
    { codigo: "44847", ciudad: "Uribia", departamento: "La guajira" },
    { codigo: "44855", ciudad: "Urumita", departamento: "La guajira" },
    { codigo: "44874", ciudad: "Villanueva", departamento: "La guajira" },
    { codigo: "47001", ciudad: "Santa marta", departamento: "Magdalena" },
    { codigo: "47030", ciudad: "Algarrobo", departamento: "Magdalena" },
    { codigo: "47053", ciudad: "Aracataca", departamento: "Magdalena" },
    { codigo: "47058", ciudad: "Ariguaní", departamento: "Magdalena" },
    { codigo: "47161", ciudad: "Cerro de san antonio", departamento: "Magdalena" },
    { codigo: "47170", ciudad: "Chivolo", departamento: "Magdalena" },
    { codigo: "47189", ciudad: "Ciénaga", departamento: "Magdalena" },
    { codigo: "47205", ciudad: "Concordia", departamento: "Magdalena" },
    { codigo: "47245", ciudad: "El banco", departamento: "Magdalena" },
    { codigo: "47258", ciudad: "El piñón", departamento: "Magdalena" },
    { codigo: "47268", ciudad: "El retén", departamento: "Magdalena" },
    { codigo: "47288", ciudad: "Fundación", departamento: "Magdalena" },
    { codigo: "47318", ciudad: "Guamal", departamento: "Magdalena" },
    { codigo: "47460", ciudad: "Nueva granada", departamento: "Magdalena" },
    { codigo: "47541", ciudad: "Pedraza", departamento: "Magdalena" },
    { codigo: "47545", ciudad: "Pijiño del carmen", departamento: "Magdalena" },
    { codigo: "47551", ciudad: "Pivijay", departamento: "Magdalena" },
    { codigo: "47555", ciudad: "Plato", departamento: "Magdalena" },
    { codigo: "47570", ciudad: "Puebloviejo", departamento: "Magdalena" },
    { codigo: "47605", ciudad: "Remolino", departamento: "Magdalena" },
    { codigo: "47660", ciudad: "Sabanas de san ángel", departamento: "Magdalena" },
    { codigo: "47675", ciudad: "Salamina", departamento: "Magdalena" },
    { codigo: "47692", ciudad: "San sebastián de buenavista", departamento: "Magdalena" },
    { codigo: "47703", ciudad: "San zenón", departamento: "Magdalena" },
    { codigo: "47707", ciudad: "Santa ana", departamento: "Magdalena" },
    { codigo: "47720", ciudad: "Santa bárbara de pinto", departamento: "Magdalena" },
    { codigo: "47745", ciudad: "Sitionuevo", departamento: "Magdalena" },
    { codigo: "47798", ciudad: "Tenerife", departamento: "Magdalena" },
    { codigo: "47960", ciudad: "Zapayán", departamento: "Magdalena" },
    { codigo: "47980", ciudad: "Zona bananera", departamento: "Magdalena" },
    { codigo: "50001", ciudad: "Villavicencio", departamento: "Meta" },
    { codigo: "50006", ciudad: "Acacías", departamento: "Meta" },
    { codigo: "50110", ciudad: "Barranca de upía", departamento: "Meta" },
    { codigo: "50124", ciudad: "Cabuyaro", departamento: "Meta" },
    { codigo: "50150", ciudad: "Castilla la nueva", departamento: "Meta" },
    { codigo: "50223", ciudad: "Cubarral", departamento: "Meta" },
    { codigo: "50226", ciudad: "Cumaral", departamento: "Meta" },
    { codigo: "50245", ciudad: "El calvario", departamento: "Meta" },
    { codigo: "50251", ciudad: "El castillo", departamento: "Meta" },
    { codigo: "50270", ciudad: "El dorado", departamento: "Meta" },
    { codigo: "50287", ciudad: "Fuente de oro", departamento: "Meta" },
    { codigo: "50313", ciudad: "Granada", departamento: "Meta" },
    { codigo: "50318", ciudad: "Guamal", departamento: "Meta" },
    { codigo: "50325", ciudad: "Mapiripán", departamento: "Meta" },
    { codigo: "50330", ciudad: "Mesetas", departamento: "Meta" },
    { codigo: "50350", ciudad: "La macarena", departamento: "Meta" },
    { codigo: "50370", ciudad: "Uribe", departamento: "Meta" },
    { codigo: "50400", ciudad: "Lejanías", departamento: "Meta" },
    { codigo: "50450", ciudad: "Puerto concordia", departamento: "Meta" },
    { codigo: "50568", ciudad: "Puerto gaitán", departamento: "Meta" },
    { codigo: "50573", ciudad: "Puerto lópez", departamento: "Meta" },
    { codigo: "50577", ciudad: "Puerto lleras", departamento: "Meta" },
    { codigo: "50590", ciudad: "Puerto rico", departamento: "Meta" },
    { codigo: "50606", ciudad: "Restrepo", departamento: "Meta" },
    { codigo: "50680", ciudad: "San carlos de guaroa", departamento: "Meta" },
    { codigo: "50683", ciudad: "San juan de arama", departamento: "Meta" },
    { codigo: "50686", ciudad: "San juanito", departamento: "Meta" },
    { codigo: "50689", ciudad: "San martín", departamento: "Meta" },
    { codigo: "50711", ciudad: "Vistahermosa", departamento: "Meta" },
    { codigo: "52001", ciudad: "Pasto", departamento: "Nariño" },
    { codigo: "52019", ciudad: "Albán", departamento: "Nariño" },
    { codigo: "52022", ciudad: "Aldana", departamento: "Nariño" },
    { codigo: "52036", ciudad: "Ancuya", departamento: "Nariño" },
    { codigo: "52051", ciudad: "Arboleda", departamento: "Nariño" },
    { codigo: "52079", ciudad: "Barbacoas", departamento: "Nariño" },
    { codigo: "52083", ciudad: "Belén", departamento: "Nariño" },
    { codigo: "52110", ciudad: "Buesaco", departamento: "Nariño" },
    { codigo: "52203", ciudad: "Colón", departamento: "Nariño" },
    { codigo: "52207", ciudad: "Consacá", departamento: "Nariño" },
    { codigo: "52210", ciudad: "Contadero", departamento: "Nariño" },
    { codigo: "52215", ciudad: "Córdoba", departamento: "Nariño" },
    { codigo: "52224", ciudad: "Cuaspud carlosama", departamento: "Nariño" },
    { codigo: "52227", ciudad: "Cumbal", departamento: "Nariño" },
    { codigo: "52233", ciudad: "Cumbitara", departamento: "Nariño" },
    { codigo: "52240", ciudad: "Chachagüí", departamento: "Nariño" },
    { codigo: "52250", ciudad: "El charco", departamento: "Nariño" },
    { codigo: "52254", ciudad: "El peñol", departamento: "Nariño" },
    { codigo: "52256", ciudad: "El rosario", departamento: "Nariño" },
    { codigo: "52258", ciudad: "El tablón de gómez", departamento: "Nariño" },
    { codigo: "52260", ciudad: "El tambo", departamento: "Nariño" },
    { codigo: "52287", ciudad: "Funes", departamento: "Nariño" },
    { codigo: "52317", ciudad: "Guachucal", departamento: "Nariño" },
    { codigo: "52320", ciudad: "Guaitarilla", departamento: "Nariño" },
    { codigo: "52323", ciudad: "Gualmatán", departamento: "Nariño" },
    { codigo: "52352", ciudad: "Iles", departamento: "Nariño" },
    { codigo: "52354", ciudad: "Imués", departamento: "Nariño" },
    { codigo: "52356", ciudad: "Ipiales", departamento: "Nariño" },
    { codigo: "52378", ciudad: "La cruz", departamento: "Nariño" },
    { codigo: "52381", ciudad: "La florida", departamento: "Nariño" },
    { codigo: "52385", ciudad: "La llanada", departamento: "Nariño" },
    { codigo: "52390", ciudad: "La tola", departamento: "Nariño" },
    { codigo: "52399", ciudad: "La unión", departamento: "Nariño" },
    { codigo: "52405", ciudad: "Leiva", departamento: "Nariño" },
    { codigo: "52411", ciudad: "Linares", departamento: "Nariño" },
    { codigo: "52418", ciudad: "Los andes", departamento: "Nariño" },
    { codigo: "52427", ciudad: "Magüí", departamento: "Nariño" },
    { codigo: "52435", ciudad: "Mallama", departamento: "Nariño" },
    { codigo: "52473", ciudad: "Mosquera", departamento: "Nariño" },
    { codigo: "52480", ciudad: "Nariño", departamento: "Nariño" },
    { codigo: "52490", ciudad: "Olaya herrera", departamento: "Nariño" },
    { codigo: "52506", ciudad: "Ospina", departamento: "Nariño" },
    { codigo: "52520", ciudad: "Francisco pizarro", departamento: "Nariño" },
    { codigo: "52540", ciudad: "Policarpa", departamento: "Nariño" },
    { codigo: "52560", ciudad: "Potosí", departamento: "Nariño" },
    { codigo: "52565", ciudad: "Providencia", departamento: "Nariño" },
    { codigo: "52573", ciudad: "Puerres", departamento: "Nariño" },
    { codigo: "52585", ciudad: "Pupiales", departamento: "Nariño" },
    { codigo: "52612", ciudad: "Ricaurte", departamento: "Nariño" },
    { codigo: "52621", ciudad: "Roberto payán", departamento: "Nariño" },
    { codigo: "52678", ciudad: "Samaniego", departamento: "Nariño" },
    { codigo: "52683", ciudad: "Sandoná", departamento: "Nariño" },
    { codigo: "52685", ciudad: "San bernardo", departamento: "Nariño" },
    { codigo: "52687", ciudad: "San lorenzo", departamento: "Nariño" },
    { codigo: "52693", ciudad: "San pablo", departamento: "Nariño" },
    { codigo: "52694", ciudad: "San pedro de cartago", departamento: "Nariño" },
    { codigo: "52696", ciudad: "Santa bárbara", departamento: "Nariño" },
    { codigo: "52699", ciudad: "Santacruz", departamento: "Nariño" },
    { codigo: "52720", ciudad: "Sapuyes", departamento: "Nariño" },
    { codigo: "52786", ciudad: "Taminango", departamento: "Nariño" },
    { codigo: "52788", ciudad: "Tangua", departamento: "Nariño" },
    { codigo: "52835", ciudad: "San andrés de tumaco", departamento: "Nariño" },
    { codigo: "52838", ciudad: "Túquerres", departamento: "Nariño" },
    { codigo: "52885", ciudad: "Yacuanquer", departamento: "Nariño" },
    { codigo: "54001", ciudad: "San josé de cúcuta", departamento: "Norte de santander" },
    { codigo: "54003", ciudad: "Ábrego", departamento: "Norte de santander" },
    { codigo: "54051", ciudad: "Arboledas", departamento: "Norte de santander" },
    { codigo: "54099", ciudad: "Bochalema", departamento: "Norte de santander" },
    { codigo: "54109", ciudad: "Bucarasica", departamento: "Norte de santander" },
    { codigo: "54125", ciudad: "Cácota", departamento: "Norte de santander" },
    { codigo: "54128", ciudad: "Cáchira", departamento: "Norte de santander" },
    { codigo: "54172", ciudad: "Chinácota", departamento: "Norte de santander" },
    { codigo: "54174", ciudad: "Chitagá", departamento: "Norte de santander" },
    { codigo: "54206", ciudad: "Convención", departamento: "Norte de santander" },
    { codigo: "54223", ciudad: "Cucutilla", departamento: "Norte de santander" },
    { codigo: "54239", ciudad: "Durania", departamento: "Norte de santander" },
    { codigo: "54245", ciudad: "El carmen", departamento: "Norte de santander" },
    { codigo: "54250", ciudad: "El tarra", departamento: "Norte de santander" },
    { codigo: "54261", ciudad: "El zulia", departamento: "Norte de santander" },
    { codigo: "54313", ciudad: "Gramalote", departamento: "Norte de santander" },
    { codigo: "54344", ciudad: "Hacarí", departamento: "Norte de santander" },
    { codigo: "54347", ciudad: "Herrán", departamento: "Norte de santander" },
    { codigo: "54377", ciudad: "Labateca", departamento: "Norte de santander" },
    { codigo: "54385", ciudad: "La esperanza", departamento: "Norte de santander" },
    { codigo: "54398", ciudad: "La playa", departamento: "Norte de santander" },
    { codigo: "54405", ciudad: "Los patios", departamento: "Norte de santander" },
    { codigo: "54418", ciudad: "Lourdes", departamento: "Norte de santander" },
    { codigo: "54480", ciudad: "Mutiscua", departamento: "Norte de santander" },
    { codigo: "54498", ciudad: "Ocaña", departamento: "Norte de santander" },
    { codigo: "54518", ciudad: "Pamplona", departamento: "Norte de santander" },
    { codigo: "54520", ciudad: "Pamplonita", departamento: "Norte de santander" },
    { codigo: "54553", ciudad: "Puerto santander", departamento: "Norte de santander" },
    { codigo: "54599", ciudad: "Ragonvalia", departamento: "Norte de santander" },
    { codigo: "54660", ciudad: "Salazar", departamento: "Norte de santander" },
    { codigo: "54670", ciudad: "San calixto", departamento: "Norte de santander" },
    { codigo: "54673", ciudad: "San cayetano", departamento: "Norte de santander" },
    { codigo: "54680", ciudad: "Santiago", departamento: "Norte de santander" },
    { codigo: "54720", ciudad: "Sardinata", departamento: "Norte de santander" },
    { codigo: "54743", ciudad: "Silos", departamento: "Norte de santander" },
    { codigo: "54800", ciudad: "Teorama", departamento: "Norte de santander" },
    { codigo: "54810", ciudad: "Tibú", departamento: "Norte de santander" },
    { codigo: "54820", ciudad: "Toledo", departamento: "Norte de santander" },
    { codigo: "54871", ciudad: "Villa caro", departamento: "Norte de santander" },
    { codigo: "54874", ciudad: "Villa del rosario", departamento: "Norte de santander" },
    { codigo: "63001", ciudad: "Armenia", departamento: "Quindío" },
    { codigo: "63111", ciudad: "Buenavista", departamento: "Quindío" },
    { codigo: "63130", ciudad: "Calarcá", departamento: "Quindío" },
    { codigo: "63190", ciudad: "Circasia", departamento: "Quindío" },
    { codigo: "63212", ciudad: "Córdoba", departamento: "Quindío" },
    { codigo: "63272", ciudad: "Filandia", departamento: "Quindío" },
    { codigo: "63302", ciudad: "Génova", departamento: "Quindío" },
    { codigo: "63401", ciudad: "La tebaida", departamento: "Quindío" },
    { codigo: "63470", ciudad: "Montenegro", departamento: "Quindío" },
    { codigo: "63548", ciudad: "Pijao", departamento: "Quindío" },
    { codigo: "63594", ciudad: "Quimbaya", departamento: "Quindío" },
    { codigo: "63690", ciudad: "Salento", departamento: "Quindío" },
    { codigo: "66001", ciudad: "Pereira", departamento: "Risaralda" },
    { codigo: "66045", ciudad: "Apía", departamento: "Risaralda" },
    { codigo: "66075", ciudad: "Balboa", departamento: "Risaralda" },
    { codigo: "66088", ciudad: "Belén de umbría", departamento: "Risaralda" },
    { codigo: "66170", ciudad: "Dosquebradas", departamento: "Risaralda" },
    { codigo: "66318", ciudad: "Guática", departamento: "Risaralda" },
    { codigo: "66383", ciudad: "La celia", departamento: "Risaralda" },
    { codigo: "66400", ciudad: "La virginia", departamento: "Risaralda" },
    { codigo: "66440", ciudad: "Marsella", departamento: "Risaralda" },
    { codigo: "66456", ciudad: "Mistrató", departamento: "Risaralda" },
    { codigo: "66572", ciudad: "Pueblo rico", departamento: "Risaralda" },
    { codigo: "66594", ciudad: "Quinchía", departamento: "Risaralda" },
    { codigo: "66682", ciudad: "Santa rosa de cabal", departamento: "Risaralda" },
    { codigo: "66687", ciudad: "Santuario", departamento: "Risaralda" },
    { codigo: "68001", ciudad: "Bucaramanga", departamento: "Santander" },
    { codigo: "68013", ciudad: "Aguada", departamento: "Santander" },
    { codigo: "68020", ciudad: "Albania", departamento: "Santander" },
    { codigo: "68051", ciudad: "Aratoca", departamento: "Santander" },
    { codigo: "68077", ciudad: "Barbosa", departamento: "Santander" },
    { codigo: "68079", ciudad: "Barichara", departamento: "Santander" },
    { codigo: "68081", ciudad: "Barrancabermeja", departamento: "Santander" },
    { codigo: "68092", ciudad: "Betulia", departamento: "Santander" },
    { codigo: "68101", ciudad: "Bolívar", departamento: "Santander" },
    { codigo: "68121", ciudad: "Cabrera", departamento: "Santander" },
    { codigo: "68132", ciudad: "California", departamento: "Santander" },
    { codigo: "68147", ciudad: "Capitanejo", departamento: "Santander" },
    { codigo: "68152", ciudad: "Carcasí", departamento: "Santander" },
    { codigo: "68160", ciudad: "Cepitá", departamento: "Santander" },
    { codigo: "68162", ciudad: "Cerrito", departamento: "Santander" },
    { codigo: "68167", ciudad: "Charalá", departamento: "Santander" },
    { codigo: "68169", ciudad: "Charta", departamento: "Santander" },
    { codigo: "68176", ciudad: "Chima", departamento: "Santander" },
    { codigo: "68179", ciudad: "Chipatá", departamento: "Santander" },
    { codigo: "68190", ciudad: "Cimitarra", departamento: "Santander" },
    { codigo: "68207", ciudad: "Concepción", departamento: "Santander" },
    { codigo: "68209", ciudad: "Confines", departamento: "Santander" },
    { codigo: "68211", ciudad: "Contratación", departamento: "Santander" },
    { codigo: "68217", ciudad: "Coromoro", departamento: "Santander" },
    { codigo: "68229", ciudad: "Curití", departamento: "Santander" },
    { codigo: "68235", ciudad: "El carmen de chucurí", departamento: "Santander" },
    { codigo: "68245", ciudad: "El guacamayo", departamento: "Santander" },
    { codigo: "68250", ciudad: "El peñón", departamento: "Santander" },
    { codigo: "68255", ciudad: "El playón", departamento: "Santander" },
    { codigo: "68264", ciudad: "Encino", departamento: "Santander" },
    { codigo: "68266", ciudad: "Enciso", departamento: "Santander" },
    { codigo: "68271", ciudad: "Florián", departamento: "Santander" },
    { codigo: "68276", ciudad: "Floridablanca", departamento: "Santander" },
    { codigo: "68296", ciudad: "Galán", departamento: "Santander" },
    { codigo: "68298", ciudad: "Gámbita", departamento: "Santander" },
    { codigo: "68307", ciudad: "Girón", departamento: "Santander" },
    { codigo: "68318", ciudad: "Guaca", departamento: "Santander" },
    { codigo: "68320", ciudad: "Guadalupe", departamento: "Santander" },
    { codigo: "68322", ciudad: "Guapotá", departamento: "Santander" },
    { codigo: "68324", ciudad: "Guavatá", departamento: "Santander" },
    { codigo: "68327", ciudad: "Güepsa", departamento: "Santander" },
    { codigo: "68344", ciudad: "Hato", departamento: "Santander" },
    { codigo: "68368", ciudad: "Jesús maría", departamento: "Santander" },
    { codigo: "68370", ciudad: "Jordán", departamento: "Santander" },
    { codigo: "68377", ciudad: "La belleza", departamento: "Santander" },
    { codigo: "68385", ciudad: "Landázuri", departamento: "Santander" },
    { codigo: "68397", ciudad: "La paz", departamento: "Santander" },
    { codigo: "68406", ciudad: "Lebrija", departamento: "Santander" },
    { codigo: "68418", ciudad: "Los santos", departamento: "Santander" },
    { codigo: "68425", ciudad: "Macaravita", departamento: "Santander" },
    { codigo: "68432", ciudad: "Málaga", departamento: "Santander" },
    { codigo: "68444", ciudad: "Matanza", departamento: "Santander" },
    { codigo: "68464", ciudad: "Mogotes", departamento: "Santander" },
    { codigo: "68468", ciudad: "Molagavita", departamento: "Santander" },
    { codigo: "68498", ciudad: "Ocamonte", departamento: "Santander" },
    { codigo: "68500", ciudad: "Oiba", departamento: "Santander" },
    { codigo: "68502", ciudad: "Onzaga", departamento: "Santander" },
    { codigo: "68522", ciudad: "Palmar", departamento: "Santander" },
    { codigo: "68524", ciudad: "Palmas del socorro", departamento: "Santander" },
    { codigo: "68533", ciudad: "Páramo", departamento: "Santander" },
    { codigo: "68547", ciudad: "Piedecuesta", departamento: "Santander" },
    { codigo: "68549", ciudad: "Pinchote", departamento: "Santander" },
    { codigo: "68572", ciudad: "Puente nacional", departamento: "Santander" },
    { codigo: "68573", ciudad: "Puerto parra", departamento: "Santander" },
    { codigo: "68575", ciudad: "Puerto wilches", departamento: "Santander" },
    { codigo: "68615", ciudad: "Rionegro", departamento: "Santander" },
    { codigo: "68655", ciudad: "Sabana de torres", departamento: "Santander" },
    { codigo: "68669", ciudad: "San andrés", departamento: "Santander" },
    { codigo: "68673", ciudad: "San benito", departamento: "Santander" },
    { codigo: "68679", ciudad: "San gil", departamento: "Santander" },
    { codigo: "68682", ciudad: "San joaquín", departamento: "Santander" },
    { codigo: "68684", ciudad: "San josé de miranda", departamento: "Santander" },
    { codigo: "68686", ciudad: "San miguel", departamento: "Santander" },
    { codigo: "68689", ciudad: "San vicente de chucurí", departamento: "Santander" },
    { codigo: "68705", ciudad: "Santa bárbara", departamento: "Santander" },
    { codigo: "68720", ciudad: "Santa helena del opón", departamento: "Santander" },
    { codigo: "68745", ciudad: "Simacota", departamento: "Santander" },
    { codigo: "68755", ciudad: "Socorro", departamento: "Santander" },
    { codigo: "68770", ciudad: "Suaita", departamento: "Santander" },
    { codigo: "68773", ciudad: "Sucre", departamento: "Santander" },
    { codigo: "68780", ciudad: "Suratá", departamento: "Santander" },
    { codigo: "68820", ciudad: "Tona", departamento: "Santander" },
    { codigo: "68855", ciudad: "Valle de san josé", departamento: "Santander" },
    { codigo: "68861", ciudad: "Vélez", departamento: "Santander" },
    { codigo: "68867", ciudad: "Vetas", departamento: "Santander" },
    { codigo: "68872", ciudad: "Villanueva", departamento: "Santander" },
    { codigo: "68895", ciudad: "Zapatoca", departamento: "Santander" },
    { codigo: "70001", ciudad: "Sincelejo", departamento: "Sucre" },
    { codigo: "70110", ciudad: "Buenavista", departamento: "Sucre" },
    { codigo: "70124", ciudad: "Caimito", departamento: "Sucre" },
    { codigo: "70204", ciudad: "Colosó", departamento: "Sucre" },
    { codigo: "70215", ciudad: "Corozal", departamento: "Sucre" },
    { codigo: "70221", ciudad: "Coveñas", departamento: "Sucre" },
    { codigo: "70230", ciudad: "Chalán", departamento: "Sucre" },
    { codigo: "70233", ciudad: "El roble", departamento: "Sucre" },
    { codigo: "70235", ciudad: "Galeras", departamento: "Sucre" },
    { codigo: "70265", ciudad: "Guaranda", departamento: "Sucre" },
    { codigo: "70400", ciudad: "La unión", departamento: "Sucre" },
    { codigo: "70418", ciudad: "Los palmitos", departamento: "Sucre" },
    { codigo: "70429", ciudad: "Majagual", departamento: "Sucre" },
    { codigo: "70473", ciudad: "Morroa", departamento: "Sucre" },
    { codigo: "70508", ciudad: "Ovejas", departamento: "Sucre" },
    { codigo: "70523", ciudad: "Palmito", departamento: "Sucre" },
    { codigo: "70670", ciudad: "Sampués", departamento: "Sucre" },
    { codigo: "70678", ciudad: "San benito abad", departamento: "Sucre" },
    { codigo: "70702", ciudad: "San juan de betulia", departamento: "Sucre" },
    { codigo: "70708", ciudad: "San marcos", departamento: "Sucre" },
    { codigo: "70713", ciudad: "San onofre", departamento: "Sucre" },
    { codigo: "70717", ciudad: "San pedro", departamento: "Sucre" },
    { codigo: "70742", ciudad: "San luis de sincé", departamento: "Sucre" },
    { codigo: "70771", ciudad: "Sucre", departamento: "Sucre" },
    { codigo: "70820", ciudad: "Santiago de tolú", departamento: "Sucre" },
    { codigo: "70823", ciudad: "San josé de toluviejo", departamento: "Sucre" },
    { codigo: "73001", ciudad: "Ibagué", departamento: "Tolima" },
    { codigo: "73024", ciudad: "Alpujarra", departamento: "Tolima" },
    { codigo: "73026", ciudad: "Alvarado", departamento: "Tolima" },
    { codigo: "73030", ciudad: "Ambalema", departamento: "Tolima" },
    { codigo: "73043", ciudad: "Anzoátegui", departamento: "Tolima" },
    { codigo: "73055", ciudad: "Armero", departamento: "Tolima" },
    { codigo: "73067", ciudad: "Ataco", departamento: "Tolima" },
    { codigo: "73124", ciudad: "Cajamarca", departamento: "Tolima" },
    { codigo: "73148", ciudad: "Carmen de apicalá", departamento: "Tolima" },
    { codigo: "73152", ciudad: "Casabianca", departamento: "Tolima" },
    { codigo: "73168", ciudad: "Chaparral", departamento: "Tolima" },
    { codigo: "73200", ciudad: "Coello", departamento: "Tolima" },
    { codigo: "73217", ciudad: "Coyaima", departamento: "Tolima" },
    { codigo: "73226", ciudad: "Cunday", departamento: "Tolima" },
    { codigo: "73236", ciudad: "Dolores", departamento: "Tolima" },
    { codigo: "73268", ciudad: "Espinal", departamento: "Tolima" },
    { codigo: "73270", ciudad: "Falan", departamento: "Tolima" },
    { codigo: "73275", ciudad: "Flandes", departamento: "Tolima" },
    { codigo: "73283", ciudad: "Fresno", departamento: "Tolima" },
    { codigo: "73319", ciudad: "Guamo", departamento: "Tolima" },
    { codigo: "73347", ciudad: "Herveo", departamento: "Tolima" },
    { codigo: "73349", ciudad: "Honda", departamento: "Tolima" },
    { codigo: "73352", ciudad: "Icononzo", departamento: "Tolima" },
    { codigo: "73408", ciudad: "Lérida", departamento: "Tolima" },
    { codigo: "73411", ciudad: "Líbano", departamento: "Tolima" },
    { codigo: "73443", ciudad: "San sebastián de mariquita", departamento: "Tolima" },
    { codigo: "73449", ciudad: "Melgar", departamento: "Tolima" },
    { codigo: "73461", ciudad: "Murillo", departamento: "Tolima" },
    { codigo: "73483", ciudad: "Natagaima", departamento: "Tolima" },
    { codigo: "73504", ciudad: "Ortega", departamento: "Tolima" },
    { codigo: "73520", ciudad: "Palocabildo", departamento: "Tolima" },
    { codigo: "73547", ciudad: "Piedras", departamento: "Tolima" },
    { codigo: "73555", ciudad: "Planadas", departamento: "Tolima" },
    { codigo: "73563", ciudad: "Prado", departamento: "Tolima" },
    { codigo: "73585", ciudad: "Purificación", departamento: "Tolima" },
    { codigo: "73616", ciudad: "Rioblanco", departamento: "Tolima" },
    { codigo: "73622", ciudad: "Roncesvalles", departamento: "Tolima" },
    { codigo: "73624", ciudad: "Rovira", departamento: "Tolima" },
    { codigo: "73671", ciudad: "Saldaña", departamento: "Tolima" },
    { codigo: "73675", ciudad: "San antonio", departamento: "Tolima" },
    { codigo: "73678", ciudad: "San luis", departamento: "Tolima" },
    { codigo: "73686", ciudad: "Santa isabel", departamento: "Tolima" },
    { codigo: "73770", ciudad: "Suárez", departamento: "Tolima" },
    { codigo: "73854", ciudad: "Valle de san juan", departamento: "Tolima" },
    { codigo: "73861", ciudad: "Venadillo", departamento: "Tolima" },
    { codigo: "73870", ciudad: "Villahermosa", departamento: "Tolima" },
    { codigo: "73873", ciudad: "Villarrica", departamento: "Tolima" },
    { codigo: "76001", ciudad: "Cali", departamento: "Valle del cauca" },
    { codigo: "76020", ciudad: "Alcalá", departamento: "Valle del cauca" },
    { codigo: "76036", ciudad: "Andalucía", departamento: "Valle del cauca" },
    { codigo: "76041", ciudad: "Ansermanuevo", departamento: "Valle del cauca" },
    { codigo: "76054", ciudad: "Argelia", departamento: "Valle del cauca" },
    { codigo: "76100", ciudad: "Bolívar", departamento: "Valle del cauca" },
    { codigo: "76109", ciudad: "Buenaventura", departamento: "Valle del cauca" },
    { codigo: "76111", ciudad: "Guadalajara de buga", departamento: "Valle del cauca" },
    { codigo: "76113", ciudad: "Bugalagrande", departamento: "Valle del cauca" },
    { codigo: "76122", ciudad: "Caicedonia", departamento: "Valle del cauca" },
    { codigo: "76126", ciudad: "Calima", departamento: "Valle del cauca" },
    { codigo: "76130", ciudad: "Candelaria", departamento: "Valle del cauca" },
    { codigo: "76147", ciudad: "Cartago", departamento: "Valle del cauca" },
    { codigo: "76233", ciudad: "Dagua", departamento: "Valle del cauca" },
    { codigo: "76243", ciudad: "El águila", departamento: "Valle del cauca" },
    { codigo: "76246", ciudad: "El cairo", departamento: "Valle del cauca" },
    { codigo: "76248", ciudad: "El cerrito", departamento: "Valle del cauca" },
    { codigo: "76250", ciudad: "El dovio", departamento: "Valle del cauca" },
    { codigo: "76275", ciudad: "Florida", departamento: "Valle del cauca" },
    { codigo: "76306", ciudad: "Ginebra", departamento: "Valle del cauca" },
    { codigo: "76318", ciudad: "Guacarí", departamento: "Valle del cauca" },
    { codigo: "76364", ciudad: "Jamundí", departamento: "Valle del cauca" },
    { codigo: "76377", ciudad: "La cumbre", departamento: "Valle del cauca" },
    { codigo: "76400", ciudad: "La unión", departamento: "Valle del cauca" },
    { codigo: "76403", ciudad: "La victoria", departamento: "Valle del cauca" },
    { codigo: "76497", ciudad: "Obando", departamento: "Valle del cauca" },
    { codigo: "76520", ciudad: "Palmira", departamento: "Valle del cauca" },
    { codigo: "76563", ciudad: "Pradera", departamento: "Valle del cauca" },
    { codigo: "76606", ciudad: "Restrepo", departamento: "Valle del cauca" },
    { codigo: "76616", ciudad: "Riofrío", departamento: "Valle del cauca" },
    { codigo: "76622", ciudad: "Roldanillo", departamento: "Valle del cauca" },
    { codigo: "76670", ciudad: "San pedro", departamento: "Valle del cauca" },
    { codigo: "76736", ciudad: "Sevilla", departamento: "Valle del cauca" },
    { codigo: "76823", ciudad: "Toro", departamento: "Valle del cauca" },
    { codigo: "76828", ciudad: "Trujillo", departamento: "Valle del cauca" },
    { codigo: "76834", ciudad: "Tuluá", departamento: "Valle del cauca" },
    { codigo: "76845", ciudad: "Ulloa", departamento: "Valle del cauca" },
    { codigo: "76863", ciudad: "Versalles", departamento: "Valle del cauca" },
    { codigo: "76869", ciudad: "Vijes", departamento: "Valle del cauca" },
    { codigo: "76890", ciudad: "Yotoco", departamento: "Valle del cauca" },
    { codigo: "76892", ciudad: "Yumbo", departamento: "Valle del cauca" },
    { codigo: "76895", ciudad: "Zarzal", departamento: "Valle del cauca" },
    { codigo: "81001", ciudad: "Arauca", departamento: "Arauca" },
    { codigo: "81065", ciudad: "Arauquita", departamento: "Arauca" },
    { codigo: "81220", ciudad: "Cravo norte", departamento: "Arauca" },
    { codigo: "81300", ciudad: "Fortul", departamento: "Arauca" },
    { codigo: "81591", ciudad: "Puerto rondón", departamento: "Arauca" },
    { codigo: "81736", ciudad: "Saravena", departamento: "Arauca" },
    { codigo: "81794", ciudad: "Tame", departamento: "Arauca" },
    { codigo: "85001", ciudad: "Yopal", departamento: "Casanare" },
    { codigo: "85010", ciudad: "Aguazul", departamento: "Casanare" },
    { codigo: "85015", ciudad: "Chámeza", departamento: "Casanare" },
    { codigo: "85125", ciudad: "Hato corozal", departamento: "Casanare" },
    { codigo: "85136", ciudad: "La salina", departamento: "Casanare" },
    { codigo: "85139", ciudad: "Maní", departamento: "Casanare" },
    { codigo: "85162", ciudad: "Monterrey", departamento: "Casanare" },
    { codigo: "85225", ciudad: "Nunchía", departamento: "Casanare" },
    { codigo: "85230", ciudad: "Orocué", departamento: "Casanare" },
    { codigo: "85250", ciudad: "Paz de ariporo", departamento: "Casanare" },
    { codigo: "85263", ciudad: "Pore", departamento: "Casanare" },
    { codigo: "85279", ciudad: "Recetor", departamento: "Casanare" },
    { codigo: "85300", ciudad: "Sabanalarga", departamento: "Casanare" },
    { codigo: "85315", ciudad: "Sácama", departamento: "Casanare" },
    { codigo: "85325", ciudad: "San luis de palenque", departamento: "Casanare" },
    { codigo: "85400", ciudad: "Támara", departamento: "Casanare" },
    { codigo: "85410", ciudad: "Tauramena", departamento: "Casanare" },
    { codigo: "85430", ciudad: "Trinidad", departamento: "Casanare" },
    { codigo: "85440", ciudad: "Villanueva", departamento: "Casanare" },
    { codigo: "86001", ciudad: "Mocoa", departamento: "Putumayo" },
    { codigo: "86219", ciudad: "Colón", departamento: "Putumayo" },
    { codigo: "86320", ciudad: "Orito", departamento: "Putumayo" },
    { codigo: "86568", ciudad: "Puerto asís", departamento: "Putumayo" },
    { codigo: "86569", ciudad: "Puerto caicedo", departamento: "Putumayo" },
    { codigo: "86571", ciudad: "Puerto guzmán", departamento: "Putumayo" },
    { codigo: "86573", ciudad: "Puerto leguízamo", departamento: "Putumayo" },
    { codigo: "86749", ciudad: "Sibundoy", departamento: "Putumayo" },
    { codigo: "86755", ciudad: "San francisco", departamento: "Putumayo" },
    { codigo: "86757", ciudad: "San miguel", departamento: "Putumayo" },
    { codigo: "86760", ciudad: "Santiago", departamento: "Putumayo" },
    { codigo: "86865", ciudad: "Valle del guamuez", departamento: "Putumayo" },
    { codigo: "86885", ciudad: "Villagarzón", departamento: "Putumayo" },
    { codigo: "88001", ciudad: "San andrés", departamento: "Archipiélago de san andrés, providencia y santa catalina" },
    { codigo: "88564", ciudad: "Providencia", departamento: "Archipiélago de san andrés, providencia y santa catalina" },
    { codigo: "91001", ciudad: "Leticia", departamento: "Amazonas" },
    { codigo: "91263", ciudad: "El encanto", departamento: "Amazonas" },
    { codigo: "91405", ciudad: "La chorrera", departamento: "Amazonas" },
    { codigo: "91407", ciudad: "La pedrera", departamento: "Amazonas" },
    { codigo: "91430", ciudad: "La victoria", departamento: "Amazonas" },
    { codigo: "91460", ciudad: "Mirití - paraná", departamento: "Amazonas" },
    { codigo: "91530", ciudad: "Puerto alegría", departamento: "Amazonas" },
    { codigo: "91536", ciudad: "Puerto arica", departamento: "Amazonas" },
    { codigo: "91540", ciudad: "Puerto nariño", departamento: "Amazonas" },
    { codigo: "91669", ciudad: "Puerto santander", departamento: "Amazonas" },
    { codigo: "91798", ciudad: "Tarapacá", departamento: "Amazonas" },
    { codigo: "94001", ciudad: "Inírida", departamento: "Guainía" },
    { codigo: "94343", ciudad: "Barrancominas", departamento: "Guainía" },
    { codigo: "94883", ciudad: "San felipe", departamento: "Guainía" },
    { codigo: "94884", ciudad: "Puerto colombia", departamento: "Guainía" },
    { codigo: "94885", ciudad: "La guadalupe", departamento: "Guainía" },
    { codigo: "94886", ciudad: "Cacahual", departamento: "Guainía" },
    { codigo: "94887", ciudad: "Pana pana", departamento: "Guainía" },
    { codigo: "94888", ciudad: "Morichal", departamento: "Guainía" },
    { codigo: "95001", ciudad: "San josé del guaviare", departamento: "Guaviare" },
    { codigo: "95015", ciudad: "Calamar", departamento: "Guaviare" },
    { codigo: "95025", ciudad: "El retorno", departamento: "Guaviare" },
    { codigo: "95200", ciudad: "Miraflores", departamento: "Guaviare" },
    { codigo: "97001", ciudad: "Mitú", departamento: "Vaupés" },
    { codigo: "97161", ciudad: "Carurú", departamento: "Vaupés" },
    { codigo: "97511", ciudad: "Pacoa", departamento: "Vaupés" },
    { codigo: "97666", ciudad: "Taraira", departamento: "Vaupés" },
    { codigo: "97777", ciudad: "Papunahua", departamento: "Vaupés" },
    { codigo: "97889", ciudad: "Yavaraté", departamento: "Vaupés" },
    { codigo: "99001", ciudad: "Puerto carreño", departamento: "Vichada" },
    { codigo: "99524", ciudad: "La primavera", departamento: "Vichada" },
    { codigo: "99624", ciudad: "Santa rosalía", departamento: "Vichada" },
    { codigo: "99773", ciudad: "Cumaribo", departamento: "Vichada" }
]

export default ciudades;