import * as React from "react";
import { useBarcode } from "next-barcode";

/* eslint react-hooks/exhaustive-deps: 0 */

const BarCode = ({ code }) => {
    const { inputRef } = useBarcode({
        value: code,
        options: {
            height: 50,
            format: 'CODE128',
            fontSize: 17,
            background: '#ffffff00',
        }
    });

    return (
        <div className="_bar_code">
            <svg ref={inputRef} />
        </div>
    );
}

export default BarCode;