// import * as React from 'react';

/** Niveles de los usuarios 
 * 
 * 1 Super Administrador
 * 2 Administrador
 * 3 Contador o asistente contable
 * 4 Almacenista
 * 5 vendedor o asesores
 * 6 Transportadora
 * 7 Domiciliarios
 * 8 Dropshipping´s
 * 9
 * 10 usuarios finales
 * 
 */
const getPerfil = (_rol, _permisos =[]) => {
    let _perfil = { plural: "usuarios", singular: "usuario" }

    switch (parseInt(_rol)) {
        case 1:
            _perfil = { plural: "super administradores", singular: "super administrador" };
            break;
        case 2:
            _perfil = { plural: "administradores", singular: "administrador" };
            break;
        case 3:
            _perfil = { plural: "contadores", singular: _permisos.aux_contable ? "auxiliar contable" : "contador" };
            break;
        case 4:
            _perfil = { plural: "almacenistas", singular: "almacenista" };
            break;
        case 5:
            _perfil = { plural: "asesores", singular: "asesor" };
            break;
        case 6:
            _perfil = { plural: "transportadores", singular: "transportador" };
            break;
        case 7:
            _perfil = { plural: "domiciliarios", singular: "domiciliario" };
            break;
        default:
            _perfil = { plural: "", singular: "" };
            break;
    }

    return _perfil;
}

const permisos_guias = [
    { "breack_line": "Guías" },
    // { "guias_ver": "Ver guías" },
    { "guias_crear": "Crear guías" },
    { "guias_edita": "Editar guías" },
    { "guias_borra": "Borrar guías" },
];

const permisos_administradores = [
    { "breack_line": "Administradores" },
    { "admin_ver": "Ver administradores" },
    { "admin_crear": "Crear administradores" },
    { "admin_edita": "Editar administradores" },
    { "admin_borra": "Borrar administradores" },
];

const permisos_contadores = [
    { "breack_line": "Contadores" },
    { "contadores_ver": "Ver contadores" },
    { "contadores_crear": "Crear contadores" },
    { "contadores_edita": "Editar contadores" },
    { "contadores_borra": "Borrar contadores" },
];

const permisos_almacenistas = [
    { "breack_line": "Almacenistas" },
    { "almacenistas_ver": "Ver almacenistas" },
    { "almacenistas_crear": "Crear almacenistas" },
    { "almacenistas_edita": "Editar almacenistas" },
    { "almacenistas_borra": "Borrar almacenistas" },
];

const permisos_asesores = [
    { "breack_line": "asesores" },
    { "acesor_ver": "Ver asesores" },
    { "acesor_crear": "Crear asesores" },
    { "acesor_edita": "Editar asesores" },
    { "acesor_borra": "Borrar asesores" },
];

const permisos_transportadoras = [
    { "breack_line": "Transportadoras" },
    { "carrier_ver": "Ver transportadoras" },
    { "carrier_crear": "Crear transportadoras" },
    { "carrier_edita": "Editar transportadoras" },
    { "carrier_borra": "Borrar transportadoras" },
];

const permisos_domiciliarios = [
    { "breack_line": "Domiciliarios" },
    { "domics_ver": "Ver domiciliarios" },
    { "domics_crear": "Crear domiciliarios" },
    { "domics_edita": "Editar domiciliarios" },
    { "domics_borra": "Borrar domiciliarios" },
];

const permisos_despachos = [
    { "breack_line": "Despachos" },
    
    { "despachos_ver": "Ver despachos" },
    { "despachos_crear": "Crear despachos" },
    { "despachos_edita": "Editar despachos" },
    { "despachos_borra": "Borrar despachos" },
    { "despachos_entregar": "Entregar despachos" },
    { "despachos_informes": "Informe de despachos" },
];

const permisos_comprobantes = [
    { "breack_line": "Comprobantes" },

    { "comprobantes_ver": "Ver comprobantes" },
    { "comprobantes_crear": "Crear comprobantes" },
    { "comprobantes_edita": "Editar comprobantes" },
    { "comprobantes_edit_in_close": "Editar comprobantes cerrados" },
    { "comprobantes_borra": "Borrar comprobantes" },
    { "comprobantes_borra_in_close": "Borrar comprobantes cerrados" },
    { "comprobantes_valida": "Validar comprobantes" },
    { "comprobantes_revalida": "Revalidar comprobantes" },
    { "comprobantes_informes": "Informe de comprobantes" },
];

const permisos_comisiones = [
    { "breack_line": "Comisiones" },

    { "contable_comisiones_ver": "Ver comisiones" },
];

const permisos_caja = [
    { "breack_line": "Caja" },

    { "contable_caja_ver": "Ver caja" },
    { "contable_caja_entreda": "Entradas de caja" },
    { "contable_caja_salida": "Salidas de caja" },
    { "contable_caja_informes": "Informe de caja" },
    { "contable_caja_movement_edita": "Editar movimientos" },
    { "contable_caja_movement_borra": "Borrar movimientos" },

    { "contable_caja_code_ver": "Ver códigos de caja" },
    { "contable_caja_code_crear": "Agregar códigos de caja" },
    { "contable_caja_code_edita": "Editar códigos de caja" },
    { "contable_caja_code_borra": "Borrar códigos de caja" },
];

const permisos_informes = [
    { "breack_line": "Informes" },

    { "contable_informes_ver": "Ver informes" },
];

const permisos_contabilidad = [
    { "breack_line": "Contabilidad" },

    // { "aux_contable": "Es auxiliar contable" },
    { "contable_ver": "Ver contabilidad" },

    ...permisos_comprobantes,
    // ...permisos_comisiones,
    ...permisos_caja,
    // ...permisos_informes,
];

const permisos_alertas = [
    { "breack_line": "Alertas" },
    { "alertas_ver": "Ver alertas" },
];

const permisos_inventario = [
    { "breack_line": "Inventario" },

    { "inventario_ver": "Ver inventario" },
];

const permisos_para_admin = [
    ...permisos_administradores,
    ...permisos_contadores,
    ...permisos_almacenistas,
    ...permisos_asesores,
    ...permisos_transportadoras,
    ...permisos_domiciliarios,
    ...permisos_despachos,
    ...permisos_contabilidad,
    ...permisos_alertas,
    ...permisos_inventario,
    ...permisos_guias,
];

const permisos_para_contadores = [
    ...permisos_contabilidad,

    { "breack_line": "Despachos" },
    { "despachos_ver": "Ver despachos" },
    { "despachos_informes": "Informe de despachos" },
];

const permisos_para_almacenista = [
    ...permisos_despachos,
];

const permisos_para_asesores = [
    { "breack_line": "Comprobantes" },
    { "comprobantes_ver": "Ver comprobantes" },
    { "comprobantes_crear": "Crear comprobantes" },
    { "comprobantes_edita": "Editar comprobantes" },

    { "breack_line": "Despachos" },
    { "despachos_ver": "Ver despachos" },

    ...permisos_inventario,
];

const permisos_para_transportadoras = [
    { "breack_line": "????" },

    { "mis_despachos_ver": "Ver sus despachos" },
];

const permisos_para_domiciliarios = [
    { "breack_line": "????" },

    { "domics_per": "????" },
];

export {
    getPerfil,
    permisos_administradores,
    permisos_contadores,
    permisos_almacenistas,
    permisos_asesores,
    permisos_transportadoras,
    permisos_domiciliarios,
    permisos_despachos,
    permisos_comprobantes,
    permisos_comisiones,
    permisos_caja,
    permisos_informes,
    permisos_contabilidad,
    permisos_alertas,
    permisos_inventario,

    permisos_para_admin,
    permisos_para_contadores,
    permisos_para_almacenista,
    permisos_para_asesores,
    permisos_para_transportadoras,
    permisos_para_domiciliarios,
}