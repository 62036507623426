import * as React from 'react';
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { InputWithIcon } from 'shared/Components';
import { useNavigate } from "react-router";
import Loading from "shared/Loading";
import * as Iconos from "images";
import api from "services/api";

/* eslint no-useless-escape: 0 */

const FortgotForm = () => {
    const [showLoading, setShowLoading] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const inputMail = React.useRef(null);
    const navigate = useNavigate();


    const validateFormatEmail = () => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!regex.test(String(email).toLowerCase())) {
            alerta(inputMail.current, "El formato del correo es erróneo!");

            return false;
        } else {
            return true;
        }
    }

    const validaMail = () => {
        alertaRemove(inputMail.current);

        if (email == null || email.length < 1) {
            alerta(inputMail.current, "¡Debes ingresar tu correo eléctronico!");

            return false;
        } else {
            return true;
        }
    }

    const validate = async () => {
        if (validaMail() && validateFormatEmail()) {
            setShowLoading(true);
            let _data = await api.fetchJson({
                url: "reset-pass",
                tipo: "invitado",
                data: { email: email.trim() }
            });

            if (_data.response === "1") {
                Alert(_data.msg, "ok", () => navigate(-1));
            } else {
                Alert(_data.msg, "warning");
            }

            setShowLoading(false);
        }
    }

    return (
        <form className="form">

            <InputWithIcon
                ref={inputMail}
                type="text"
                title="Correo eléctronico"
                Icono={Iconos.Email}
                value={email}
                onChange={v => { alertaRemove(inputMail.current); setEmail(v) }} />

            <div className="content_btns">
                <button type="button" className="btn btn_primary" onClick={validate}>Enviar</button>
            </div>

            <Loading state={showLoading} />
        </form>
    );
};

export default FortgotForm;
