import * as React from "react";
import { Alert, alertArea, alertaRemove } from "shared/Alert";
import { InputWithIcon } from "shared/Components";
import { useNavigate } from "react-router-dom";
import MovePopUp from "shared/MovePopUp";
import Loading from "shared/Loading";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const AddOrder = ({ data, parent, reload, close }) => {
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = React.useState(false);
    const [order, setOrder] = React.useState("");

    const orderRef = React.useRef(null);


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const saveValidate = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "update-voucher",
            data: {
                task: "add_order",
                info: JSON.stringify({
                    id: data.id,
                    pedido: order,
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                reload();
                close(false);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                close(false);
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validate = (e) => {
        e.preventDefault();
        alertaRemove(orderRef.current);

        if (order.length < 1) {
            alertArea(orderRef.current, "¡Debes ingresar el número de pedido!");
            orderRef.current.focus();
            return false;
        }

        saveValidate();
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, [data]);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title="Agregar predido">

            <div className="content_primary _on_popup">

                <h4 className="field_text" style={{marginBottom: "2rem"}}>Comprobane <i>{data.voucher}</i></h4>

                <InputWithIcon
                    ref={orderRef}
                    title="Número de pedido"
                    value={order}
                    onChange={v => { alertaRemove(orderRef.current); setOrder(v) }} />

            </div>

            <div className="content_btns _without_margin">
                <button type="button" className="btn btn_primary" onClick={validate}>Aceptar</button>
            </div>

            <Loading state={showLoading} />
        </MovePopUp>)
}

export default AddOrder;