import * as React from 'react';
import { Route, Routes, Outlet } from "react-router-dom";
import { MediaProvider } from "./Contexts";
import Header from '../view/Header';
import Footer from 'shared/Footer';

// Otros
import NoMatch from 'shared/NoMatch';

// Inicio y login
import Home from '../view/Home';
import LogIn from '../view/LogIn';
import ForgotPassword from '../view/ForgotPassword';

// Usuarios
import Users from '../view/Users';
import NewUser from '../view/Users/components/NewUser';
import EditUser from '../view/Users/components/EditUser';
import DetailUser from '../view/Users/components/DetailUser';

// Despachos
import Dispatch from '../view/Dispatch';
import NewDispatch from '../view/Dispatch/components/NewDispatch';
import EditDispatch from '../view/Dispatch/components/EditDispatch';
import DeliveryDispatch from '../view/Dispatch/components/DeliveryDispatch';
import DetailsDispatch from '../view/Dispatch/components/DetailsDispatch';

// Comprobantes
import Vouchers from '../view/Vouchers';
import VoucherNew from '../view/Vouchers/components/VoucherNew';
import VoucherEdit from '../view/Vouchers/components/VoucherEdit';
import VoucherDetail from '../view/Vouchers/components/VoucherDetail';
import Till from '../view/Till';
import Guias from '../view/Guias';
import PrintGuide from '../view/Guias/components/PrintGuide';

/* eslint eqeqeq: 0 */

export default function Router() {

    const handleTheme = (_theme) => {
        let _element = document.getElementsByTagName("body")[0];
        localStorage.setItem("theme", _theme);

        if (_theme == "theme-light") {
            _element.classList.add("theme-light");
            _element.classList.remove("theme-dark");
            // _element.style.backgroundColor = "rgb(230, 230, 230)";
        } else if (_theme == "theme-dark") {
            _element.classList.add("theme-dark");
            _element.classList.remove("theme-light");
            // _element.style.backgroundColor = "#2a2a31";
        }
    }

    const WrappedRoutes = (params) => {
        return (
            <>
                <Header {...params} />
                <main className="wrapper">
                    <Outlet />
                </main>
            </>
        );
    }

    return (
        <MediaProvider>
            <Routes>
                <Route path="/" element={<WrappedRoutes changeTheme={(_theme) => handleTheme(_theme)} />}   >
                    <Route path="/" element={<Home />} />

                    <Route path="/lista-administradores" element={<Users />} />
                    <Route path="/lista-almacenistas" element={<Users />} />
                    <Route path="/lista-contadores" element={<Users />} />
                    <Route path="/lista-asesores" element={<Users />} />
                    <Route path="/lista-transportadoras" element={<Users />} />
                    <Route path="/lista-domiciliarios" element={<Users />} />

                    <Route path="/crear-usuario" element={<NewUser />} />
                    <Route path="/editar-usuario" element={<EditUser />} />
                    <Route path="/detalle-usuario" element={<DetailUser />} />

                    <Route path="/despachos" element={<Dispatch />} />
                    <Route path="/nuevo-despacho" element={<NewDispatch />} />
                    <Route path="/editar-despacho" element={<EditDispatch />} />
                    <Route path="/entregar-despachos" element={<DeliveryDispatch />} />
                    <Route exact path="/detalle-despacho" element={<DetailsDispatch />} />
                    <Route path="/detalle-despacho/:guia" element={<DetailsDispatch />} />

                    <Route path="/comprobantes" element={<Vouchers />} />
                    <Route path="/nuevo-comprobante" element={<VoucherNew />} />
                    <Route path="/editar-comprobante" element={<VoucherEdit />} />
                    <Route exact path="/detalle-comprobante" element={<VoucherDetail />} />
                    <Route path="/detalle-comprobante/:voucher" element={<VoucherDetail />} />

                    <Route path="/caja" element={<Till />} />
                    <Route path="/guias" element={<Guias />} />
                    <Route path="/guias-imprimir" element={<PrintGuide />} />

                    <Route path="*" element={<NoMatch />} />
                </Route>

                <Route
                    path="/sing-in"
                    element={<LogIn changeTheme={(_theme) => handleTheme(_theme)} />} />

                <Route
                    path="/forgot-password"
                    element={<ForgotPassword changeTheme={(_theme) => handleTheme(_theme)} />} />
            </Routes>
            <footer>
                <Footer />
                <div id="modal"></div>
            </footer>
        </MediaProvider>
    );
}
