import * as React from "react";
import { InputOnly } from "shared/Components";
import { Alert, Confirm } from "shared/Alert";
import * as Iconos from "images";
import api from "services/api";
import EditarCodigo from "./EditarCodigo";
import CrearCodigo from "./CrearCodigo";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const CodigosCaja = (params) => {
    const { navigate, media_movil, funciones, handleRefresh, showLoading, setShowLoading } = params;
    const [loadingLocal, setLoadingLocal] = React.useState(false);
    const [movementList, setMovementList] = React.useState([]);
    const [allMovements, setAllMovements] = React.useState([]);
    const [modalView, setModalView] = React.useState(null);
    const [criterio, setCriterio] = React.useState("");



    const Rows = () => {
        let _categoria = "";
        let _row_categogory;

        return movementList.map((item, i) => {

            if (item.caegoria && item.caegoria == _categoria) {
                _row_categogory = null;
            } else {
                _categoria = item.caegoria;

                if (_categoria) {
                    _row_categogory = <tr className={"table_row bkg_category_line"}>
                        <td className="colum_title _force_width"><h4>{_categoria}</h4></td>
                        <td>&nbsp;</td>
                        <td className="td_menu"></td>
                    </tr>;
                } else {
                    _row_categogory = null;
                }
            }

            return <React.Fragment key={item.id}>
                {_row_categogory}
                <tr className={(i % 2) === 1 ? "table_row bkg_inter_line" : "table_row"}>
                    <td className="colum_title _force_width">{format(item.id)} - {item.nombre}</td>
                    <td className="colum_icons">
                        {funciones.current.validaPermisos("contable_caja_code_edita") && <Iconos.Edit onClick={e => abrirEdicion(e, item)} />}

                        {funciones.current.validaPermisos("contable_caja_code_borra") && <>
                            {loadingLocal ?
                                <Iconos.Renew className="_rotate" onClick={handleRefresh} />
                                :
                                <Iconos.Delete onClick={() => goDelete(item.id)} />
                            }
                        </>
                        }
                    </td>
                    <td className="td_menu"></td>
                </tr>
            </React.Fragment>;
        })
    }

    const goDelete = (_id) => {
        Confirm(`¿Seguro de borrar el código "${format(_id)}"? \nLos movimientos asociados a este código se pueden ver afectados y alterar las cuentas.`, async (response) => {
            if (response) {
                setLoadingLocal(true);
                let _data = await api.fetchJson({
                    url: "delete-concept",
                    data: {
                        info: JSON.stringify({ id: _id })
                    }
                });

                if (_data.response == 1) {
                    Alert(_data.msg, "ok", () => {
                        document.body.style.overflow = "auto";
                        handleRefresh();
                    });
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => {
                        document.body.style.overflow = "auto";
                        navigate("/sing-in");
                    });
                } else {
                    Alert(_data.msg, "warning");
                }

                setLoadingLocal(false);
            }
        });
    }

    const abrirEdicion = (e, _data) => {
        e.preventDefault();

        setModalView(<EditarCodigo
            data={_data}
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            reload={() => handleRefresh()}
            close={() => setModalView(null)} />);
    }

    const abrirCreacion = (e, _data) => {
        e.preventDefault();

        setModalView(<CrearCodigo
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            reload={() => handleRefresh()}
            close={() => setModalView(null)} />);
    }

    const getMovements = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-concepts",
            data: {
                task: "todos",
            }
        });

        if (_data.response == 1) {
            setMovementList(_data.data);
            setAllMovements(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        let coincidencias = allMovements.filter(objeto => {
            let _nombre = format(objeto.id) + " - " + objeto.nombre;
            return _nombre.includes(criterio);
        });
        setMovementList(coincidencias);
    }, [criterio]);

    React.useEffect(() => {
        if (showLoading) {
            getMovements();
        }
    }, [showLoading]);

    return (
        <>
            <div className="caja">
                <div className="caja_contiene_tabla">
                    <div className="caja_tabla">
                        <table className="table">
                            <thead>
                                <tr className="table_title">
                                    <th className="colum_title _force_width">{!media_movil && "Códigos"} <InputOnly
                                        placeholder="Buscar código"
                                        value={criterio}
                                        onChange={v => setCriterio(v)}
                                        list={allMovements.map(item => {
                                            let _nombres = format(item.id) + " - " + item.nombre;
                                            return _nombres.trim();
                                        })} />
                                    </th>
                                    <th className="colum_icons">
                                        {funciones.current.validaPermisos("contable_caja_code_crear") && <Iconos.Plus onClick={abrirCreacion} />}
                                        <Iconos.Renew className={showLoading ? "_rotate" : ""} onClick={handleRefresh} /></th>
                                </tr>
                            </thead>
                            <tbody>
                                <Rows />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {modalView}
        </>
    )
}

export default CodigosCaja;