import * as React from "react";
import { InputCheck, InputPassword, InputWithIcon, SelectWithIcon } from "shared/Components";
import { RequirePermissions } from "shared/RequirePermissions";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import * as Iconos from "images";
import CryptoJS from "crypto-js";
import api from "services/api";
import {
    getPerfil,
    permisos_para_asesores,
    permisos_para_almacenista,
    permisos_para_contadores,
    permisos_para_transportadoras,
    permisos_para_domiciliarios,
    permisos_para_admin
} from "shared/permisos";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const EditUser = (router) => {
    const { navigate, location } = router;
    const { setTitle } = useMedia();
    const [perfil, setPerfil] = React.useState({ plural: "usuarios", singular: "usuario" });
    const [listaPermisos, setListaPermisos] = React.useState([{ "title_line": "Permisos de " + perfil.singular }]);
    const [showPermissions, setShowPermissions] = React.useState(false);
    const [permisosUser, setPermisosUser] = React.useState({});
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const funciones = React.useRef(new Funciones(navigate));
    const [direccion, setDireccion] = React.useState("");
    const [telefono, setTelefono] = React.useState("");
    const [apellido, setApellido] = React.useState("");
    const [tipoDoc, setTipoDoc] = React.useState("");
    const [nombre, setNombre] = React.useState("");
    const [numDoc, setNumDoc] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [pass, setPass] = React.useState("");

    const direccionRef = React.useRef(null);
    const telefonoRef = React.useRef(null);
    const apellidoRef = React.useRef(null);
    const tipoDocRef = React.useRef(null);
    const nombreRef = React.useRef(null);
    const numDocRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const passRef = React.useRef(null);


    const armaPerfil = (_rol) => {
        let _perfil = getPerfil(_rol);

        setPerfil(_perfil);
        return _perfil;
    }

    const handlePermission = (_perm) => {

        setPermisosUser(p => {
            let _new;

            if (p[_perm] === undefined) {
                _new = { ...p, [_perm]: 1 }
                // "add " + _perm);
            } else {
                // "remove " + _perm);
                _new = Object.keys(p).reduce((obj, key) => {
                    if (key !== _perm) {
                        obj[key] = p[key];
                    }
                    return obj;
                }, {});
            }
            return _new;
        });
    }

    const saveUser = async () => {
        setShowLoading(true);
        let clave = CryptoJS.SHA256(pass);

        let _data = await api.fetchJson({
            url: "update-user",
            data: {
                task: perfil.plural,
                info: JSON.stringify({
                    id: location.state.data.id,
                    intentos: 0,
                    nivel: location.state.data.nivel,
                    perfil: perfil.singular,
                    nombre,
                    apellido,
                    doc_tipo: tipoDoc,
                    doc_numero: numDoc,
                    telefono,
                    direccion,
                    correo: email,
                    clave: pass.length > 1 ? clave.toString() : "sin_cambio",
                    permisos: permisosUser
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                navigate(-1);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validaCambios = () => {
        let _data = location.state.data;

        if (_data.nombre === nombre &&
            _data.apellido === apellido &&
            _data.doc_tipo === tipoDoc &&
            _data.doc_numero === numDoc &&
            _data.telefono === telefono &&
            _data.direccion === direccion &&
            _data.correo === email &&
            pass.length < 8 &&
            funciones.current.compareObjects(permisosUser, _data.permisos)) {
            return false;
        }

        return true;
    }

    const validate = () => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        alertaRemove(nombreRef.current);
        alertaRemove(apellidoRef.current);
        alertaRemove(tipoDocRef.current);
        alertaRemove(numDocRef.current);
        alertaRemove(telefonoRef.current);
        alertaRemove(direccionRef.current);
        alertaRemove(emailRef.current);
        alertaRemove(passRef.current);

        if (nombre == null || nombre.length < 1) {
            alerta(nombreRef.current, "¡Debes ingresar el nombre del " + perfil.singular + "!");
            nombreRef.current.focus();
            return false;
        }

        if (apellido == null || apellido.length < 1) {
            alerta(apellidoRef.current, "¡Debes ingresar el apellido del " + perfil.singular + "!");
            apellidoRef.current.focus();
            return false;
        }

        if (tipoDoc == null || tipoDoc.length < 1) {
            alerta(tipoDocRef.current, "¡Debes seleccionar el tipo de documento del " + perfil.singular + "!");
            tipoDocRef.current.focus();
            return false;
        }

        if (numDoc == null || numDoc.length < 1) {
            alerta(numDocRef.current, "¡Debes ingresar el número de documento del " + perfil.singular + "!");
            numDocRef.current.focus();
            return false;
        }

        if (telefono == null || telefono.length < 1) {
            alerta(telefonoRef.current, "¡Debes ingresar el número de teléfono del " + perfil.singular + "!");
            telefonoRef.current.focus();
            return false;
        }

        if (email == null || email.length < 1) {
            alerta(emailRef.current, "¡Debes ingresar el correo eléctronico del " + perfil.singular + "!");
            emailRef.current.focus();
            return false;
        }

        if (!regex.test(String(email).toLowerCase())) {
            alerta(emailRef.current, "¡El formato del correo es erróneo!");
            emailRef.current.focus();
            return false;
        }

        if (pass.length > 1 && pass.length < 8) {
            alerta(passRef.current, "¡La contraseña es muy corta, debe tener minimo 8 caracteres!");
            passRef.current.focus();
            return false;
        }

        if (validaCambios()) {
            saveUser();
        } else {
            Alert("No hay cambios en la información del " + perfil.singular, "warning");
        }
    }

    React.useEffect(() => {
        document.body.style.overflow = "auto";

        if (location.state && location.state.data) {
            let _data = location.state.data;
            let data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 10, permisos: {} };
            let _rol = _data.nivel;
            let _perfil = armaPerfil(_rol);
            let _permission = "permitido";
            let _listaPermisos = [];

            setTitle("Editar " + _perfil.singular + " " + location.state.data.nombres, location.state);

            switch (parseInt(_rol)) {
                case 1:
                case 2:
                    // 2 Administrador                    
                    _permission = ["admin_ver", "admin_edita"];
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_admin,
                    ];
                    break;
                case 3:
                    // 3 Contador o asistente contable
                    _permission = ["contadores_ver", "contadores_edita"];
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_contadores,
                    ];
                    break;
                case 4:
                    // 4 Almacenista
                    _permission = ["almacenistas_ver", "almacenistas_edita"];
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_almacenista,
                    ];
                    break;
                case 5:
                    //  5 vendedor o asesores
                    _permission = ["acesor_ver", "acesor_edita"];
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_asesores,
                    ];
                    break;
                case 6:
                    //  6 transportadoras
                    _permission = ["carrier_ver", "carrier_edita"];
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_transportadoras,
                    ];
                    break;
                case 7:
                    //  7 domiciliarios
                    _permission = ["domics_ver", "domics_edita"];
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_domiciliarios,
                    ];
                    break;
            }

            setListaPermisos(_listaPermisos);

            funciones.current.validaSesion(_permission).then(response => {
                if (response && [1, 2].includes(parseInt(data_user.nivel))) {
                    setPermission(true);
                    setNombre(_data.nombre || "");
                    setApellido(_data.apellido || "");
                    setTipoDoc(_data.doc_tipo || "");
                    setNumDoc(_data.doc_numero || "");
                    setTelefono(_data.telefono || "");
                    setDireccion(_data.direccion || "");
                    setEmail(_data.correo || "");

                    let _permisosUser = {};

                    _listaPermisos.map((perm, i) => {
                        // eslint-disable-next-line
                        const [key, value] = Object.entries(perm)[0];
                        if (_data.permisos[key] !== undefined) {
                            _permisosUser = { ..._permisosUser, [key]: 1 }
                        }
                    });

                    setPermisosUser(_permisosUser);
                } else {
                    setPermission(false);
                }
                setShowLoading(false);
            });

        } else {
            setShowLoading(false);
            setTitle("Editar usuario", location.state);
            Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                "warning",
                () => navigate(-1));
        }
    }, [location.state]);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2 className="head_bar-title">Editar {perfil.singular + "\n"} "{location.state && location.state.data.nombres}"</h2>
            </div>

            <div className="content_primary">

                <InputWithIcon
                    ref={nombreRef}
                    type="text"
                    title="Nombre"
                    value={nombre}
                    onChange={v => { alertaRemove(nombreRef.current); setNombre(v) }} />

                <InputWithIcon
                    ref={apellidoRef}
                    type="text"
                    title="Apellidos"
                    value={apellido}
                    onChange={v => { alertaRemove(apellidoRef.current); setApellido(v) }} />

                <SelectWithIcon
                    ref={tipoDocRef}
                    type="text"
                    title="Tipo de documento"
                    Icono={Iconos.CardSelect}
                    value={tipoDoc}
                    onChange={v => { alertaRemove(tipoDocRef.current); setTipoDoc(v) }} >
                    <option value="">Selecciona tipo de documento</option>
                    <option>Cédula de Ciudadanía</option>
                    <option>Cédula de extranjería</option>
                    <option>Pasapote</option>
                    <option>NIT</option>
                </SelectWithIcon>

                <InputWithIcon
                    ref={numDocRef}
                    type="number"
                    title="Número de documento"
                    Icono={Iconos.CardDetails}
                    value={numDoc}
                    onChange={v => { alertaRemove(numDocRef.current); setNumDoc(v) }} />

                <InputWithIcon
                    ref={telefonoRef}
                    type="tel"
                    title="Teléfono"
                    Icono={Iconos.Phone}
                    value={telefono}
                    onChange={v => { alertaRemove(telefonoRef.current); setTelefono(v) }} />

                <InputWithIcon
                    ref={direccionRef}
                    type="text"
                    title="Dirección"
                    Icono={Iconos.HomeMarker}
                    value={direccion}
                    onChange={v => { alertaRemove(direccionRef.current); setDireccion(v) }} />

                <InputWithIcon
                    ref={emailRef}
                    type="text"
                    title="Correo eléctronico"
                    Icono={Iconos.Email}
                    value={email}
                    onChange={v => { alertaRemove(emailRef.current); setEmail(v) }} />

                <InputPassword
                    ref={passRef}
                    title="Contraseña"
                    value={pass}
                    onChange={v => { alertaRemove(passRef.current); setPass(v) }} />

            </div>

            <div className={showPermissions ? "content_collapse _open" : "content_collapse"}>

                {listaPermisos.map((perm, i) => {
                    const [key, value] = Object.entries(perm)[0];

                    if (key == "title_line") {
                        return <div key={i} >
                            <button type="button" className="btn btn_primary" onClick={() => setShowPermissions(s => !s)}>
                                <Iconos.Permissions />
                                {value}
                                {showPermissions ? <Iconos.ChevronUp className="chevron" /> : <Iconos.ChevronDown className="chevron" />}
                            </button>
                        </div>;
                    } else if (key == "breack_line") {
                        return <div key={i} className="breack_line"><hr /><i>{value}</i><hr /></div>;
                    } else {
                        return <InputCheck
                            key={i}
                            title={value}
                            checked={permisosUser[key] !== undefined}
                            onClick={() => handlePermission(key)} />;
                    }
                })}

            </div>

            <div className="content_btns _with_margin">
                <button type="button" className="btn btn_primary" onClick={validate}>Guardar {perfil.singular}</button>
            </div>

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(EditUser);