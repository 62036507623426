import * as React from "react";
import { DatePicker, InputList, SelectWithIcon } from "shared/Components";
import { useNavigate } from "react-router-dom";
import ReactExport from "react-export-excel";
import MovePopUp from "shared/MovePopUp";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import api from "services/api";

// const rowData = ReactExport.rowData;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const meses_hsort = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const _border = {
    top: { style: "thin", color: { rgb: "ff000000" } },
    bottom: { style: "thin", color: { rgb: "ff000000" } },
    left: { style: "thin", color: { rgb: "ff000000" } },
    right: { style: "thin", color: { rgb: "ff000000" } },
};

// const _align_rigth = { horizontal: "right", vertical: "center", wrapText: false };
const _align_left = { horizontal: "left", vertical: "center", wrapText: false };

const _heads = {
    fill: { patternType: "solid", fgColor: { rgb: "ffb7e1cd" } },
    font: { sz: "12", bold: true, color: { rgb: "ffff0000" } },
    alignment: _align_left,
    border: _border
};

// eslint-disable-next-line
const _resum = {
    fill: { patternType: "solid", fgColor: { rgb: "ff6060ff" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffffff" } },
    border: _border
};

const _txt = { font: { sz: "10.5" }, border: _border };

// eslint-disable-next-line
const _txt_green = {
    fill: { patternType: "solid", fgColor: { rgb: "ff00ff00" } },
    font: { sz: "10.5", color: { rgb: "ff000000" } },
    border: _border
};

// eslint-disable-next-line
const _txt_blue = {
    fill: { patternType: "solid", fgColor: { rgb: "ff4040ff" } },
    font: { sz: "10.5", color: { rgb: "ffffffff" } },
    border: _border
};

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const DispatchReport = ({ parent, close }) => {
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = React.useState(true);
    const [fileName, setFileName] = React.useState("No name");
    const [carrierList, setCarrierList] = React.useState([]);
    const [carrierInfo, setCarrierInfo] = React.useState({});
    const [domicsList, setDomicsList] = React.useState([]);
    const [generado, setGenerado] = React.useState(false);
    const [fecha, setFecha] = React.useState(new Date());
    const [dataList, setDataList] = React.useState([]);
    const [carrier, setCarrier] = React.useState("");
    const [domici, setDomici] = React.useState("");
    const [error, setError] = React.useState("");

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const handleDomiciliary = (_value) => {
        setError("");
        setDomici(_value);
        setGenerado(false);

        let item = domicsList.filter(({ info }) => {
            let _info = JSON.parse(info);
            let _nombres = _info.nombre + " " + _info.apellido;
            return _nombres.trim() === _value;
        });
        item = item[0];

        if (typeof item === "object") {
            setCarrierInfo({ id: item.id, name: _value });
        } else {
            setCarrierInfo({});
            setError("¡El domiciliario es invalido!");
        }
    }

    const handleCarrier = (_value) => {
        setError("");
        setGenerado(false);

        if (_value === "") {
            setCarrier("");
            setCarrierInfo({});
        } else if (_value === "Domiciliario") {
            setCarrier(_value);
            getDomics();
        } else {
            let item = carrierList.filter(({ info }) => {
                let _info = JSON.parse(info);
                let _nombres = _info.nombre + " " + _info.apellido;
                return _nombres.trim() === _value;
            });
            item = item[0];

            if (typeof item === "object") {
                setCarrier(_value);
                setCarrierInfo({ id: item.id, name: _value });
            } else {
                setCarrier("");
                setCarrierInfo({});
                setError("¡La transportadora es invalida!");
            }
        }
    }

    const getDomics = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: "domiciliarios"
            }
        });

        if (_data.response == 1) {
            setDomicsList(_data.data);
        } else if (_data.response === -2) {
            setError(_data.msg);
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const getCarriers = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: "carriers"
            }
        });

        if (_data.response == 1) {
            setCarrierList(_data.data);
        } else if (_data.response === -2) {
            setError(_data.msg);
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const getList = async () => {
        if (carrier === "Domiciliario" && carrierInfo.id === undefined) {
            setError("¡Debes ingresar un domocililario!");
            return;
        }

        setShowLoading(true);
        setDataList([]);
        let _radios = document.getElementsByName("estado");
        let _estado;

        _radios.forEach(element => {
            if (element.checked) {
                _estado = element.value;
            }
        });

        let _data = await api.fetchJson({
            url: "get-dispatch",
            data: {
                task: "para_excel",
                estado: _estado,
                carrier: carrierInfo.id,
                fecha: `${fecha.getFullYear()}-${format(fecha.getMonth() + 1)}-${format(fecha.getDate())}`
            }
        });

        if (_data.response == 1) {
            let _list = [];

            _data.data.map(item => {
                let _carrier = JSON.parse(item.carrier);
                if (_carrier.old) {
                    _carrier.old.map(old => {
                        _list.push({
                            ...item,
                            carrier: JSON.stringify({ ...old, old: 1 })
                        });
                    });
                }

                _list.push({
                    ...item,
                    carrier: JSON.stringify({
                        id: _carrier.id,
                        name: _carrier.name,
                        fecha_entregado: _carrier.fecha_entregado
                    })
                });

            });

            setDataList(_list);
            setGenerado(true);
        } else if (_data.response === -2) {
            setError(_data.msg);
            Alert(_data.msg, "warning", () => {
                navigate("/sing-in");
            });
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const BotonDescargar = ({ enable }) => {
        let _btn = <button className="btn btn_with_icon _left unable"><Iconos.ExcelDownload />Descargar Excel</button>;

        if (showLoading) {
            _btn = <button className="btn btn_with_icon _left"><Iconos.Loading />Cargando info...</button>;
        } else if (enable) {
            _btn = <button className="btn btn_with_icon _left"><Iconos.ExcelDownload />Descargar Excel</button>;
        }

        return _btn;
    }

    const BotonGenerar = () => {

        if (showLoading) {
            return <button className="btn btn_with_icon _left"><Iconos.Loading />Cargando info...</button>;
        } else {
            return <button className="btn btn_with_icon _left" onClick={() => getList()}><Iconos.ExcelSearch />Generar informe </button>;
        }
    }

    const HojaDeCalculo = () => {

        const valida = (_val) => {

            if (_val.length > 0) {
                return _val;
            } else {
                return "...";
            }
        }

        const validaFecha = (_fecha, _hora = true) => {
            if (typeof _fecha === "string") {
                return _fecha.length > 1 ? _fecha : "...";
            } else if (isNaN(_fecha.getDate())) {
                return "...";
            } else {
                return _hora ?
                    format(_fecha.getDate()) + "/" + meses_hsort[_fecha.getMonth()] + "/" + _fecha.getFullYear() + " " + format(_fecha.getHours()) + ":" + format(_fecha.getMinutes())
                    :
                    format(_fecha.getDate()) + "/" + meses_hsort[_fecha.getMonth()] + "/" + _fecha.getFullYear();
            }
        }

        const multiDataSet = [
            {
                columns: [
                    { value: "Guía", style: _heads, width: 10 },            // A
                    { value: "Pedido", style: _heads, width: 9 },           // B
                    { value: "Fecha compra", style: _heads, width: 12 },    // C
                    { value: "Cantidad", style: _heads, width: 6 },         // D
                    { value: "Cliente", style: _heads, width: 20 },         // E
                    { value: "Valor", style: _heads, width: 8 },            // F
                    { value: "Ciudad", style: _heads, width: 8 },           // G
                    { value: "Contacto", style: _heads, width: 12 },        // H
                    { value: "Cédula", style: _heads, width: 10 },          // I 
                    { value: "Estado", style: _heads, width: 16 },          // J
                    { value: "Transportadora", style: _heads, width: 18 },  // K
                    { value: "Fecha escaneado", style: _heads, width: 14 }, // L
                    { value: "Fecha entregado", style: _heads, width: 14 }, // M
                    { value: "Etiquetas", style: _heads, width: 20 },       // N
                    null,   // O
                    null,   // P
                    null,   // Q
                    null,   // R
                    null,   // S
                    null,   // T
                ],
                data: dataList.map((item, i) => {
                    let _info = JSON.parse(item.info);
                    let _carrier = JSON.parse(item.carrier);
                    let _etiquetas = _info.etiquetas.toString().toLowerCase();
                    _etiquetas = _etiquetas.split(",");
                    let _estado = _etiquetas.filter(e => e.includes("ya pago"));
                    let fecha_entrega = _carrier.fecha_entregado ? new Date(_carrier.fecha_entregado) : new Date();
                    let fecha_creado = new Date(_info.fecha_creado);
                    let fecha_compra = _info.fecha.split("-");
                    fecha_compra = new Date(fecha_compra[2], (fecha_compra[1] - 1), fecha_compra[0]);

                    return [
                        { value: valida(item.guia ?? ""), style: _carrier.old ? _txt_green : _txt },
                        { value: valida(_info.numero ?? ""), style: _carrier.old ? _txt_green : _txt },
                        { value: validaFecha(fecha_compra, false), style: _carrier.old ? _txt_green : _txt },
                        { value: valida(_info.cantidad ?? ""), style: _carrier.old ? _txt_green : _txt },
                        { value: valida(_info.cliente ?? ""), style: _carrier.old ? _txt_green : _txt },
                        { value: valida(_info.valor ?? ""), style: _carrier.old ? _txt_green : _txt },
                        { value: valida(_info.ciudad ?? ""), style: _carrier.old ? _txt_green : _txt },
                        { value: valida(_info.contacto ?? ""), style: _carrier.old ? _txt_green : _txt },
                        { value: valida(_info.cedula ?? ""), style: _carrier.old ? _txt_green : _txt },
                        { value: valida(_estado[0] ?? ""), style: _carrier.old ? _txt_green : _txt },
                        { value: _carrier.id ? _carrier.name : "Sin entregar", style: _carrier.old ? _txt_green : _txt },
                        { value: validaFecha(fecha_creado), style: _carrier.old ? _txt_green : _txt },
                        { value: validaFecha(_carrier.fecha_entregado ? fecha_entrega : "Sin entregar"), style: _carrier.old ? _txt_green : _txt },
                        { value: valida(_info.etiquetas ?? ""), style: _txt },
                        null,
                        null,
                        null,
                    ];
                })
            }
        ];

        return <>
            {(dataList.length == 0) ?
                <BotonDescargar enable={false} />
                :
                <ExcelFile filename={fileName} element={<BotonDescargar enable={true} />}>
                    <ExcelSheet dataSet={multiDataSet} name={`${meses[fecha.getMonth()].slice(0, 3)} ${format(fecha.getDate())} de ${fecha.getFullYear()}`} />
                </ExcelFile>
            }
        </>;
    }

    const handleChange = (e) => {
        if (e.target.value === "todos") {
            setFileName(`Todos los despachos ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        } else {
            setFileName(`Despachos ${e.target.value} ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        }
        setGenerado(false);
    }

    React.useEffect(() => {
        let _radios = document.getElementsByName("estado");
        let _estado;

        _radios.forEach(element => {
            if (element.checked) {
                _estado = element.value;
            }
        });

        if (_estado === "todos") {
            setFileName(`Todos los despachos ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        } else {
            setFileName(`Despachos ${_estado} ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        }

        setGenerado(false);
    }, [fecha]);

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        getCarriers();
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title="Informe de despachos">

            <SelectWithIcon
                title="Transportadora"
                Icono={Iconos.TruckFast}
                value={carrier}
                onChange={handleCarrier} >
                <option value="">Todas las transportadoras</option>
                {carrierList.map(item => {
                    let _info = JSON.parse(item.info);
                    return <option key={item.id}>{_info.nombre} {_info.apellido}</option>;
                })}
                <option>Domiciliario</option>
            </SelectWithIcon>

            {carrier === "Domiciliario" &&
                <InputList
                    title="Domiciliario"
                    Icono={Iconos.Moped}
                    value={domici}
                    onChange={handleDomiciliary}
                    list={domicsList.map(({ info }) => {
                        let _info = JSON.parse(info);
                        let _nombres = _info.nombre + " " + _info.apellido;
                        return _nombres.trim();
                    })} />
            }

            <DatePicker
                title="Fecha"
                value={fecha}
                onChange={(date) => { setError(""); setFecha(date); }} />

            <div className="radio_goup">
                <label><input type="radio" name="estado" value="pendientes" onChange={handleChange} /><Iconos.TruckPending />Pendientes</label>
                <label><input type="radio" name="estado" value="entregados" onChange={handleChange} /><Iconos.TruckCheck />Entregados</label>
                <label><input type="radio" name="estado" value="todos" onChange={handleChange} defaultChecked={true} /><Iconos.TruckDelivery />Todos</label>
            </div>


            {error.length > 1 && <h4 className="error"><Iconos.InformationCircle />{error}</h4>}

            {generado ?
                <HojaDeCalculo />
                :
                <BotonGenerar />
            }
        </MovePopUp>
    )
}

export default DispatchReport;