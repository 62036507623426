import * as React from 'react';
import ItemLinkIcon from './components/ItemLinkIcon';
import ItemSubMenu from './components/ItemSubMenu';
import ChangePass from './components/ChangePass';
import ItemGroup from './components/ItemGroup';
import { Funciones } from 'services/funciones';
import ItemLink from './components/ItemLink';
import { getPerfil } from 'shared/permisos';
import { NavLink } from 'react-router-dom';
import { useMedia } from 'hooks';
import * as Icon from 'images';

/* eslint eqeqeq: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Header = (params) => {
    const { changeTheme } = params;
    const usuario = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 10 };
    const [currentTheme, setCurrentTheme] = React.useState("");
    const [modalView, setModalView] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false);
    const funciones = React.useRef(new Funciones());
    const [nombre, setNombre] = React.useState("");
    const [perfil, setPerfil] = React.useState("");
    const { setTitle, media_movil } = useMedia();

    const openChangePass = (e) => {
        e.preventDefault();
        setOpenMenu(false);

        setModalView(<ChangePass
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            close={() => setModalView(null)} />);
    }

    const armaPerfil = () => {
        let _perfil = getPerfil(usuario.nivel, usuario.permisos);

        setNombre(usuario.nombre ?? "Iniciar Sesión");
        setPerfil(_perfil.singular);
    }

    const setTheme = (theme) => {
        changeTheme((theme == "theme-light" || theme == "theme-dark") ? theme : "theme-light");
        setCurrentTheme(theme);
    }

    const handleOpenMenu = (e) => {
        e.preventDefault();
        let _element = document.getElementsByClassName("_opened");
        let _submenu = document.getElementsByClassName("submenu")[0];

        if (_element.length == 0 && openMenu) {
            _submenu && _submenu.classList.add("_opened");
        } else {
            setOpenMenu(o => !o);
        }
    }

    const Avatar = () => {
        return (<div className="menu_profile_avatar" onClick={handleOpenMenu}>
            <h4>
                <Icon.UserCircle className="menu_profile_img" />
                {nombre}
                {openMenu ? <Icon.ChevronUp className="menu_profile_arrow" /> : <Icon.ChevronDown className="menu_profile_arrow" />}
            </h4>

            <i>{perfil}</i>
        </div>)
    }

    const Menu = () => {
        return (<div className="menu_burger" onClick={handleOpenMenu}>
            <Icon.Menu className="menu_burger_icono" />
        </div>)
    }

    const NavMenu = () => {
        return (<nav className="menu_nav">

            {[1, 2].includes(parseInt(usuario.nivel)) &&
                <>
                    <ItemGroup title="Usuarios" Icono={Icon.Users}>
                        {funciones.current.validaPermisos("admin_ver") && <ItemLink title="Administradores" route="/lista-administradores" state={{ rol: 2 }} />}
                        {funciones.current.validaPermisos("contadores_ver") && <ItemLink title="Contadores" route="/lista-contadores" state={{ rol: 3 }} />}
                        {funciones.current.validaPermisos("almacenistas_ver") && <ItemLink title="Almacenistas" route="/lista-almacenistas" state={{ rol: 4 }} />}
                        {funciones.current.validaPermisos("acesor_ver") && <ItemLink title="Asesores" route="/lista-asesores" state={{ rol: 5 }} />}
                        {funciones.current.validaPermisos("carrier_ver") && <ItemLink title="Transportadoras" route="/lista-transportadoras" state={{ rol: 6 }} />}
                        {funciones.current.validaPermisos("domics_ver") && <ItemLink title="Domiciliarios" route="/lista-domiciliarios" state={{ rol: 7 }} />}
                    </ItemGroup>

                    {funciones.current.validaPermisos("guias_crear") && <ItemLinkIcon Icono={Icon.CheckBook} title="Guías" route="/guias" />}
                </>
            }

            {([1, 2, 4].includes(parseInt(usuario.nivel)) && funciones.current.validaPermisos("despachos_ver")) &&
                <ItemGroup title="Despachos" Icono={Icon.TruckDelivery}>
                    {funciones.current.validaPermisos("despachos_entregar") && <ItemLink title="Entregar" route="/entregar-despachos" />}
                    <ItemLink title="Ver todos" route="/despachos" />
                    {funciones.current.validaPermisos("despachos_crear") && <ItemLink title="Crear nuevo" route="/nuevo-despacho" />}
                </ItemGroup>
            }

            {[3, 5].includes(parseInt(usuario.nivel)) &&
                <>
                    {funciones.current.validaPermisos("despachos_ver") && <ItemLinkIcon Icono={Icon.TruckDelivery} title="Despachos" route="/despachos" />}
                    {(funciones.current.validaPermisos("comprobantes_ver") && [5].includes(parseInt(usuario.nivel))) && <ItemLinkIcon Icono={Icon.Vouchers} title="Comprobantes" route="/comprobantes" />}
                </>
            }

            {([1, 2, 3].includes(parseInt(usuario.nivel)) && funciones.current.validaPermisos("contable_ver")) &&
                <ItemGroup title="Contabilidad" Icono={Icon.Contabilidad}>
                    {funciones.current.validaPermisos("comprobantes_ver") && <ItemLink title="Comprobantes" route="/comprobantes" />}
                    {funciones.current.validaPermisos("contable_comisiones_ver") && <ItemLink title="comisiones" route="/comisiones" />}
                    {/* {funciones.current.validaPermisos("contable_informes_ver") && <ItemLink title="Informes" route="/informes_contables" />} */}
                    {funciones.current.validaPermisos("contable_caja_ver") && <ItemLink title="Caja" route="/caja" />}
                </ItemGroup>
            }

            {([1, 2].includes(parseInt(usuario.nivel)) && funciones.current.validaPermisos("alertas_ver")) &&
                <ItemGroup title="Alertas" Icono={Icon.AlertRhombus}>
                    <ItemLink title="Todas" route="/alertas/lista" />
                    <ItemLink title="???" route="/alertas/otras" />
                    <ItemLink />
                </ItemGroup>
            }

        </nav>)
    }

    React.useEffect(() => {
        let theme = localStorage.getItem("theme");
        setTheme(theme);
        armaPerfil();
    }, []);

    return (
        <>
            <header>

                <NavLink className="menu_logo" to="/"></NavLink>

                {media_movil ? <></> : <NavMenu />}

                <div className="menu_profile">
                    {media_movil ? <Menu /> : <Avatar />}

                    <div className={openMenu ? "submenu _opened" : "submenu"} onClick={() => setOpenMenu(false)}>
                        <div className="submenu_content">
                            <Avatar />
                            {media_movil ? <NavMenu /> : <></>}
                            <ItemSubMenu onClick={() => setTheme(currentTheme === "theme-light" ? "theme-dark" : "theme-light")}>
                                {currentTheme === "theme-light" ? <Icon.Moon className="submenu_icon" /> : <Icon.Sun className="submenu_icon" />}
                                <a>Tema {currentTheme === "theme-light" ? "oscuro" : "claro"}</a>
                            </ItemSubMenu>

                            <ItemSubMenu onClick={openChangePass}>
                                <Icon.KeyChange className="submenu_icon" />
                                <NavLink>Cambiar contraseña</NavLink>
                            </ItemSubMenu>

                            <ItemSubMenu onClick={() => setTitle(document.title, { self: "self" })}>
                                <Icon.Exit className="submenu_icon" />
                                <NavLink to="/sing-in">Cerrar Sesión</NavLink>
                            </ItemSubMenu>

                            <ItemSubMenu className="_version">...</ItemSubMenu>
                        </div>
                    </div>
                </div>

            </header>

            {modalView}
        </>
    );
};

export default Header;
