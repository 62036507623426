import * as React from "react";
import * as ReactDOMClient from 'react-dom/client';
import * as Iconos from "images";

/* eslint jsx-a11y/anchor-is-valid: 0 */

const SearchComponent = ({ modal, action }) => {
    const [codigo, setCodigo] = React.useState("");
    const [error, setError] = React.useState("");

    const handleClose = () => {
        modal.unmount();
    }

    const handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            onDismiss(e);
        }
    }

    const onDismiss = (e) => {
        e.preventDefault();
        setError("");

        if (codigo.length < 1) {
            setError("¡No hay contenido para buscar!");
        } else {
            action(codigo);
            modal.unmount();
        }
    }

    return (
        <div className="alert__content search_flotante">
            <h4><Iconos.CloseCircle onClick={handleClose} />Ingresa el código a buscar</h4>

            <div className="cont_search">
                <input type="text" placeholder="Buscar código" value={codigo}
                    onKeyUp={handleKeyUp}
                    onChange={e => { setError(""); setCodigo(e.target.value); }} />
                <Iconos.Magnify />
            </div>

            {error.length > 1 && <i className="error">{error}</i>}
            <a className="close" onClick={onDismiss}>Buscar</a>
        </div>
    );
}

const AlertComponent = ({ modal, msg, icono, action }) => {
    let Icon;

    const onDismiss = () => {
        action();
        document.body.style.overflow = "auto";
        modal.unmount();
    }

    switch (icono) {
        case "alert":
        case "warning":
            Icon = <Iconos.AlertRhombus className="red"/>;
            break;
        case "ok":
            Icon = <Iconos.CheckBold className="green"/>;
            break;
        case "info_circle":
            Icon = <Iconos.InformationCircle />;
            break;
        case "info":
        default:
            Icon = <Iconos.Information />;
            break;
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        try {
            navigator.vibrate(250);
        } catch (e) {
            console.log('No se puede ejecutar el vibrador:', e);
        }
    }, []);

    return (
        <div className="alert__content">
            <div className="alert__icon">{Icon}</div>
            <div className="to_scroll">
                <p>{msg}</p>
            </div>
            <a className="close" onClick={onDismiss}>Aceptar</a>
        </div>
    );
}

const ConfirmComponent = ({ modal, msg, action }) => {
    let Icon = <Iconos.Alert className="yellow"/>;

    const onDismiss = (e, _response) => {
        e.preventDefault();
        e.stopPropagation();

        action(_response);
        document.body.style.overflow = "auto";
        modal.unmount();
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        try {
            navigator.vibrate(250);
        } catch (e) {
            console.log('No se puede ejecutar el vibrador:', e);
        }
    }, []);

    return (
        <div className="alert__content">
            <div className="alert__icon">{Icon}</div>
            <p>{msg}</p>
            <div className="in_row">
                <a className="close" onClick={e => onDismiss(e, true)}>Aceptar</a>
                <a className="close" onClick={e => onDismiss(e, false)}>Cancelar</a>
            </div>
        </div>
    );
}

export const Alert = (_msg, _icono = "info", action = () => {}) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <AlertComponent modal={_modal} msg={_msg} icono={_icono} action={action} />
        </div>);
}

export const SearchFloating = (action = () => {}) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <SearchComponent modal={_modal} action={action} />
        </div>);
}

/**
 * Muestra una ventana de confirmación
 * @param {String} _msg Mensaje a mostrar
 * @param {Function} action función de respuesta callback
 */
export const Confirm = (_msg, action = () => {}) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <ConfirmComponent modal={_modal} msg={_msg} action={action} />
        </div>);
}

export const alertArea = (_current_node, _msg) => {
    let _new_node = document.createElement("span");
    let _add_alert = true;

    if (_current_node.classList.contains("alert__resalta_input")) {
        _add_alert = false;
    }

    if (_add_alert) {
        _current_node.classList.add("alert__resalta_input");
        _new_node.className = "alert__input _up _area";
        _new_node.innerText = _msg;

        _current_node.insertAdjacentElement("afterend", _new_node);
    }

    try {
        navigator.vibrate(250);
    } catch (e) {
        console.log('No se puede ejecutar el vibrador:', e);
    }
}

export const alerta = (_current_node, _msg) => {
    let _new_node = document.createElement("span");
    let _add_alert = true;

    if (_current_node.classList.contains("alert__resalta_input")) {
        _add_alert = false;
    }

    if (_add_alert) {
        _current_node.classList.add("alert__resalta_input");
        _new_node.className = "alert__input _up";
        _new_node.innerText = _msg;

        _current_node.insertAdjacentElement("afterend", _new_node);
    }

    try {
        navigator.vibrate(250);
    } catch (e) {
        console.log('No se puede ejecutar el vibrador:', e);
    }
}

export const alertDown = (_current_node, _msg) => {
    let _new_node = document.createElement("span");
    let _add_alert = true;

    if (_current_node.classList.contains("alert__resalta_input")) {
        _add_alert = false;
    }

    if (_add_alert) {
        _current_node.classList.add("alert__resalta_input");
        _new_node.className = "alert__input _down";
        _new_node.innerText = _msg;

        _current_node.insertAdjacentElement("afterend", _new_node);
    }

    try {
        navigator.vibrate(250);
    } catch (e) {
        console.log('No se puede ejecutar el vibrador:', e);
    }
}

export const alertaRemove = (_current_node) => {
    let _alerta = _current_node == null ? null : _current_node.nextSibling;

    if (_alerta != null) {
        _current_node.classList.remove("alert__resalta_input");

        if (_alerta.className === "alert__input _up"
            || _alerta.className === "alert__input _down"
            || _alerta.className === "alert__input _up _area") {
            _alerta.parentNode.removeChild(_alerta);
        }
    }
}
