import * as React from "react";

/* eslint eqeqeq: 0 */

const Menu = (params) => {
    const { data, interline, children, onSel, current } = params;
    const [clases, setClases] = React.useState(interline == 0 ? "table_row" : "table_row bkg_inter_line");


    const onSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();

        onSel(data);
    }

    React.useEffect(() => {
        current === data.id && setClases(c => c + " bkg_select");
    }, [data.id, current]);

    return (
        <tr className={clases} onClick={onSelect}>

            {children}

            <td className="td_menu">
            </td>
        </tr>
    )
}

export default Menu;