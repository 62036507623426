// import * as React from 'react';

const cuentas = [
    { "01": "Bancolombia Nicolás" },
    { "02": "Nequi Nicolás" },
    { "03": "DaviPlata Nicolás" },
    { "04": "Efectivo" },
];

export {
    cuentas,
}